import React from 'react';
import * as el from './GlobalFooter.styled';
import { P } from '../../styled-components/typography';
import footerLogo from '../../assets/images/logo-grey.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { TextLinkRR } from '../../styled-components/react-router';

const GlobalFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <el.Footer>
      <el.Logo src={footerLogo} alt="phovo.co logo" />
      <el.FooterLinksContainer>
        <TextLinkRR to="/terms">Terms &amp; Conditions</TextLinkRR>
        <TextLinkRR to="/privacy">Privacy Policy</TextLinkRR>
        <TextLinkRR to="/contact">Contact Us</TextLinkRR>
      </el.FooterLinksContainer>
      <el.SocialContainer>
        <FontAwesomeIcon
          icon={faInstagram}
          size="2x"
          className="fa-icon icon-primary"
        />
        <FontAwesomeIcon
          icon={faTwitter}
          size="2x"
          className="fa-icon icon-primary"
        />
      </el.SocialContainer>
      <P small margin="0">
        All rights reserved, Phovo Inc. {currentYear}
      </P>
    </el.Footer>
  );
};

export default GlobalFooter;
