import * as vars from '../utils/phovoVars';

export const generateUniqueId = () => {
  const date = String(Date.now()).slice(7);
  const randomLetter = (num: number) => {
    let returnVal = '';
    for (let i = 0; i < num; i++) {
      returnVal += String.fromCharCode(65 + Math.floor(Math.random() * 26));
    }
    return returnVal;
  };

  const randomNumber = Math.round(Math.random() * (10000 - 1) + 1);
  return `${randomLetter(5) + date + randomNumber}`;
};

export const generateReactKey = (
  namespace: string,
  idContent: string | number
) => {
  const flatString = idContent.toString().replace(' ', '');

  return flatString.length >= 20
    ? `${namespace}_${flatString.substring(0, 19)}`
    : `${namespace}_${flatString}`;
};

export const getBrandColor = (propName: string) => {
  return propName === 'primary'
    ? vars.branding.primary
    : propName === 'white'
    ? '#ffffff'
    : propName === 'darkGrey'
    ? vars.branding.darkGrey
    : propName === 'black'
    ? '#000000'
    : vars.branding.grey1;
};

export const getParagraphs = (str: string) => {
  return str ? str.split('\n\n') : null;
};

export const hiddenSmall = () => {
  return `display: none;
    @media (min-width: ${vars.breakpoints.md}) {
      display: block;
    }`;
};

export const visibleSmall = () => {
  return `display: block;
  @media (min-width: ${vars.breakpoints.md}) {
    display: none;
  }`;
};

export const clearGooglePlace = () => {
  return {
    country: { longName: '', code: '' },
    subRegion: { longName: '', code: '' },
    city: '',
    googlePlaceID: '',
    coordinates: { longitude: null, latitude: null },
    googlePlaceValue: '',
  };
};
