import { ActionSources } from '../../../types/base.types';
import {
  CreateItineraryActionTypes,
  IUpdateAutocompleteItineraryPayload,
  IUpdateItineraryDescriptionPayload,
  IUpdateItineraryPlaceValuePayload,
} from './createItinerary.types';

export const INITIATE_ITINERARY = 'INITIATE_ITINERARY';
export const UPDATE_ITINERARY_FIELD = 'UPDATE_ITINERARY_FIELD';
export const SET_ITINERARY_AUTOCOMPLETE_PLACE =
  'SET_ITINERARY_AUTOCOMPLETE_PLACE';
export const CLEAR_ITINERARY_AUTOCOMPLETE_PLACE =
  'CLEAR_ITINERARY_AUTOCOMPLETE_PLACE';
export const UPDATE_ITINERARY_GOOGLE_AUTOCOMPLETE = // user types in form field
  'UPDATE_ITINERARY_GOOGLE_AUTOCOMPLETE';
export const ADD_ITINERARY = 'ADD_ITINERARY';
export const REMOVE_ITINERARY = 'REMOVE_ITINERARY';

export const initiateItinerary = (
  payload: number,
  src: ActionSources
): CreateItineraryActionTypes => ({
  type: INITIATE_ITINERARY,
  payload: payload,
  src: src,
});

export const updateItineraryField = (
  payload: IUpdateItineraryDescriptionPayload,
  src: ActionSources
): CreateItineraryActionTypes => ({
  type: UPDATE_ITINERARY_FIELD,
  payload: payload,
  src: src,
});

export const setItineraryAutocompletePlace = (
  payload: IUpdateAutocompleteItineraryPayload,
  src: ActionSources
): CreateItineraryActionTypes => ({
  type: SET_ITINERARY_AUTOCOMPLETE_PLACE,
  payload: payload,
  src: src,
});

export const clearItineraryAutocompletePlace = (
  payload: string,
  src: ActionSources
): CreateItineraryActionTypes => ({
  type: CLEAR_ITINERARY_AUTOCOMPLETE_PLACE,
  payload: payload,
  src: src,
});

export const updateItineraryAutocomplete = (
  payload: IUpdateItineraryPlaceValuePayload,
  src: ActionSources
): CreateItineraryActionTypes => ({
  type: UPDATE_ITINERARY_GOOGLE_AUTOCOMPLETE,
  payload: payload,
  src: src,
});

export const addItinerary = (
  payload: number,
  src: ActionSources
): CreateItineraryActionTypes => ({
  type: ADD_ITINERARY,
  payload: payload,
  src: src,
});

export const removeItinerary = (
  payload: number,
  src: ActionSources
): CreateItineraryActionTypes => ({
  type: REMOVE_ITINERARY,
  payload: payload,
  src: src,
});
