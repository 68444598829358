import { ActionSources, IFormFieldPayload } from '../../../types/base.types';
import { CreateFormActionTypes } from './createFormInfo.types';

export const SET_CREATE_MODE = 'SET_CREATE_MODE';
export const RESET_FORM = 'RESET_FORM';

// Generic
export const UPDATE_CREATE_FIELD = 'UPDATE_CREATE_FIELD';
export const GO_NEXT_STEP = 'GO_NEXT_STEP';
export const SUBMIT_FIRESTORE_POST = 'SUBMIT_FIRESTORE_POST';
export const SUBMIT_FIRESTORE_POST_SUCCESS = 'SUBMIT_FIRESTORE_POST_SUCCESS';
export const SUBMIT_FIRESTORE_POST_FAIL = 'SUBMIT_FIRESTORE_POST_FAIL';

export const SUBMIT_UPDATE_FIRESTORE_POST = 'SUBMIT_UPDATE_FIRESTORE_POST';

export const setCreateMode = (src: ActionSources): CreateFormActionTypes => ({
  type: SET_CREATE_MODE,
  payload: null,
  src: src,
});

export const resetForm = (src: ActionSources): CreateFormActionTypes => ({
  type: RESET_FORM,
  payload: null,
  src: src,
});

export const updateCreateField = (
  payload: IFormFieldPayload,
  src: ActionSources
): CreateFormActionTypes => ({
  type: UPDATE_CREATE_FIELD,
  payload: payload,
  src: src,
});

export const goNextStep = (src: ActionSources): CreateFormActionTypes => ({
  type: GO_NEXT_STEP,
  payload: null,
  src: src,
});

export const submitFirestorePost = (
  src: ActionSources
): CreateFormActionTypes => ({
  type: SUBMIT_FIRESTORE_POST,
  payload: null,
  src: src,
});

export const updateFirestorePost = (
  src: ActionSources
): CreateFormActionTypes => ({
  type: SUBMIT_UPDATE_FIRESTORE_POST,
  payload: null,
  src: src,
});
