import { ILabelValue } from '../types/base.types';

export const SearchParams = [
  {
    label: 'Country',
    value: 'country',
  },
  {
    label: 'Story tag',
    value: 'tag',
  },
  // {
  //   label: 'Most popular',
  //   value: 'popular',
  // },
  {
    label: 'Most recent',
    value: 'recent',
  },
];

export const postBudget = [
  { value: '', label: 'Indicate daily budget' },
  { value: 1, label: 'Very low: $0-50 a day' },
  { value: 2, label: 'Low: $51-100 a day' },
  { value: 3, label: 'Moderate: $101-200 a day' },
  { value: 4, label: 'Expensive: $200+ a day' },
];

export const travelMethods = {
  NONE: {
    noun: 'Select travel method',
    gerund: 'Select travel method',
    past: 'Select travel method',
  },
  PLANE: { noun: 'Flight', gerund: 'Flying to', past: 'Flew to' },
  BUS: { noun: 'Bus', gerund: 'Busing to', past: 'Bussed to' },
  TRAIN: { noun: 'Train', gerund: 'Training to', past: 'Trained to' },
  BOAT: { noun: 'Boat/Ferry', gerund: 'Boating to', past: 'Boated to' },
  CAR: { noun: 'Car', gerund: 'Driving to', past: 'Drove to' },
  TAXI: { noun: 'Taxi', gerund: 'Taxiing to', past: 'Taxied to' },
  BIKE: { noun: 'Bike', gerund: 'Biking to', past: 'Biked to' },
  WALK: { noun: 'Walk', gerund: 'Walking to', past: 'Walked to' },
};

export const postTags = [
  // value corresponds to the tagID
  { value: 1, label: 'beach', group: 'setting' },
  { value: 2, label: 'mountains', group: 'setting' },
  { value: 3, label: 'snorkeling', group: 'activity' },
  { value: 4, label: 'diving', group: 'activity' },
  { value: 5, label: 'party hardy', group: 'activity' },
  { value: 6, label: 'backpacker', group: 'vibe' },
  { value: 7, label: 'foodie heaven', group: 'vibe' },
  { value: 8, label: 'restaurants', group: 'activity' },
  { value: 9, label: 'street food', group: 'activity' },
  { value: 10, label: 'city', group: 'setting' },
  { value: 11, label: 'off the beaten path', group: 'vibe' },
  { value: 12, label: 'back country', group: 'setting' },
  { value: 13, label: 'rock climbing', group: 'activity' },
  { value: 14, label: 'out of this world', group: 'setting' },
  { value: 15, label: 'once in a life time', group: 'vibe' },
  { value: 16, label: 'bucket list', group: 'setting' },
  { value: 17, label: 'alternative tourism', group: 'activity' },
  { value: 18, label: 'cultural experience', group: 'activity' },
  { value: 19, label: 'cycling', group: 'activity' },
  { value: 20, label: 'sailing', group: 'activity' },
  { value: 21, label: 'island hopping', group: 'setting' },
  { value: 22, label: 'photography', group: 'activity' },
  { value: 23, label: 'bohemian', group: 'vibe' },
  { value: 24, label: 'authentic', group: 'vibe' },
  { value: 25, label: 'sports', group: 'activity' },
  { value: 26, label: 'skiing / Snowboarding', group: 'activity' },
  { value: 27, label: 'hiking', group: 'activity' },
  { value: 28, label: 'museums', group: 'activity' },
  { value: 29, label: 'seafood', group: 'activity' },
  { value: 30, label: 'historical ruins', group: 'setting' },
  { value: 31, label: 'vineyards', group: 'setting' },
  { value: 32, label: 'breweries', group: 'activity' },
  { value: 33, label: 'tours', group: 'activity' },
  { value: 34, label: 'gambling', group: 'activity' },
  { value: 35, label: 'drinking', group: 'activity' },
  { value: 36, label: 'extreme sports', group: 'activity' },
  { value: 37, label: 'resort', group: 'setting' },
  { value: 38, label: 'road trip', group: 'activity' },
  { value: 39, label: 'desert', group: 'setting' },
  { value: 40, label: 'nature', group: 'setting' },
  { value: 41, label: 'forest', group: 'setting' },
  { value: 42, label: 'wildlife', group: 'setting' },
  { value: 43, label: 'safari', group: 'activity' },
  { value: 44, label: 'volunteering', group: 'activity' },
  { value: 45, label: 'economic development', group: 'activity"' },
  { value: 46, label: 'exhilarating', group: 'vibe' },
  { value: 47, label: 'dangerous', group: 'vibe' },
  { value: 48, label: 'camping', group: 'activity' },
  { value: 49, label: 'climbing', group: 'activity' },
  { value: 50, label: 'wonder of the world', group: 'setting' },
  { value: 51, label: 'relaxing', group: 'setting' },
  { value: 52, label: 'chill', group: 'vibe' },
  { value: 53, label: 'local secret', group: 'setting' },
  { value: 54, label: 'bourdain would be proud', group: 'vibe' },
  { value: 55, label: 'market', group: 'setting' },
  { value: 56, label: 'lakes', group: 'setting' },
  { value: 57, label: 'fishing', group: 'activity' },
  { value: 58, label: 'LGBTQ friendly', group: 'setting' },
  { value: 59, label: 'touristy', group: 'setting' },
  { value: 60, label: 'busy', group: 'setting' },
  { value: 61, label: 'full moon', group: 'activity' },
  { value: 62, label: 'forever memories', group: 'vibe' },
  { value: 63, label: 'love', group: 'vibe' },
  { value: 64, label: 'clubbing', group: 'activity' },
  { value: 65, label: 'adventure', group: 'vibe' },
  { value: 66, label: 'getting lost', group: 'vibe' },
  { value: 67, label: 'music', group: 'activity' },
  { value: 68, label: 'scary ugly beautiful', group: 'setting' },
  { value: 69, label: 'dive bars', group: 'setting' },
  { value: 70, label: 'accessible', group: 'setting' },
  { value: 71, label: 'easy travel', group: 'setting' },
  { value: 72, label: 'the journey not the destination', group: 'setting' },
  { value: 73, label: 'surfing', group: 'activity' },
  { value: 74, label: 'beautiful', group: 'setting' },
  { value: 75, label: 'spiritual retreat', group: 'activity' },
  { value: 76, label: 'luxury', group: 'setting' },
  { value: 77, label: 'bougie', group: 'vibe' },
];

export const monthOptions = [
  { value: '', label: 'Select month' },
  { value: 'January', label: 'January' },
  { value: 'February', label: 'February' },
  { value: 'March', label: 'March' },
  { value: 'April', label: 'April' },
  { value: 'May', label: 'May' },
  { value: 'June', label: 'June' },
  { value: 'July', label: 'July' },
  { value: 'August', label: 'August' },
  { value: 'September', label: 'September' },
  { value: 'October', label: 'October' },
  { value: 'November', label: 'November' },
  { value: 'December', label: 'December' },
];

export const previousYearsOptions = (years: number): Array<ILabelValue> => {
  const currentYear = new Date().getFullYear();
  return Array(years)
    .fill(currentYear)
    .map((year: number, index: number) => ({
      value: year - index,
      label: year - index,
    }));
};

export const createExtraQuestionsInitialState = [
  {
    id: 1,
    readerQuestion: 'Main activities',
    writerQuestion: 'What are the main activities to do here?',
    placeholder: 'Main activities',
    writerAnswer: { quillHTML: '', quillDelta: null, rawText: '' },
  },
  {
    id: 2,
    readerQuestion: 'Places to stay',
    writerQuestion: 'Where should we stay?',
    placeholder: 'Places to stay',
    writerAnswer: { quillHTML: '', quillDelta: null, rawText: '' },
  },
  {
    id: 3,
    readerQuestion: 'Where and what to eat',
    writerQuestion: 'Where and what should we eat?',
    placeholder: 'Where and what to eat',
    writerAnswer: { quillHTML: '', quillDelta: null, rawText: '' },
  },
  {
    id: 4,
    readerQuestion: 'Useful resources',
    writerQuestion:
      'Do you have any useful resources to share? (sites, books, etc.)',
    placeholder: 'Useful resources',
    writerAnswer: { quillHTML: '', quillDelta: null, rawText: '' },
  },
  {
    id: 5,
    readerQuestion: 'General advice about this place and country',
    writerQuestion:
      'Do you have any general advice about this place or this country in general?',
    placeholder: 'General advice',
    writerAnswer: { quillHTML: '', quillDelta: null, rawText: '' },
  },
  {
    id: 6,
    readerQuestion: 'Other places to consider adding to your itinerary',
    writerQuestion:
      'If a reader has more time to spend here or in a neighboring city or country, can you recommend anywhere else for them to visit?',
    placeholder: 'Other places to visit on this trip',
    writerAnswer: { quillHTML: '', quillDelta: null, rawText: '' },
  },
];

export const updateLoadingMessages = [
  'Sending your updates...',
  'Reviewing your changes...',
  'Your post has been updated!',
  'Taking you to your updated post...',
];

export const createLoadingMessages = [
  'Sending your post to a cloud in the sky...',
  'A group of highly-trained primates are evaluating your post...',
  'Success! They like it.',
  'Taking you to your new post...',
];

export const maxUploadAttempts = 15;
