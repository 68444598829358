import { createSelector, Selector } from 'reselect';
import { IAppState } from '../../../types/appState.types';

const getTagIDs: Selector<IAppState, Array<number>> = (state) =>
  state.createPost.tags.tagIDs;

export const tagLimit = 3;

export const getTagsStateSelector = createSelector(
  getTagIDs,
  (TagsData) => TagsData
);

export const createTaggingStepValidator = createSelector(
  getTagsStateSelector,
  (tags) => Boolean(tags.length <= 3 && tags.length)
);
