import { CreateDescriptionActionTypes } from './createDescription.types';
import { ActionSources, IFormFieldPayload } from '../../../types/base.types';

export const UPDATE_CREATE_DESCRIPTION_FIELD =
  'UPDATE_CREATE_DESCRIPTION_FIELD';
export const UPDATE_QUOTE = 'UPDATE_QUOTE';
export const UPDATE_DATE_VISITED_MONTH = 'UPDATE_DATE_MONTH';
export const UPDATE_DATE_VISITED_YEAR = 'UPDATE_DATE_YEAR';

export const updateCreateDescriptionField = (
  payload: IFormFieldPayload,
  src: ActionSources
): CreateDescriptionActionTypes => ({
  type: UPDATE_CREATE_DESCRIPTION_FIELD,
  payload: payload,
  src: src
});

export const updateDateVisitedMonth = (
  payload: IFormFieldPayload,
  src: ActionSources
): CreateDescriptionActionTypes => ({
  type: UPDATE_DATE_VISITED_MONTH,
  payload: payload,
  src: src
});

export const updateDateVisitedYear = (
  payload: IFormFieldPayload,
  src: ActionSources
): CreateDescriptionActionTypes => ({
  type: UPDATE_DATE_VISITED_YEAR,
  payload: payload,
  src: src
});
