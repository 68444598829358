import React, { useEffect, useCallback } from 'react';

import './App.scss';
import '../node_modules/quill/dist/quill.snow.css';

// redux config
import { connect, ConnectedProps } from 'react-redux';
import * as UI_SELECTORS from './store/ui/ui.selectors';
import * as UI_ACTIONS from './store/ui/ui.actions';
import * as USER_SELECTORS from './store/user/user.selectors';
import * as USER_ACTIONS from './store/user/user.actions';
import { isAggregatesLoadingSelector } from './store/aggregates/aggregates.selectors';

// components
import GlobalHeader from './components/GlobalHeader/GlobalHeader.component';
import GlobalFooter from './components/GlobalFooter/GlobalFooter.component';
import Modal from './components/Modal/Modal.component';
import MainRouterOutlet from './pages/MainRouterOutlet.component';
import { withRouter, RouteComponentProps } from 'react-router';
import LoginForm from './components/LoginForm/LoginForm.component';
import JoinForm from './components/JoinForm/JoinForm.component';

import { GlobalGridContainer } from './styled-components/shared';

import { ActionSources } from './types/base.types';
import { Dispatch } from 'redux';
import { IAppState } from './types/appState.types';
import { ModalTypes } from './store/ui/ui.types';

// *******************
// *** REACT-REDUX ***
// *******************

const mapState = (state: IAppState) => ({
  mobileMenuState: UI_SELECTORS.isMobileMenuOpenSelector(state),
  isModalOpen: UI_SELECTORS.isModalOpenSelector(state),
  modalType: UI_SELECTORS.modalTypeSelector(state),
  authorizedState: USER_SELECTORS.isAuthenticatedSelector(state),
  loggedInUserID: USER_SELECTORS.loggedInUserIDSelector(state),
  isUserLoading: USER_SELECTORS.isUserLoadingSelector(state),
  isAggregatesLoading: isAggregatesLoadingSelector(state),
  loggedInUserData: USER_SELECTORS.loggedInUserDataSelector(state),
});

const mapDispatch = (dispatch: Dispatch) => ({
  appBootstrapInitHandler: () => {
    dispatch(UI_ACTIONS.appBootstrapInit());
  },
  appBootstrapCompleteHandler: () => {
    dispatch(UI_ACTIONS.appBootstrapComplete(ActionSources.APP_DID_MOUNT));
  },
  toggleMenuHandler: () => {
    dispatch(UI_ACTIONS.toggleMobileMenu(ActionSources.GLOBAL_MENU));
  },
  toggleModalHandler: (payload: ModalTypes | null) => {
    dispatch(UI_ACTIONS.toggleModal(payload, ActionSources.GLOBAL_MENU));
  },
  logoutHandler: () => {
    dispatch(USER_ACTIONS.logoutUserAction(ActionSources.GLOBAL_MENU));
  },
});

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

// *****

interface IAppProps extends ReduxProps, RouteComponentProps {}

const App: React.FC<IAppProps> = ({
  mobileMenuState,
  toggleMenuHandler,
  toggleModalHandler,
  isModalOpen,
  modalType,
  authorizedState,
  loggedInUserID,
  logoutHandler,
  appBootstrapInitHandler,
  appBootstrapCompleteHandler,
  isUserLoading,
  isAggregatesLoading,
}) => {
  // ***** Callbacks
  const appBootstrapInitHandler_CB = useCallback(
    () => appBootstrapInitHandler(),
    [appBootstrapInitHandler]
  );

  const appBootstrapCompleteHandler_CB = useCallback(
    () => appBootstrapCompleteHandler(),
    [appBootstrapCompleteHandler]
  );
  // *****

  useEffect(() => {
    appBootstrapInitHandler_CB();
  }, [appBootstrapInitHandler_CB]);

  useEffect(() => {
    if (!isUserLoading && !isAggregatesLoading) {
      appBootstrapCompleteHandler_CB();
    }
  }, [isUserLoading, isAggregatesLoading, appBootstrapCompleteHandler_CB]);

  return (
    <>
      <GlobalHeader
        isMenuOpen={mobileMenuState}
        toggleMenu={toggleMenuHandler}
        toggleModal={toggleModalHandler}
        isAuthorized={authorizedState}
        profileID={loggedInUserID}
        logoutHandler={logoutHandler}
      />
      <GlobalGridContainer>
        <MainRouterOutlet />
        <GlobalFooter />
      </GlobalGridContainer>
      {isModalOpen ? (
        <Modal
          isModalOpen={isModalOpen}
          toggleModalHandler={toggleModalHandler}
        >
          {modalType === ModalTypes.LOGIN ? <LoginForm /> : <JoinForm />}
        </Modal>
      ) : null}
    </>
  );
};

export default withRouter(connector(App));
