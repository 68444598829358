import {
  mergeMap,
  catchError,
  filter,
  withLatestFrom,
  tap,
} from 'rxjs/operators';
import { from, of } from 'rxjs';

import { combineEpics, Epic, ofType } from 'redux-observable';

import * as CREATE_FORM_INFO_ACTIONS from './createFormInfo.actions';
import { buildPost } from '../../../services/builder.service';
import {
  completedCreateFormDataSelector,
  newPostIDSelector,
} from './createFormInfo.selectors';
import firestoreService from '../../../services/firestore.service';
import { ActionSources } from '../../../types/base.types';
import {
  completedUpdateFormDataSelector,
  updatePostIDSelector,
} from '../../updatePost/updatePost.selectors';

const SubmitNewPostToFirestoreEpic: Epic<any> = (action$, state$) =>
  action$.pipe(
    ofType(CREATE_FORM_INFO_ACTIONS.SUBMIT_FIRESTORE_POST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      of({
        postID: newPostIDSelector(state),
        data: buildPost(completedCreateFormDataSelector(state)),
      }).pipe(
        mergeMap((newPost) =>
          from(
            firestoreService.createDocument(
              'posts',
              newPost.postID,
              newPost.data
            )
          ).pipe(
            filter((req) => Boolean(req)),
            mergeMap(() =>
              of({
                type: CREATE_FORM_INFO_ACTIONS.SUBMIT_FIRESTORE_POST_SUCCESS,
                payload: {
                  submitType: 'CREATE',
                  authorID: newPost.data.admin.authorID,
                  postID: newPost.data.admin.postID,
                  data: newPost.data,
                },
                src: ActionSources.CREATE_FROM_INFO_EPIC,
              })
            )
          )
        )
      )
    ),
    catchError((err: string) =>
      of({
        type: CREATE_FORM_INFO_ACTIONS.SUBMIT_FIRESTORE_POST_FAIL,
        payload: err,
        src: ActionSources.CREATE_FROM_INFO_EPIC,
      })
    )
  );

const SubmitUpdateToFirestoreEpic: Epic<any> = (action$, state$) =>
  action$.pipe(
    ofType(CREATE_FORM_INFO_ACTIONS.SUBMIT_UPDATE_FIRESTORE_POST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      of({
        postID: updatePostIDSelector(state),
        data: buildPost(completedUpdateFormDataSelector(state), 'update'),
      }).pipe(
        tap((updatePost) => console.log(updatePost)),
        mergeMap((updatePost) =>
          from(
            firestoreService.updateDocument(
              'posts',
              updatePost.postID,
              updatePost.data
            )
          ).pipe(
            filter((req) => Boolean(req)),
            mergeMap(() =>
              of({
                type: CREATE_FORM_INFO_ACTIONS.SUBMIT_FIRESTORE_POST_SUCCESS,
                payload: {
                  submitType: 'UPDATE',
                  authorID: updatePost.data.admin.authorID,
                  postID: updatePost.data.admin.postID,
                  data: updatePost.data,
                },
                src: ActionSources.CREATE_FROM_INFO_EPIC,
              })
            )
          )
        )
      )
    ),
    catchError((err: string) =>
      of({
        type: CREATE_FORM_INFO_ACTIONS.SUBMIT_FIRESTORE_POST_FAIL,
        payload: err,
        src: ActionSources.CREATE_FROM_INFO_EPIC,
      })
    )
  );

export const CreateFormInfoEpic = combineEpics(
  SubmitNewPostToFirestoreEpic,
  SubmitUpdateToFirestoreEpic
);
