import {
  mergeMap,
  catchError,
  filter,
  map,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as PROFILE_ACTIONS from './profile.actions';
import * as POST_LISTING_ACTIONS from '../postListing/postListing.actions';
import * as USER_ACTIONS from '../user/user.actions';
import * as UPDATE_PROFILE_ACTIONS from '../updateProfile/updateProfile.actions';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { ActionSources } from '../../types/base.types';

import firestoreService from '../../services/firestore.service';
import {
  updatedDisplayNameSelector,
  updatedProfilePictureSelector,
} from '../updateProfile/updateProfile.selectors';
import { loggedInUserIDSelector } from '../user/user.selectors';

// INFO: Set listing type (user), get user data for the loaded profile
const GetProfilePostsEpic: Epic<any> = (action$) =>
  action$.pipe(
    ofType(PROFILE_ACTIONS.LOAD_PROFILE_PAGE),
    mergeMap((action) =>
      of(
        {
          type: POST_LISTING_ACTIONS.SET_POST_LISTING,
          payload: {
            queryBy: 'user',
            filterBy: action.payload,
          },
          src: ActionSources.PROFILE_PAGE_EPIC,
        },
        {
          type: USER_ACTIONS.GET_USER_DOCUMENT,
          payload: action.payload,
          src: ActionSources.PROFILE_PAGE_EPIC,
        }
      )
    )
  );

export const DeletePostAttemptEpic: Epic<any> = (action$) =>
  action$.pipe(
    ofType(PROFILE_ACTIONS.DELETE_POST),
    mergeMap((action) =>
      from(firestoreService.deleteDocument('posts', action.payload)).pipe(
        filter((data: any) => Boolean(data)),
        map(() => ({
          type: PROFILE_ACTIONS.DELETE_POST_SUCCESS,
          payload: action.payload,
          src: ActionSources.PROFILE_PAGE_EPIC,
        })),
        catchError((err: string) => {
          return of({
            type: PROFILE_ACTIONS.DELETE_POST_FAIL,
            payload: err,
            src: ActionSources.PROFILE_PAGE_EPIC,
          });
        })
      )
    )
  );

export const UpdateProfileAttemptEpic: Epic<any> = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_PROFILE_ACTIONS.SUBMIT_PROFILE_UPDATE),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      from(
        firestoreService.updateDocument(
          'users',
          loggedInUserIDSelector(state),
          {
            displayName: updatedDisplayNameSelector(state),
            profilePicture: updatedProfilePictureSelector(state),
          }
        )
      ).pipe(
        withLatestFrom(state$),
        mergeMap(([res, state]) => {
          return of({
            type: UPDATE_PROFILE_ACTIONS.SUBMIT_PROFILE_UPDATE_SUCCESS,
            payload: {
              displayName: updatedDisplayNameSelector(state),
              profilePicture: updatedProfilePictureSelector(state),
            },
            src: ActionSources.PROFILE_PAGE_EPIC,
          });
        })
      )
    ),
    catchError((err: string) =>
      of({
        type: UPDATE_PROFILE_ACTIONS.SUBMIT_PROFILE_UPDATE_FAIL,
        payload: err,
        src: ActionSources.PROFILE_PAGE_EPIC,
      })
    )
  );

export const ProfilePageInitEpic = combineEpics(
  GetProfilePostsEpic,
  DeletePostAttemptEpic,
  UpdateProfileAttemptEpic
);
