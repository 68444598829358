import styled, { css } from 'styled-components/macro';
import * as vars from '../../utils/phovoVars';
import { Link } from 'react-router-dom';

interface IHeaderProps {
  isMenuOpen?: boolean;
}

export const Header = styled.header<IHeaderProps>`
  position: fixed;
  overflow: hidden;
  min-height: 45px;
  max-height: 45px;
  width: 100%;
  background-color: rgba(53, 53, 53, 0.95);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  transition: all 750ms ease-out;
  z-index: 100;

  ${(p) =>
    p.isMenuOpen &&
    css`
      max-height: 500px;
    `};

  @media (min-width: ${vars.breakpoints.lg}) {
    min-height: 55px;
    max-height: 55px;
    display: flex;
    justify-content: space-between;
  }
`;

export const LogoLink = styled(Link)``;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.img`
  height: 45px;
  margin: 0 15px;
`;

export const HamburgerContainer = styled.div`
  display: inline-block;
  background: transparent;
  border: none;

  @media (min-width: ${vars.breakpoints.lg}) {
    display: none;
  }
`;

export const GlobalNav = styled.nav`
  @media (min-width: ${vars.breakpoints.lg}) {
    display: flex;
    max-height: none;
    align-items: center;
    flex-direction: row;
    box-shadow: none;
  }
`;

export const GlobalNavLink = styled.a`
  border: none;
  background: transparent;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 700;
  text-decoration: none;
  height: 100%;
  padding: 20px 0;
  text-transform: uppercase;
  display: flex;
  transition: all 250ms ease-out;
  width: 100%;

  &:hover {
    background-color: ${vars.branding.primary};
    cursor: pointer;
  }

  span {
    margin: 0 15px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }

  @media (min-width: ${vars.breakpoints.lg}) {
    width: auto;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }
`;

export const GlobalNavLinkRR = GlobalNavLink.withComponent(Link);
export const BtnGlobalNavLinkBtn = GlobalNavLink.withComponent('button');
