import {
  mergeMap,
  catchError,
  filter,
  map,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as POST_DETAIL_ACTIONS from './postDetail.actions';
import * as USER_ACTIONS from '../user/user.actions';

import { combineEpics, Epic, ofType } from 'redux-observable';
import { ActionSources } from '../../types/base.types';

import { currentPostSelector } from './postDetail.selectors';
import firestoreService from '../../services/firestore.service';

// INFO: SET_POST_LISTING => sets queryBy and filterBy. If posts are already loaded dispatch DISPLAY_CACHED_POSTS, if not, get posts from DB through dispatching GET_POST_LISTING
const LoadPostDetailEpic: Epic<any> = (action$, state$) =>
  action$.pipe(
    ofType(POST_DETAIL_ACTIONS.LOAD_POSTDETAIL_PAGE),
    map((action) => ({
      type: POST_DETAIL_ACTIONS.GET_POST,
      payload: action.payload,
      src: ActionSources.POST_DETAIL_EPIC,
    }))
  );

// INFO: If post doesn't exist get post document, get user document of post author, OR display cached post.
const GetPostDetailAttemptEpic: Epic<any> = (action$, state$) =>
  action$.pipe(
    ofType(POST_DETAIL_ACTIONS.GET_POST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      of(currentPostSelector(state)).pipe(
        mergeMap((cachedPost) => {
          if (!cachedPost) {
            return from(
              firestoreService.fbDocumentQuery('posts', action.payload)
            ).pipe(
              filter((req: any) => Boolean(req)),
              mergeMap((req: any) =>
                of(
                  {
                    type: POST_DETAIL_ACTIONS.GET_POST_SUCCESS,
                    payload: req,
                    src: ActionSources.POST_DETAIL_EPIC,
                  },
                  {
                    type: USER_ACTIONS.GET_USER_DOCUMENT,
                    payload: req.data.admin.authorID,
                    src: ActionSources.POST_DETAIL_EPIC,
                  }
                )
              )
            );
          } else {
            return of(
              {
                type: POST_DETAIL_ACTIONS.DISPLAY_CACHED_POST,
                src: ActionSources.POST_DETAIL_EPIC,
              },
              {
                type: USER_ACTIONS.GET_USER_DOCUMENT,
                payload: cachedPost.admin.authorID,
                src: ActionSources.POST_DETAIL_EPIC,
              }
            );
          }
        })
      )
    ),
    catchError((err: string) =>
      of({
        type: POST_DETAIL_ACTIONS.GET_POST_FAIL,
        payload: err,
        src: ActionSources.POST_DETAIL_EPIC,
      })
    )
  );

export const PostDetailEpic = combineEpics(
  LoadPostDetailEpic,
  GetPostDetailAttemptEpic
);
