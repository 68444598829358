import { combineReducers } from 'redux';
import CreateDescriptionReducer from './createDescription/createDescription.reducer';
import CreateFormReducer from './createFormInfo/createFormInfo.reducer';
import CreatePhotoUploadReducer from './createPhotoUpload/createPhotoUpload.reducer';
import CreateItineraryReducer from './createItinerary/createItinerary.reducer';
import CreateLocationReducer from './createLocation/createLocation.reducer';
import CreateTaggingReducer from './createTagging/createTagging.reducer';
import CreateExtraQuestionsReducer from './createExtraQuestions/createExtraQuestions.reducer';
import {
  IPostLocation,
  IPostDescription,
  IPostPhotos,
  IPostTags,
  IPostItinerary,
  IPostExtraQuestions,
} from '../../types/post.types';
import { ICreateFormInfo } from './createFormInfo/createFormInfo.types';

export interface ICreatePostState {
  formInfo: ICreateFormInfo;
  location: IPostLocation;
  description: IPostDescription;
  uploadMedia: IPostPhotos;
  tags: IPostTags;
  itinerary: IPostItinerary;
  extraQuestions: IPostExtraQuestions;
}

const createStateReducers = combineReducers({
  formInfo: CreateFormReducer,
  location: CreateLocationReducer,
  description: CreateDescriptionReducer,
  uploadMedia: CreatePhotoUploadReducer,
  tags: CreateTaggingReducer,
  itinerary: CreateItineraryReducer,
  extraQuestions: CreateExtraQuestionsReducer,
});

export default createStateReducers;
