import * as AGG_ACTIONS from './aggregates.actions';
import { IAggregatesState, AggregatesActionTypes } from './aggregates.types';

const initialState: IAggregatesState = {
  isLoading: null,
  isLoadingError: null,
  cities: {},
  countries: {},
  general: {},
  tags: {},
};

export default function AggregatesReducer(
  state = initialState,
  action: AggregatesActionTypes
) {
  switch (action.type) {
    case AGG_ACTIONS.GET_AGGREGATES: {
      return {
        ...state,
        isLoading: true,
        isLoadingError: false,
      };
    }

    case AGG_ACTIONS.GET_AGGREGATES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    }

    case AGG_ACTIONS.GET_AGGREGATES_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoadingError: true,
      };
    }

    default:
      return state;
  }
}
