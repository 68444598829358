import * as UI_ACTIONS from './ui.actions';
import { ActionSources } from '../../types/base.types';

export interface IUIState {
  isAppBootstrapComplete: boolean;
  isMobileMenuOpen: boolean;
  isModalOpen: boolean;
  modalType: ModalTypes | null;
}

export enum ModalTypes {
  JOIN = 'join',
  LOGIN = 'login',
}

interface IAppBootstrapCompleteAction {
  type: typeof UI_ACTIONS.APP_BOOTSTRAP_COMPLETE;
  payload: null;
  src: ActionSources;
}

interface IToggleMobileMenuAction {
  type: typeof UI_ACTIONS.TOGGLE_MOBILE_MENU;
  payload: null;
  src: ActionSources;
}

interface IToggleModalAction {
  type: typeof UI_ACTIONS.TOGGLE_MODAL;
  payload: ModalTypes | null;
  src: ActionSources;
}

export type UIActionTypes =
  | IAppBootstrapCompleteAction
  | IToggleMobileMenuAction
  | IToggleModalAction;
