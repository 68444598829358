import { combineReducers } from 'redux';
// REDUCERS
import AggregatesReducer from './aggregates/aggregates.reducer';
import JoinReducer from './join/join.reducer';
import SearchReducer from './search/search.reducer';
import PostListingReducer from './postListing/postListing.reducer';
import UIReducer from './ui/ui.reducer';
import LoginReducer from './login/login.reducer';
import UserReducer from './user/user.reducer';
import PostDetailReducer from './postDetail/postDetail.reducer';
import UpdatePostReducer from './updatePost/updatePost.reducer';
import ProfileReducer from './profile/profile.reducer';
import UpdateProfileReducer from './updateProfile/updateProfile.reducer';
import createStateReducers from './createPost';

// EPICS
import { combineEpics } from 'redux-observable';
import { LoginAttemptEpic } from './login/login.epics';
import { GetUserDocumentAttemptEpic } from './user/user.epics';
import { GetAggregatesAttemptEpic } from './aggregates/aggregates.epics';
import { AppBootstrapEpic } from './ui/ui.epics';
import { PostListingEpic } from './postListing/postListing.epics';
import { ProfilePageInitEpic } from './profile/profile.epics';
import { PostDetailEpic } from './postDetail/postDetail.epics';
import { CreateFormInfoEpic } from './createPost/createFormInfo/createFormInfo.epics';

export const phovoState = combineReducers({
  search: SearchReducer,
  ui: UIReducer,
  createPost: createStateReducers,
  updatePost: UpdatePostReducer,
  joinForm: JoinReducer,
  loginForm: LoginReducer,
  user: UserReducer,
  postDetail: PostDetailReducer,
  postListing: PostListingReducer,
  aggregates: AggregatesReducer,
  profile: ProfileReducer,
  updateProfile: UpdateProfileReducer,
});

export const phovoSideEffects = combineEpics(
  LoginAttemptEpic,
  GetUserDocumentAttemptEpic,
  GetAggregatesAttemptEpic,
  AppBootstrapEpic,
  PostListingEpic,
  ProfilePageInitEpic,
  PostDetailEpic,
  CreateFormInfoEpic
);
