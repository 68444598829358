import { JoinActionTypes } from './join.types';
import { IFormFieldPayload, ActionSources } from '../../types/base.types';

export const UPDATE_JOIN_FIELD = 'UPDATE_JOIN_FIELD';
export const COMPLETE_STEP = 'COMPLETE_STEP';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export const RESET_FORM = 'RESET_FORM';

export const updateJoinField = (
  payload: IFormFieldPayload,
  src: ActionSources
): JoinActionTypes => {
  return {
    type: UPDATE_JOIN_FIELD,
    payload: payload,
    src: src,
  };
};

export const completeStep = (src: ActionSources): JoinActionTypes => {
  return {
    type: COMPLETE_STEP,
    payload: null,
    src: src,
  };
};

export const createUser = (src: ActionSources): JoinActionTypes => {
  return {
    type: CREATE_USER,
    payload: null,
    src: src,
  };
};

export const createUserSuccess = (
  payload: string,
  src: ActionSources
): JoinActionTypes => {
  return {
    type: CREATE_USER_SUCCESS,
    payload: payload,
    src: src,
  };
};

export const createUserFail = (src: ActionSources): JoinActionTypes => {
  return {
    type: CREATE_USER_FAIL,
    payload: null,
    src: src,
  };
};

export const resetForm = (src: ActionSources): JoinActionTypes => {
  return {
    type: RESET_FORM,
    payload: null,
    src: src,
  };
};
