import { createSelector, Selector } from 'reselect';
import * as validators from '../../services/validation.service';
import { IAppState } from '../../types/appState.types';

// State Getters
const getEmail: Selector<IAppState, string> = (state) => state.loginForm.email;
const getPwd: Selector<IAppState, string> = (state) => state.loginForm.pwd;
const getIsSuccess: Selector<IAppState, boolean | null> = (state) =>
  state.loginForm.isSuccess;
const getFormError: Selector<IAppState, string> = (state) =>
  state.loginForm.formError;

export const loginEmailSelector = createSelector(
  getEmail,
  (stateValue) => stateValue
);

export const loginPwdSelector = createSelector(
  getPwd,
  (stateValue) => stateValue
);

export const isLoginSuccessSelector = createSelector(
  getIsSuccess,
  (stateValue) => stateValue
);

export const isLoginErrorSelector = createSelector(
  getFormError,
  (stateValue) => stateValue
);

// Validations
export const loginFormConfig = {
  email: [
    validators.isRequired(),
    validators.isEmail(),
    validators.maxChar(75),
  ],
  pwd: [validators.isRequired(), validators.maxChar(75)],
};

const emailValidations = [...loginFormConfig.email];
const pwdValidations = [...loginFormConfig.pwd];

export const emailValidationsSelector = createSelector(getEmail, (stateValue) =>
  validators.validate(stateValue, emailValidations)
);

export const pwdValidationsSelector = createSelector(getPwd, (stateValue) =>
  validators.validate(stateValue, pwdValidations)
);

// Derived state
export const formValidSelector = createSelector(
  [emailValidationsSelector, pwdValidationsSelector],
  (email, pwd) => [...email, ...pwd].length === 0
);
