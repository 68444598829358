import * as CREATE_FORM_ACTIONS from './createFormInfo.actions';
import * as UPDATEPOST_ACTIONS from '../../updatePost/updatePost.actions';
import { CreateFormActionTypes, ICreateFormInfo } from './createFormInfo.types';
import { UpdatePostActionTypes } from '../../updatePost/updatePost.types';

const initialState: ICreateFormInfo = {
  pageMode: null,
  currentStep: 1,
  isRequestPending: false,
  isRequestSuccess: null,
};

export default function CreateFormReducer(
  state = initialState,
  action: CreateFormActionTypes | UpdatePostActionTypes
): ICreateFormInfo {
  switch (action.type) {
    // *** General
    case CREATE_FORM_ACTIONS.SET_CREATE_MODE:
      return {
        ...initialState,
        pageMode: 'CREATE',
      };

    case UPDATEPOST_ACTIONS.SET_UPDATE_MODE: // NOTE: updatePost.reducer captures updateFlow related state
      return {
        ...initialState,
        pageMode: 'UPDATE',
      };

    case CREATE_FORM_ACTIONS.RESET_FORM:
      return {
        ...initialState,
      };

    case UPDATEPOST_ACTIONS.SET_UPDATE_DATA:
      return {
        ...state,
        currentStep: 6,
      };

    // *** FORM NAVIGATION AND SUBMISSION

    case CREATE_FORM_ACTIONS.GO_NEXT_STEP:
      return {
        ...state,
        currentStep: ++state.currentStep,
      };

    case CREATE_FORM_ACTIONS.SUBMIT_FIRESTORE_POST:
    case CREATE_FORM_ACTIONS.SUBMIT_UPDATE_FIRESTORE_POST:
      return {
        ...state,
        isRequestPending: true,
      };

    case CREATE_FORM_ACTIONS.SUBMIT_FIRESTORE_POST_SUCCESS:
      return {
        ...state,
        isRequestSuccess: true,
        isRequestPending: false,
      };

    case CREATE_FORM_ACTIONS.SUBMIT_FIRESTORE_POST_FAIL:
      return {
        ...state,
        isRequestSuccess: false,
        isRequestPending: false,
      };
    default:
      return state;
  }
}
