import React, { useEffect, useCallback } from 'react';

import * as el from './Modal.styled';
import logo from '../../assets/images/logo-circle-white.svg';
import { IconButton } from '../../styled-components/shared';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { ModalTypes } from '../../store/ui/ui.types';

interface IModalProps {
  isModalOpen: boolean;
  minimal?: boolean;
  toggleModalHandler: (modalType: ModalTypes | null) => void;
}

const Modal: React.FC<IModalProps> = ({
  isModalOpen,
  minimal,
  toggleModalHandler,
  children,
}) => {
  const closeOnEsc = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        toggleModalHandler(null);
      }
    },
    [toggleModalHandler]
  );

  useEffect(() => {
    window.addEventListener('keydown', closeOnEsc);
    return () => {
      window.removeEventListener('keydown', closeOnEsc);
    };
  }, [closeOnEsc]);

  return (
    <el.Overlay isOpen={isModalOpen}>
      <el.Modal flexibleHeight={minimal}>
        {minimal ? null : (
          <el.LogoContainer>
            <el.Logo src={logo} />
          </el.LogoContainer>
        )}
        <el.CloseButtonContainer>
          <IconButton onClick={() => toggleModalHandler(null)}>
            <FontAwesomeIcon icon={faTimes} size="2x" />
          </IconButton>
        </el.CloseButtonContainer>
        <el.ModalContent>{children}</el.ModalContent>
      </el.Modal>
    </el.Overlay>
  );
};

export default Modal;
