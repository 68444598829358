import * as USER_ACTIONS from './user.actions';
import * as UPDATE_PROFILE_ACTIONS from '../updateProfile/updateProfile.actions';
import * as JOIN_ACTIONS from '../join/join.actions';
import * as LOGIN_ACTIONS from '../login/login.actions';
import * as CREATE_FORM_ACTIONS from '../createPost/createFormInfo/createFormInfo.actions';
import { IUserState, UserActionTypes } from './user.types';
import { JoinActionTypes } from '../join/join.types';
import { UpdateProfileActionTypes } from '../updateProfile/updateProfile.types';
import { LoginActionTypes } from '../login/login.types';
import { CreateFormActionTypes } from '../createPost/createFormInfo/createFormInfo.types';

const initialState: IUserState = {
  isProfilePageLoading: false,
  isLoading: true, // wait for auth check
  isLoadingError: false,
  loggedInUserID: null,
  cachedUsers: null,
};

export default function UserReducer(
  state = initialState,
  action:
    | UserActionTypes
    | JoinActionTypes
    | UpdateProfileActionTypes
    | LoginActionTypes
    | CreateFormActionTypes
): IUserState {
  switch (action.type) {
    case USER_ACTIONS.IS_AUTHORIZED_USER:
    case JOIN_ACTIONS.CREATE_USER_SUCCESS:
    case LOGIN_ACTIONS.AUTHENTICATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loggedInUserID: action.payload,
      };

    case USER_ACTIONS.LOGOUT_USER:
    case USER_ACTIONS.IS_NOT_AUTHORIZED_USER:
      return {
        ...state,
        isLoading: false,
        isLoadingError: false,
        loggedInUserID: null,
      };

    case USER_ACTIONS.GET_USER_DOCUMENT:
      return {
        ...state,
        isLoadingError: false,
        isLoading: true,
      };

    case USER_ACTIONS.GET_USER_DOCUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingError: false,
        cachedUsers: {
          ...state.cachedUsers,
          [action.payload.id]: { ...action.payload.data },
        },
      };

    case USER_ACTIONS.DISPLAY_CACHED_USER: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case USER_ACTIONS.GET_USER_DOCUMENT_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingError: true,
      };

    case UPDATE_PROFILE_ACTIONS.SUBMIT_PROFILE_UPDATE_SUCCESS:
      return state.loggedInUserID
        ? {
            ...state,
            cachedUsers: {
              ...state.cachedUsers,
              [state.loggedInUserID]: {
                ...state.cachedUsers[state.loggedInUserID],
                ...action.payload,
              },
            },
          }
        : { ...state };

    case CREATE_FORM_ACTIONS.SUBMIT_FIRESTORE_POST_SUCCESS:
      return state.loggedInUserID
        ? {
            ...state,
            cachedUsers: {
              ...state.cachedUsers,
              [state.loggedInUserID]: {
                ...state.cachedUsers[state.loggedInUserID],
                totalPosts:
                  state.cachedUsers[state.loggedInUserID].totalPosts + 1,
              },
            },
          }
        : { ...state };

    default:
      return state;
  }
}
