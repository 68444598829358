import { ActionSources } from '../../types/base.types';
import { UserActionTypes } from './user.types';

export const IS_AUTHORIZED_USER = 'IS_AUTHORIZED_USER';
export const IS_NOT_AUTHORIZED_USER = 'IS_NOT_AUTHORIZED_USER';

export const LOGOUT_USER = 'LOGOUT_USER';

export const GET_USER_DOCUMENT = 'GET_USER_DOCUMENT';
export const GET_USER_DOCUMENT_SUCCESS = 'GET_USER_DOCUMENT_SUCCESS';
export const GET_USER_DOCUMENT_FAIL = 'GET_USER_DOCUMENT_FAIL';

export const DISPLAY_CACHED_USER = 'DISPLAY_CACHED_USER';

export const isAuthorizedUserAction = (
  payload: string,
  src: ActionSources
): UserActionTypes => {
  return {
    type: IS_AUTHORIZED_USER,
    payload: payload,
    src: src,
  };
};

export const isNotAuthorizedUserAction = (
  src: ActionSources
): UserActionTypes => {
  return {
    type: IS_NOT_AUTHORIZED_USER,
    payload: null,
    src: src,
  };
};

export const logoutUserAction = (src: ActionSources): UserActionTypes => {
  return {
    type: LOGOUT_USER,
    payload: null,
    src: src,
  };
};

export const getUserDocumentAction = (
  payload: string,
  src: ActionSources
): UserActionTypes => {
  return {
    type: GET_USER_DOCUMENT,
    payload: payload,
    src: src,
  };
};

export const displayCachedUserAction = (
  src: ActionSources
): UserActionTypes => {
  return {
    type: DISPLAY_CACHED_USER,
    payload: null,
    src: src,
  };
};
