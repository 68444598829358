import * as UPDATE_PROFILE_ACTIONS from './updateProfile.actions';
import {
  IUpdateProfileState,
  UpdateProfileActionTypes,
} from './updateProfile.types';

const initialState: IUpdateProfileState = {
  updatedDisplayName: '',
  updatedProfilePicture: null,
  isUploadError: false,
  isUpdateSuccess: false,
  isUpdateError: false,
};

export default function UpdateProfileReducer(
  state = initialState,
  action: UpdateProfileActionTypes
): IUpdateProfileState {
  switch (action.type) {
    case UPDATE_PROFILE_ACTIONS.INIT_UPDATE_FORM:
      return { ...state, ...action.payload };
    case UPDATE_PROFILE_ACTIONS.RESET_UPDATE_FORM:
      return {
        ...state,
        isUploadError: false,
        isUpdateSuccess: false,
        isUpdateError: false,
      };

    case UPDATE_PROFILE_ACTIONS.UPDATE_PROFILE_FIELD:
      return {
        ...state,
        ...action.payload,
      };

    case UPDATE_PROFILE_ACTIONS.UPLOAD_CLOUDINARY_PHOTO_SUCCESS:
      return {
        ...state,
        updatedProfilePicture: { ...action.payload },
        isUploadError: false,
      };

    case UPDATE_PROFILE_ACTIONS.UPLOAD_CLOUDINARY_PHOTO_FAIL:
      return {
        ...state,
        isUploadError: true,
      };

    case UPDATE_PROFILE_ACTIONS.SUBMIT_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdateSuccess: true,
        isUpdateError: false,
      };

    case UPDATE_PROFILE_ACTIONS.SUBMIT_PROFILE_UPDATE_FAIL:
      return {
        ...state,
        isUpdateSuccess: false,
        isUpdateError: true,
      };

    default:
      return state;
  }
}
