import { ActionSources } from '../../../types/base.types';
import { ICloudinaryUploadResponse } from '../../../types/post.types';
import {
  CreatePhotoUploadActionTypes,
  IPhotoCaptionPayload
} from './createPhotoUpload.types';

export const OPEN_UPLOAD_WIDGET = 'OPEN_UPLOAD_WIDGET';
export const UPLOAD_INIT = 'UPLOAD_INIT';
export const UPLOAD_RESET = 'UPLOAD_RESET';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAIL = 'UPLOAD_FAILURE';
export const DELETE_UPLOAD_PHOTO = 'DELETE_UPLOAD_PHOTO';
export const UPDATE_PHOTO_CAPTION = 'UPDATE_PHOTO_CAPTION';
export const MAKE_COVER_PHOTO = 'MAKE_COVER_PHOTO';

export const openUploadWidget = (
  payload: number,
  src: ActionSources
): CreatePhotoUploadActionTypes => ({
  type: OPEN_UPLOAD_WIDGET,
  payload: payload,
  src: src
});

export const uploadInit = (
  src: ActionSources
): CreatePhotoUploadActionTypes => ({
  type: UPLOAD_INIT,
  payload: null,
  src: src
});

export const uploadReset = (
  src: ActionSources
): CreatePhotoUploadActionTypes => ({
  type: UPLOAD_RESET,
  payload: null,
  src: src
});

export const uploadSuccess = (
  payload: ICloudinaryUploadResponse,
  src: ActionSources
): CreatePhotoUploadActionTypes => ({
  type: UPLOAD_SUCCESS,
  payload: payload,
  src: src
});

export const uploadFail = (
  src: ActionSources
): CreatePhotoUploadActionTypes => ({
  type: UPLOAD_FAIL,
  payload: null,
  src: src
});

export const deleteUploadPhoto = (
  payload: number,
  src: ActionSources
): CreatePhotoUploadActionTypes => ({
  type: DELETE_UPLOAD_PHOTO,
  payload: payload,
  src: src
});

export const updatePhotoCaption = (
  payload: IPhotoCaptionPayload,
  src: ActionSources
): CreatePhotoUploadActionTypes => ({
  type: UPDATE_PHOTO_CAPTION,
  payload: payload,
  src: src
});

export const makeCoverPhoto = (
  payload: number,
  src: ActionSources
): CreatePhotoUploadActionTypes => ({
  type: MAKE_COVER_PHOTO,
  payload: payload,
  src: src
});
