import { createSelector, Selector } from 'reselect';
import { cachedUsersSelector } from '../user/user.selectors';
import { IAppState } from '../../types/appState.types';
import { IPostDetailState, ICachedPosts } from './postDetail.types';

const getPostDetail: Selector<IAppState, IPostDetailState> = (state) =>
  state.postDetail;
const getPostDetailLoading: Selector<IAppState, boolean> = (state) =>
  state.postDetail.isPageLoading;
const getPostDetailError: Selector<IAppState, boolean> = (state) =>
  state.postDetail.isPageError;
const getCachedPosts: Selector<IAppState, ICachedPosts> = (state) =>
  state.postDetail.cachedPosts;
const getCurrentPostID: Selector<IAppState, string | null> = (state) =>
  state.postDetail.currentPostID;
const getPostLoading: Selector<IAppState, boolean> = (state) =>
  state.postDetail.isPostLoading;

export const postDetailStateSelector = createSelector(
  getPostDetail,
  (stateValue) => stateValue
);

export const cachedPostsStateSelector = createSelector(
  getCachedPosts,
  (stateValue) => stateValue
);

export const currentPostIDSelector = createSelector(
  getCurrentPostID,
  (stateValue) => stateValue
);

export const currentPostSelector = createSelector(
  [getCurrentPostID, getCachedPosts],
  (postID, cachedPosts) =>
    postID && cachedPosts?.[postID] ? cachedPosts[postID] : null
);

export const currentPostPhotosSelector = createSelector(
  currentPostSelector,
  (currentPost) => (currentPost ? currentPost.uploadMedia : null)
);

export const currentPostCountrySelector = createSelector(
  [cachedPostsStateSelector, getCurrentPostID],
  (posts, currentPostID) => {
    return currentPostID && posts?.[currentPostID]
      ? {
          longName: posts[currentPostID].location.country.longName,
          code: posts[currentPostID].location.country.code,
        }
      : null;
  }
);

export const currentAuthorIDSelector = createSelector(
  [currentPostIDSelector, cachedPostsStateSelector],
  (currentPostID, cachedPosts) =>
    currentPostID && cachedPosts?.[currentPostID]
      ? cachedPosts[currentPostID].admin.authorID
      : null
);

export const currentAuthorSelector = createSelector(
  [currentAuthorIDSelector, cachedUsersSelector],
  (authorID, cachedUsers) => {
    return authorID && cachedUsers?.[authorID]
      ? { ...cachedUsers[authorID], id: authorID }
      : null;
  }
);

export const postDetailPageLoadingSelector = createSelector(
  getPostDetailLoading,
  (stateValue) => stateValue
);

export const postDetailPageErrorSelector = createSelector(
  getPostDetailError,
  (stateValue) => stateValue
);

export const postLoadingSelector = createSelector(
  getPostLoading,
  (stateValue) => stateValue
);
