import React, { useState, Ref } from 'react';
import * as el from './TextInput.styled';
import { generateUniqueId } from '../../../services/base.service';
import {
  Label,
  ValidationMessage,
  InfoPill,
} from '../../../styled-components/shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export interface ITextInputProps {
  children?: React.ReactNode;
  margin?: string;
  inputID?: string | number;
  label: string;
  textarea?: boolean | number;
  placeholder?: string;
  inputValue: string;
  inputType?: string;
  optional?: boolean;
  autoComplete?: string | boolean;
  validationErrors?: Array<string>;
  fieldName: string;
  forwardedRef?: Ref<any>;
  focusHandler?: () => void;
  blurHandler?: () => void;
  changeHandler: (
    value: any, // IFormFieldPayload | string throwing type errors
    id?: string | number | null
  ) => void;
}

const TextInput: React.FC<ITextInputProps> = ({
  margin,
  inputID = generateUniqueId(),
  label,
  textarea,
  placeholder,
  inputValue,
  inputType,
  optional,
  autoComplete,
  fieldName,
  validationErrors = [],
  forwardedRef,
  focusHandler,
  blurHandler,
  changeHandler,
}) => {
  const [isInputSelected, setInputSelected] = useState(false);
  const [isInputFocused, setInputFocused] = useState(false);
  const [isPristine, setPristine] = useState(true);

  const toggleSelection = () => {
    setInputSelected(!isInputSelected);
  };

  const handleFocus = () => {
    setInputSelected(true);
    setInputFocused(true);
    !isInputFocused && focusHandler && focusHandler();
  };

  const handleBlur = () => {
    setInputSelected(false);
    setInputFocused(false);
    setPristine(false);
    blurHandler && blurHandler();
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string | number | null = null
  ) => {
    changeHandler({ [fieldName]: e.target.value }, id);
  };

  const showValidations = Boolean(validationErrors?.length && !isPristine);

  return (
    <el.Container margin={margin}>
      <Label htmlFor={inputID}>
        {label}
        {optional ? <InfoPill>Optional</InfoPill> : null}
      </Label>
      <el.InputContainer>
        <el.SelectedHighlight
          isSelected={isInputSelected}
          isNotValid={showValidations}
        >
          {showValidations ? (
            <FontAwesomeIcon icon={faExclamationTriangle} />
          ) : null}
        </el.SelectedHighlight>

        {textarea ? (
          <el.Textarea
            rows={typeof textarea !== 'boolean' ? textarea : 3}
            placeholder={placeholder}
            id={inputID}
            value={inputValue}
            onMouseEnter={toggleSelection}
            onMouseLeave={toggleSelection}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e, inputID)
            }
          />
        ) : (
          <el.Input
            type={inputType ? inputType : 'text'}
            ref={forwardedRef ? forwardedRef : null}
            placeholder={placeholder}
            id={inputID}
            value={inputValue}
            onMouseEnter={toggleSelection}
            onMouseLeave={toggleSelection}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e, inputID)
            }
            autoComplete={autoComplete ? autoComplete : 'on'}
          />
        )}
      </el.InputContainer>
      {showValidations && validationErrors ? (
        <el.ValidationContainer>
          {validationErrors.map((msg) => (
            <ValidationMessage key={generateUniqueId()}>
              {msg}
            </ValidationMessage>
          ))}
        </el.ValidationContainer>
      ) : null}
    </el.Container>
  );
};

export const TextInputWithRef = React.forwardRef(
  (props: ITextInputProps, ref: Ref<any>) => {
    return <TextInput {...props} forwardedRef={ref} />;
  }
);

export default TextInput;
