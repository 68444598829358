import { createSelector, Selector } from 'reselect';
import * as validators from '../../services/validation.service';
import { IAppState } from '../../types/appState.types';

// State Getters
const getEmail: Selector<IAppState, string> = state => state.joinForm.email;
const getEmailVerify: Selector<IAppState, string> = state =>
  state.joinForm.emailVerify;
const getPwd: Selector<IAppState, string> = state => state.joinForm.pwd;
const getPwdVerify: Selector<IAppState, string> = state =>
  state.joinForm.pwdVerify;

const getJoinSuccess: Selector<IAppState, boolean | null> = state =>
  state.joinForm.isJoinSuccess;
const getJoinStep: Selector<IAppState, number> = state =>
  state.joinForm.currentStep;
const getNewAccountID: Selector<IAppState, string | null> = state =>
  state.joinForm.newAccountID;

const getFormError: Selector<IAppState, string | null> = state =>
  state.joinForm.formError;

const getIsJoinLoading: Selector<IAppState, boolean> = state =>
  state.joinForm.isLoading;

// Selectors

export const isJoinLoadingSelector = createSelector(
  getIsJoinLoading,
  stateValue => stateValue
);

export const joinFormSuccessSelector = createSelector(
  getJoinSuccess,
  stateValue => stateValue
);

export const joinFomErrorSelector = createSelector(
  getFormError,
  stateValue => stateValue
);

export const newAccountIDSelector = createSelector(
  getNewAccountID,
  stateValue => stateValue
);

export const joinStepSelector = createSelector(
  getJoinStep,
  stateValue => stateValue
);

export const emailSelector = createSelector(getEmail, stateValue => stateValue);

export const emailVerifySelector = createSelector(
  getEmailVerify,
  stateValue => stateValue
);

export const pwdSelector = createSelector(getPwd, stateValue => stateValue);

export const pwdVerifySelector = createSelector(
  getPwdVerify,
  stateValue => stateValue
);

// Validations
export const joinFormConfig = {
  email: [
    validators.isRequired(),
    validators.isEmail(),
    validators.maxChar(75)
  ],
  emailVerify: [
    validators.isRequired(),
    validators.maxChar(75)
    // Note: matching Validator must have access to a value to match against, composed in Selector
  ],
  pwd: [
    validators.isRequired(),
    validators.isStrongPassword(),
    validators.maxChar(75)
  ],
  pwdVerify: [
    validators.isRequired(),
    validators.maxChar(75)
    // Note: matching Validator must have access to a value to match against, composed in Selector
  ]
};

const emailValidations = [...joinFormConfig.email];
const emailVerifyValidations = [...joinFormConfig.emailVerify];
const pwdValidations = [...joinFormConfig.pwd];
const pwdVerifyValidations = [...joinFormConfig.pwdVerify];

export const emailValidationsSelector = createSelector(getEmail, stateValue =>
  validators.validate(stateValue, emailValidations)
);

export const emailVerifyValidationsSelector = createSelector(
  [getEmailVerify, getEmail],
  (stateValue, matchValue) => {
    const validationsWithMatch = [
      ...emailVerifyValidations,
      validators.mustMatch('previous email address', matchValue)
    ];
    return validators.validate(stateValue, validationsWithMatch);
  }
);

export const pwdValidationsSelector = createSelector(getPwd, stateValue =>
  validators.validate(stateValue, pwdValidations)
);

export const pwdVerifyValidationsSelector = createSelector(
  [getPwdVerify, getPwd],
  (stateValue, matchValue) => {
    const validationsWithMatch = [
      ...pwdVerifyValidations,
      validators.mustMatch('previous password', matchValue)
    ];
    return validators.validate(stateValue, validationsWithMatch);
  }
);

// Derived state
export const stepOneValidSelector = createSelector(
  [emailValidationsSelector, emailVerifyValidationsSelector],
  (email, emailVerify) => [...email, ...emailVerify].length === 0
);

export const stepTwoValidSelector = createSelector(
  [pwdValidationsSelector, pwdVerifyValidationsSelector],
  (pwd, pwdVerify) => [...pwd, ...pwdVerify].length === 0
);
