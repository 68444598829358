import * as UPDATEPOST_ACTIONS from '../updatePost/updatePost.actions';
import { RESET_FORM } from '../createPost/createFormInfo/createFormInfo.actions';
import { IUpdatePostState, UpdatePostActionTypes } from './updatePost.types';

// NOTE: createPost.reducer is where all data from the post that is being updated will be dumped into, the inputs are bound to these values

const initialState: IUpdatePostState = {
  updatePostID: null,
  isLoading: false,
};

export default function UpdatePostReducer(
  state = initialState,
  action: UpdatePostActionTypes | any // TODO: CreatePost actions
): IUpdatePostState {
  switch (action.type) {
    case UPDATEPOST_ACTIONS.SET_UPDATE_MODE:
      return {
        ...initialState,
        isLoading: true,
        updatePostID: action.payload,
      };
    case UPDATEPOST_ACTIONS.SET_UPDATE_DATA:
      return {
        ...state,
        isLoading: false,
      };
    case RESET_FORM:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
