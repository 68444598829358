import 'firebase/auth';
import firebase from 'firebase/app';
import firebaseService from './firebase.service';
import { User as FirestoreUser } from '@firebase/auth-types';
import { ILoggedInUserData } from '../types/user.types';
import { Observable } from 'rxjs';

class FirebaseAuthService {
  phovoFirebase: firebase.app.App | null;
  phovoAuth: firebase.auth.Auth;
  authState: Observable<firebase.User | null>;
  constructor() {
    this.phovoFirebase = firebaseService.init();
    this.phovoAuth = this.phovoFirebase.auth();
    this.authState = new Observable((subscriber) => {
      this.phovoAuth.onAuthStateChanged((user: any) => {
        subscriber.next(user);
      });
    });
  }

  checkAuthState = (
    authorizedHandler: (uid: string) => void,
    unauthorizedHandler: () => void
  ): void => {
    this.phovoAuth.onAuthStateChanged((user: any) => {
      user ? authorizedHandler(user.uid) : unauthorizedHandler();
    });
  };

  getCurrentUser = (): firebase.User | null => {
    return firebase.auth().currentUser;
  };

  createAccount = async (
    email: string,
    password: string
  ): Promise<ILoggedInUserData | null> => {
    const newAccount = await this.phovoAuth.createUserWithEmailAndPassword(
      email,
      password
    );

    return newAccount?.user ? this.buildUserInfo(newAccount.user) : null;
  };

  login = async (
    email: string,
    password: string
  ): Promise<ILoggedInUserData | null> => {
    try {
      const req = await this.phovoAuth.signInWithEmailAndPassword(
        email,
        password
      );
      return req.user ? this.buildUserInfo(req.user) : null;
    } catch (err) {
      throw err.message;
    }
  };

  logout = async (): Promise<boolean> => {
    const req = await this.phovoAuth.signOut();
    return Boolean(req);
  };

  getUserInfo = (): ILoggedInUserData | null =>
    this.phovoAuth.currentUser
      ? this.buildUserInfo(this.phovoAuth.currentUser)
      : null;

  buildUserInfo = (userData: FirestoreUser): ILoggedInUserData => {
    return {
      name: userData.displayName,
      email: userData.email,
      photoUrl: userData.photoURL,
      emailVerified: userData.emailVerified,
      uid: userData.uid,
    };
  };
}

const firebaseAuthService = new FirebaseAuthService();
export default firebaseAuthService;
