import * as LOGIN_ACTIONS from '../login/login.actions';
import { ILoginState, LoginActionTypes } from './login.types';

const initialState: ILoginState = {
  email: '',
  pwd: '',
  isSuccess: null,
  formError: ''
};

// NOTE: auth & user state should be referenced in ui.reducer
export default function LoginReducer(
  state = initialState,
  action: LoginActionTypes
) {
  switch (action.type) {
    case LOGIN_ACTIONS.UPDATE_LOGIN_FIELD: {
      return {
        ...state,
        ...action.payload
      };
    }
    case LOGIN_ACTIONS.AUTHENTICATE_USER_SUCCESS: {
      return {
        ...state,
        isSuccess: true
      };
    }
    case LOGIN_ACTIONS.AUTHENTICATE_USER_FAIL: {
      return {
        ...state,
        isSuccess: false,
        formError: action.payload
      };
    }
    default:
      return state;
  }
}
