import * as JOIN_ACTIONS from './join.actions';
import { IJoinState, JoinActionTypes } from './join.types';

const initialState: IJoinState = {
  email: '',
  emailVerify: '',
  pwd: '',
  pwdVerify: '',
  currentStep: 1,
  isJoinSuccess: null,
  isLoading: false,
  newAccountID: null,
  formError: ''
};

export default function JoinReducer(
  state = initialState,
  action: JoinActionTypes
) {
  switch (action.type) {
    case JOIN_ACTIONS.UPDATE_JOIN_FIELD: {
      return {
        ...state,
        ...action.payload
      };
    }
    case JOIN_ACTIONS.COMPLETE_STEP: {
      return {
        ...state,
        currentStep: state.currentStep + 1
      };
    }
    case JOIN_ACTIONS.CREATE_USER: {
      return {
        ...state,
        isLoading: true
      };
    }
    case JOIN_ACTIONS.CREATE_USER_FAIL: {
      return {
        ...state,
        isJoinSuccess: false,
        isLoading: false,
        formError: action.payload
      };
    }
    case JOIN_ACTIONS.CREATE_USER_SUCCESS: {
      return {
        ...state,
        isJoinSuccess: true,
        isLoading: false,
        newAccountID: action.payload
      };
    }

    case JOIN_ACTIONS.RESET_FORM: {
      return {
        ...initialState
      };
    }

    default:
      return state;
  }
}
