import styled, { css } from 'styled-components/macro';

interface IOverlayProps {
  isOpen?: boolean;
}

interface IModalProps {
  flexibleHeight?: boolean;
}

export const Overlay = styled.div<IOverlayProps>`
  background: rgba(0, 0, 0, 0.5);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  align-items: center;
  justify-content: center;
  ${(p) =>
    p.isOpen &&
    css`
      display: flex;
    `};
`;

export const Modal = styled.div<IModalProps>`
  min-height: ${(p) => (p.flexibleHeight ? '0' : '300px')};
  max-height: 500px;
  position: relative;
  width: 400px;
  padding: 25px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
`;

export const LogoContainer = styled.div`
  margin-top: -80px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Logo = styled.img`
  height: 125px;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
`;

export const ModalContent = styled.div``;
