import { SetPostListingPayload } from '../store/postListing/postListing.types';
import { getTagID } from './builder.service';
import firestoreService from './firestore.service';

export const getFeaturedPosts = () =>
  firestoreService.fbCollectionWhereQuery(
    'posts',
    'admin.featured',
    '==',
    true
  );

export const getRecentPosts = () =>
  firestoreService.fbCollectionQuery('posts', 'admin.dateCreated');

export const getPopularPosts = () =>
  firestoreService.fbCollectionQuery('posts', 'admin.likes');

export const getPostsByCountry = (country: string) =>
  firestoreService.fbCollectionWhereQuery(
    'posts',
    'location.country.longName',
    '==',
    country
  );

export const getPostsByTag = (tagID: number | null) =>
  tagID
    ? firestoreService.fbCollectionWhereQuery(
        'posts',
        'tags.tagIDs',
        'array-contains',
        tagID
      )
    : null;

export const getUserProfilePosts = (userID: string) =>
  firestoreService.fbCollectionWhereQuery(
    'posts',
    'admin.authorID',
    '==',
    userID,
    1000,
    'admin.dateCreated'
  );

export const isSupportedQueryMethod = (urlQueryBy: string): boolean => {
  return (
    (urlQueryBy && urlQueryBy === 'recent') ||
    urlQueryBy === 'popular' ||
    urlQueryBy === 'country' ||
    urlQueryBy === 'tag'
  );
};

export const doPostQuery = (payload: SetPostListingPayload): any => {
  switch (payload.queryBy) {
    case 'recent':
      return getRecentPosts();

    case 'featured':
      return getFeaturedPosts();

    case 'popular':
      return getPopularPosts();

    case 'country': {
      if (payload.filterBy) {
        return getPostsByCountry(payload.filterBy);
      } else {
        throw new Error('Country was not specified');
      }
    }
    case 'tag': {
      const tagID = payload.filterBy
        ? getTagID(payload.filterBy.replace('-', ' '))
        : undefined;
      if (tagID) {
        return getPostsByTag(tagID);
      } else {
        throw new Error('TagID was not specified');
      }
    }
    case 'user': {
      if (payload.filterBy) {
        return getUserProfilePosts(payload.filterBy);
      } else {
        throw new Error('UserID was not specified');
      }
    }
    default:
      throw new Error('No query found');
  }
};
