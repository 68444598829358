import { ActionSources, IFirestoreCollection } from '../../types/base.types';
import {
  PostListingActionTypes,
  SetPostListingPayload,
  QueryTypes,
} from './postListing.types';

export const UPDATE_QUERY_BY = 'UPDATE_QUERY_BY';
export const UPDATE_FILTER_BY = 'UPDATE_FILTER_BY';

export const SET_POST_LISTING = 'SET_POST_LISTING';

export const DISPLAY_CACHED_POSTS = 'DISPLAY_CACHED_POSTS';

export const GET_POST_LISTING = 'GET_POST_LISTING';
export const GET_POST_LISTING_SUCCESS = 'GET_POST_LISTING_SUCCESS';
export const GET_POST_LISTING_FAIL = 'GET_POST_LISTING_FAIL';

export const updateQueryBy = (
  payload: QueryTypes,
  src: ActionSources
): PostListingActionTypes => {
  return {
    type: UPDATE_QUERY_BY,
    payload: payload,
    src: src,
  };
};

export const updateFilterBy = (
  payload: QueryTypes,
  src: ActionSources
): PostListingActionTypes => {
  return {
    type: UPDATE_FILTER_BY,
    payload: payload,
    src: src,
  };
};
export const setPostListing = (
  payload: SetPostListingPayload,
  src: ActionSources
): PostListingActionTypes => {
  return {
    type: SET_POST_LISTING,
    payload: payload,
    src: src,
  };
};

export const getPostListing = (src: ActionSources): PostListingActionTypes => {
  return {
    type: GET_POST_LISTING,
    payload: null,
    src: src,
  };
};

export const getPostListingSuccess = (
  payload: IFirestoreCollection,
  src: ActionSources
): PostListingActionTypes => {
  return {
    type: GET_POST_LISTING_SUCCESS,
    payload: payload,
    src: src,
  };
};

export const getPostListingFail = (
  payload: string | null,
  src: ActionSources
): PostListingActionTypes => {
  return {
    type: GET_POST_LISTING_FAIL,
    payload: payload,
    src: src,
  };
};

export const displayCachedPosts = (
  src: ActionSources
): PostListingActionTypes => {
  return {
    type: DISPLAY_CACHED_POSTS,
    payload: null,
    src: src,
  };
};
