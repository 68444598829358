import styled, { css } from 'styled-components/macro';
import * as vars from '../../../utils/phovoVars';

interface IContainerProps {
  margin?: string;
}

interface ISelectedHighlightProps {
  isSelected?: boolean;
  isNotValid?: boolean;
}

interface IInputProps {
  type?: string;
  ref?: any;
  placeholder?: string;
  id?: any;
  value?: string;
  onMouseEnter?: any;
  onMouseLeave?: any;
  onFocus?: any;
  onBlur?: any;
  onChange?: any;
  autoComplete?: any;
}

interface ITextAreaProps extends IInputProps {
  rows?: number | boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  margin: ${(p) => (p.margin ? p.margin : '10px 0 0 0')};
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
`;

export const SelectedHighlight = styled.div<ISelectedHighlightProps>`
  width: 5px;
  background: ${vars.branding.grey1};
  transition: all 200ms ease-out;
  color: #fff;

  ${(p) =>
    p.isSelected &&
    css`
      background-color: ${vars.branding.primary};
      width: 10px;
    `};

  ${(p) =>
    p.isNotValid &&
    css`
      background-color: ${vars.branding.red};
      width: 15px;
      padding: 0 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    `};
`;

const commonInputStyles = `width: 100%;
font-family: ${vars.bodyFont};
border-top: 1px solid ${vars.branding.grey3};
border-right: 1px solid ${vars.branding.grey3};
border-bottom: 1px solid ${vars.branding.grey3};
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
border-left: none;
padding-left: 10px;
font-size: 1rem;
color: #333;
transition: border 200ms ease-out;

&:active,
&:focus {
  outline: none;
}

&::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: ${vars.branding.grey3};
}
&::-moz-placeholder {
  /* Firefox 19+ */
  color: ${vars.branding.grey3};
}

&:-ms-input-placeholder {
  /* IE 10+ */
  color: ${vars.branding.grey3};
}
&:-moz-placeholder {
  /* Firefox 18- */
  color: ${vars.branding.grey3};
}`;

export const Input = styled.input<IInputProps>`
  height: 45px;
  ${commonInputStyles};
`;

export const Textarea = styled.textarea<ITextAreaProps>`
  height: auto;
  ${commonInputStyles};
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 0.75rem;
`;

export const ValidationContainer = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
`;
