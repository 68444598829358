import { ActionSources } from '../../../types/base.types';
import { CreateTagsActionTypes } from './createTagging.types';

export const TOGGLE_TAG = 'TOGGLE_TAG';

export const toggleTag = (
  payload: number,
  src: ActionSources
): CreateTagsActionTypes => ({
  type: TOGGLE_TAG,
  payload: payload,
  src: src,
});
