import * as CREATE_FORM_ACTIONS from '../createFormInfo/createFormInfo.actions';
import * as UPDATEPOST_ACTIONS from '../../updatePost/updatePost.actions';
import * as CREATE_DESCRIPTION_ACTIONS from './createDescription.actions';

import { CreateDescriptionActionTypes } from './createDescription.types';
import { CreateFormActionTypes } from '../createFormInfo/createFormInfo.types';
import { UpdatePostActionTypes } from '../../updatePost/updatePost.types';
import { IPostDescription } from '../../../types/post.types';

const quillTextareaInitialState = {
  quillHTML: '',
  quillDelta: null,
  rawText: ''
};

const initialState: IPostDescription = {
  dateVisited: {
    month: '',
    year: ''
  },
  whyAwesome: { ...quillTextareaInitialState },
  quote: '',
  budget: ''
};

export default function CreateDescriptionReducer(
  state = initialState,
  action:
    | CreateDescriptionActionTypes
    | CreateFormActionTypes
    | UpdatePostActionTypes
): IPostDescription {
  switch (action.type) {
    // *** GENERIC ACTIONS
    case CREATE_FORM_ACTIONS.RESET_FORM:
      return {
        ...initialState
      };

    case UPDATEPOST_ACTIONS.SET_UPDATE_DATA: {
      const {
        dateVisited,
        whyAwesome,
        quote,
        budget
      } = action.payload.description;
      return {
        dateVisited,
        whyAwesome,
        quote,
        budget
      };
    }
    // ***

    case CREATE_DESCRIPTION_ACTIONS.UPDATE_CREATE_DESCRIPTION_FIELD: {
      return {
        ...state,
        ...action.payload
      };
    }

    case CREATE_DESCRIPTION_ACTIONS.UPDATE_DATE_VISITED_MONTH: {
      return {
        ...state,
        dateVisited: { ...state.dateVisited, ...action.payload }
      };
    }
    case CREATE_DESCRIPTION_ACTIONS.UPDATE_DATE_VISITED_YEAR: {
      return {
        ...state,
        dateVisited: { ...state.dateVisited, ...action.payload }
      };
    }
    default:
      return state;
  }
}
