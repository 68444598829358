import React from 'react';
import * as el from './GlobalHeader.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen } from '@fortawesome/pro-light-svg-icons';
import logo from '../../assets/images/logo-white.svg';
import HamburgerBTN from '../HamburgerBTN/HamburgerBTN.component';
import firebaseAuthService from '../../services/firebaseAuth.service';
import { ModalTypes } from '../../store/ui/ui.types';

interface IGlobalHeaderProps {
  isAuthorized: boolean;
  isMenuOpen: boolean;
  profileID: string | null;
  toggleMenu: () => void;
  toggleModal: (modalType: ModalTypes | null) => void;
  logoutHandler: () => void;
}

const GlobalHeader: React.FC<IGlobalHeaderProps> = ({
  isMenuOpen,
  toggleMenu,
  toggleModal,
  logoutHandler,
  isAuthorized,
  profileID,
}) => {
  const openModal = (modal: ModalTypes) => {
    toggleModal(modal);
  };

  const handleLogout = () => {
    //TODO: logout should happen through observable once action is fired
    const logout = firebaseAuthService.logout();
    logout ? logoutHandler() : console.error('There was a problem logging out');
  };
  return (
    <el.Header isMenuOpen={isMenuOpen}>
      <el.LogoContainer>
        <el.LogoLink to="/">
          <el.Logo src={logo} alt="phovo.co logo" />
        </el.LogoLink>
        <el.HamburgerContainer>
          <HamburgerBTN clickHandler={toggleMenu} isMenuOpen={isMenuOpen} />
        </el.HamburgerContainer>
      </el.LogoContainer>
      {isAuthorized ? (
        <el.GlobalNav>
          <el.GlobalNavLinkRR to="/explore">
            <span>Explore travel destinations</span>
          </el.GlobalNavLinkRR>
          <el.GlobalNavLinkRR to={`/traveler/${profileID}`}>
            <span>Your profile</span>
          </el.GlobalNavLinkRR>
          <el.GlobalNavLinkRR
            to="/create"
            title="Write about a new destination"
          >
            <span>Write</span>
          </el.GlobalNavLinkRR>
          <el.BtnGlobalNavLinkBtn onClick={handleLogout} title="Logout">
            <span>
              <FontAwesomeIcon icon={faDoorOpen} size="2x" />
            </span>
          </el.BtnGlobalNavLinkBtn>
        </el.GlobalNav>
      ) : (
        <el.GlobalNav>
          <el.GlobalNavLinkRR to="/">
            <span>About us</span>
          </el.GlobalNavLinkRR>
          <el.GlobalNavLinkRR to="/explore">
            <span>Explore travel destinations</span>
          </el.GlobalNavLinkRR>
          <el.BtnGlobalNavLinkBtn onClick={() => openModal(ModalTypes.JOIN)}>
            <span>Join us</span>
          </el.BtnGlobalNavLinkBtn>
          <el.BtnGlobalNavLinkBtn onClick={() => openModal(ModalTypes.LOGIN)}>
            <span>Login</span>
          </el.BtnGlobalNavLinkBtn>
        </el.GlobalNav>
      )}
    </el.Header>
  );
};

export default GlobalHeader;
