import firebase from 'firebase/app'
import 'firebase/analytics';
import 'firebase/performance';
import { FirebaseOptions } from '@firebase/app-types';

class FirebaseService {
  config: FirebaseOptions;
  phovoFirebase: firebase.app.App | null;
  constructor() {
    this.config =
      process.env.NODE_ENV === 'development'
        ? {
            apiKey: 'AIzaSyC-mfs5j65N0LUH64mmFAaWQMCd-xoLzJI',
            authDomain: 'phovo-dev.firebaseapp.com',
            databaseURL: 'https://phovo-dev.firebaseio.com',
            projectId: 'phovo-dev',
            storageBucket: 'phovo-dev.appspot.com',
            messagingSenderId: '1097732524919',
            appId: '1:1097732524919:web:ac5d4eafb96f6d1d3a2f72',
            measurementId: 'G-FYJ3PM41ZM',
          }
        : {
            apiKey: 'AIzaSyDikTbMiw9S7vB_RpTUmeEmExyl_ugqDFI',
            authDomain: 'phovo-react.firebaseapp.com',
            databaseURL: 'https://phovo-react.firebaseio.com',
            projectId: 'phovo-react',
            storageBucket: 'phovo-react.appspot.com',
            messagingSenderId: '997930734429',
            appId: '1:997930734429:web:717a242bd6e0b1e17a8eb4',
            measurementId: 'G-FYQM1QNSWV',
          };

    this.phovoFirebase = null;
  }

  init() {
    if (!this.phovoFirebase) {
      this.phovoFirebase = firebase.initializeApp(this.config);
      firebase.performance();
      firebase.analytics();
    }

    return this.phovoFirebase;
  }
}

const firebaseService = new FirebaseService();

export default firebaseService;
