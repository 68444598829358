import { createSelector, Selector } from 'reselect';
import * as R from 'ramda';
import { currentPostCountrySelector } from '../postDetail/postDetail.selectors';

import { IAppState } from '../../types/appState.types';
import { IAggregate } from '../../types/base.types';

const getGeneral: Selector<IAppState, IAggregate> = (state) =>
  state.aggregates.general;
const getCountries: Selector<IAppState, IAggregate> = (state) =>
  state.aggregates.countries;
const getTags: Selector<IAppState, IAggregate> = (state) =>
  state.aggregates.tags;

const getIsAggregatesLoading: Selector<IAppState, boolean | null> = (state) =>
  state.aggregates.isLoading;

export const introStateSelector = createSelector(
  [getGeneral, getCountries],
  (generalState, countryState) => {
    return {
      totalCountries: countryState.totalCountries,
      countryData: R.dissoc<IAggregate>('totalCountries', countryState),
      totalUsers: generalState.totalUsers,
      totalPosts: generalState.totalPosts,
    };
  }
);

export const countryStateSelector = createSelector(
  getCountries,
  (stateValue) => stateValue
);

export const tagStateSelector = createSelector(getTags, (stateValue) =>
  R.filter((n) => n !== 0, stateValue)
);

export const totalPostsFromCurrentCountrySelector = createSelector(
  [currentPostCountrySelector, countryStateSelector],
  (currentCountry, allCountries) => {
    return currentCountry?.code && currentCountry
      ? allCountries[currentCountry.code]
      : null;
  }
);

export const isAggregatesLoadingSelector = createSelector(
  getIsAggregatesLoading,
  (stateValue) => stateValue
);
