import React from 'react';
import * as el from './LoadingSpinner.styled';
import { P } from '../../styled-components/typography';

interface ILoadingSpinnerProps {
  margin?: string;
  message?: string;
}

const LoadingSpinner: React.FC<ILoadingSpinnerProps> = ({
  margin,
  message,
}) => {
  return (
    <el.LoadingContainer margin={margin}>
      <el.FlowerSpinner>
        <el.DotsContainer>
          <el.BiggerDot>
            <el.SmallerDot />
          </el.BiggerDot>
        </el.DotsContainer>
      </el.FlowerSpinner>
      {message ? <P>{message}</P> : null}
    </el.LoadingContainer>
  );
};

export default LoadingSpinner;
