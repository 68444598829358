import React from 'react';
import './HamburgerBTN.css';

interface IHamburgerBTNProps {
  clickHandler: () => void;
  isMenuOpen: boolean;
}

const HamburgerBTN: React.FC<IHamburgerBTNProps> = ({
  isMenuOpen,
  clickHandler,
}) => {
  return (
    <button
      onClick={() => clickHandler()}
      className={
        isMenuOpen
          ? 'hamburger hamburger--spin is-active'
          : 'hamburger hamburger--spin'
      }
      type="button"
      aria-label="Menu"
      aria-controls="navigation"
    >
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </button>
  );
};

export default HamburgerBTN;
