import firestoreService from './firestore.service';
import { postTags, travelMethods } from './constants';
import {
  IPost,
  IPostListing,
  IGooglemapsPlace,
  PhotoOrientation,
  IExtraQuestion,
  ICloudinaryUploadResponse,
  BuildPostOptions,
} from '../types/post.types';

import { IUserPostTotals } from '../types/user.types';
import { IFirestoreDocument, IAggregate } from '../types/base.types';
import { IAggregatesSuccessPayload } from '../store/aggregates/aggregates.types';

export const buildPostListing = (
  postIDs: Array<string>,
  posts: { [id: string]: IPost }
): Array<IPostListing> => {
  // if a post gets deleted, it will map to undefined => remove any undefined from listing array
  const cachedPostsObjFilteredToListingArray = postIDs
    .map((id: string) => posts[id])
    .filter((post: IPost) => Boolean(post));

  return cachedPostsObjFilteredToListingArray.map((post: IPost) => {
    return {
      authorID: post.admin.authorID,
      featured: post.admin.featured,
      postID: post.admin.postID,
      likes: post.admin.likes,
      city: post.location.city,
      country: { ...post.location.country },
      altName: post.location.alternateName,
      tags: getTagNames(post.tags.tagIDs),
      photo: {
        ...post.uploadMedia.photos[post.uploadMedia.coverPhotoIndex || 0],
      },
    };
  });
};

export const buildGooglePlaceInfo = (placeData: any): IGooglemapsPlace => {
  const { place_id, geometry, address_components, selectedPlace } = placeData;
  return {
    googlePlaceID: place_id,
    coordinates: {
      longitude: geometry.location.lng(),
      latitude: geometry.location.lat(),
    },
    country: address_components
      .filter((item: any) => (item.types.includes('country') ? item : ''))
      .reduce((acc: IGooglemapsPlace, info: any) => {
        return {
          longName: info.long_name,
          code: info.short_name,
        };
      }, {}),
    subRegion: address_components
      .filter((item: any) =>
        item.types.includes('administrative_area_level_1') ? item : ''
      )
      .reduce((acc: IGooglemapsPlace, info: any) => {
        return {
          longName: info.long_name,
          code: info.short_name,
        };
      }, {}),
    city: address_components
      .filter((item: any) => (item.types.includes('locality') ? item : ''))
      .reduce((acc: string, info: any) => info.long_name, ''),
    googlePlaceValue: selectedPlace,
  };
};

export const buildSelectValueLabel = (
  values: Array<string>,
  labels: Array<string>
) => {
  return values.map((item, i) => ({ value: item, label: labels[i] }));
};

export const buildTravelMethodsValueLabels = () => {
  const values = Object.keys(travelMethods);
  const labels = Object.values(travelMethods).map((method) => method.noun);
  return buildSelectValueLabel(values, labels);
};

const getPhotoOrientation = (
  width: number,
  height: number
): PhotoOrientation => {
  return width / height >= 2.33
    ? 'PANORAMIC'
    : width >= height
    ? 'LANDSCAPE'
    : 'PORTRAIT';
};

export const buildCloudinaryData = (
  response: any
): ICloudinaryUploadResponse => ({
  orientation: getPhotoOrientation(response.info.width, response.info.height),
  id: response.info.public_id,
});

export const getTagNames = (tagIDs: Array<number>): Array<string> =>
  tagIDs.map((userTag) => {
    const index = postTags.findIndex((tag) => tag.value === userTag);
    return postTags[index].label;
  });

export const getTagID = (tagName: string): number | null => {
  const tag = postTags.find((tag) => tag.label === tagName);
  return tag?.value ?? null;
};

export const getTagIDFromSlug = (slugString: string): number | null => {
  const removeSlugs = slugString.replace(/-/g, ' ');
  return getTagID(removeSlugs);
};

export const buildPost = (
  selectorData: IPost,
  operation: BuildPostOptions = 'new'
): IPost => {
  const timeStamp = firestoreService.getTimeStamp();
  const isNew = operation;

  return {
    admin: {
      ...selectorData.admin,
      featured: false,
      likes: 0,
      reports: 0,
      dateCreated: isNew ? timeStamp : selectorData.admin.dateCreated,
      dateLastModified: timeStamp, // TODO: Verify update updates this, also review proper firebase timestamp method
    },
    location: {
      ...selectorData.location,
    },
    description: {
      ...selectorData.description,
    },
    tags: { ...selectorData.tags },
    itinerary: { ...selectorData.itinerary },
    uploadMedia: {
      coverPhotoIndex: selectorData.uploadMedia.coverPhotoIndex,
      photos: selectorData.uploadMedia.photos.filter(
        (photo: ICloudinaryUploadResponse) => photo.id !== ''
      ),
    },
    extraQuestions: {
      questions: selectorData.extraQuestions.questions.filter(
        (question: IExtraQuestion) => {
          const hasChars = question.writerAnswer.rawText.match(/[a-zA-Z]+/g);
          return hasChars && hasChars.length ? question : false;
        }
      ),
    },
  };
};

export const capitalizeFirstLetter = (inputValue: string): string => {
  const letters = inputValue.split('');
  letters[0] = letters[0].toUpperCase();
  return letters.join('');
};

export const buildProfileCountryInfo = (data: IAggregate): IUserPostTotals => {
  const totalCountries = Object.keys(data).length;
  const totalPosts = Object.values(data).reduce(
    (total: number, post: number) => total + post,
    0
  );

  return { totalCountries: totalCountries, totalPosts: totalPosts };
};

export const buildAggregates = (
  data: Array<IFirestoreDocument<any>>
): IAggregatesSuccessPayload => {
  return data.reduce((acc, item) => ({ ...acc, [item.id]: { ...item.data } }), {
    cities: {},
    countries: {},
    general: {},
    tags: {},
  });
};
