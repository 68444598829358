import { mergeMap, catchError, filter, map } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as AGG_ACTIONS from './aggregates.actions';
import { Epic, ofType } from 'redux-observable';
import {
  ActionSources,
  IAggregate,
  IFirestoreDocument,
} from '../../types/base.types';

import firestoreService from '../../services/firestore.service';
import { buildAggregates } from '../../services/builder.service';

export const GetAggregatesAttemptEpic: Epic<any> = (action$) =>
  action$.pipe(
    ofType(AGG_ACTIONS.GET_AGGREGATES),
    mergeMap(() =>
      from(firestoreService.fbCollectionQuery('aggregates')).pipe(
        filter((data: any) => Boolean(data)),
        map((data: Array<IFirestoreDocument<IAggregate>>) => ({
          type: AGG_ACTIONS.GET_AGGREGATES_SUCCESS,
          payload: buildAggregates(data),
          src: ActionSources.AGGREGATES_EPIC,
        })),
        catchError((err: string) => {
          return of({
            type: AGG_ACTIONS.GET_AGGREGATES_FAIL,
            payload: err,
            src: ActionSources.AGGREGATES_EPIC,
          });
        })
      )
    )
  );
