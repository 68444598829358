import { ActionSources, IFormFieldPayload } from '../../types/base.types';
import {
  UpdateProfileActionTypes,
  IUpdateProfileFormPayload,
} from './updateProfile.types';
import { ICloudinaryUploadResponse } from '../../types/post.types';
import { IUpdateProfilePayload } from '../../types/user.types';

export const INIT_UPDATE_FORM = 'INIT_UPDATE_FORM';
export const UPDATE_PROFILE_FIELD = 'UPDATE_PROFILE_FIELD';
export const UPLOAD_CLOUDINARY_PHOTO_SUCCESS =
  'UPLOAD_CLOUDINARY_PHOTO_SUCCESS';
export const UPLOAD_CLOUDINARY_PHOTO_FAIL = 'UPLOAD_CLOUDINARY_PHOTO_FAIL';

export const SUBMIT_PROFILE_UPDATE = 'SUBMIT_PROFILE_UPDATE';
export const SUBMIT_PROFILE_UPDATE_SUCCESS = 'SUBMIT_PROFILE_UPDATE_SUCCESS';
export const SUBMIT_PROFILE_UPDATE_FAIL = 'SUBMIT_PROFILE_UPDATE_FAIL';

export const RESET_UPDATE_FORM = 'RESET_UPDATE_FORM';

export const initUpdateForm = (
  payload: IUpdateProfileFormPayload,
  src: ActionSources
): UpdateProfileActionTypes => {
  return {
    type: INIT_UPDATE_FORM,
    payload: payload,
    src: src,
  };
};

export const updateProfileField = (
  payload: IFormFieldPayload,
  src: ActionSources
): UpdateProfileActionTypes => {
  return {
    type: UPDATE_PROFILE_FIELD,
    payload: payload,
    src: src,
  };
};

export const uploadPhotoSuccess = (
  payload: ICloudinaryUploadResponse,
  src: ActionSources
): UpdateProfileActionTypes => {
  return {
    type: UPLOAD_CLOUDINARY_PHOTO_SUCCESS,
    payload: payload,
    src: src,
  };
};

export const uploadPhotoFail = (
  src: ActionSources
): UpdateProfileActionTypes => {
  return {
    type: UPLOAD_CLOUDINARY_PHOTO_FAIL,
    payload: null,
    src: src,
  };
};

export const submitProfileUpdate = (
  src: ActionSources
): UpdateProfileActionTypes => {
  return {
    type: SUBMIT_PROFILE_UPDATE,
    payload: null,
    src: src,
  };
};

export const submitProfileUpdateSuccess = (
  payload: IUpdateProfilePayload,
  src: ActionSources
): UpdateProfileActionTypes => {
  return {
    type: SUBMIT_PROFILE_UPDATE_SUCCESS,
    payload: payload,
    src: src,
  };
};

export const submitProfileUpdateFail = (
  src: ActionSources
): UpdateProfileActionTypes => {
  return {
    type: SUBMIT_PROFILE_UPDATE_FAIL,
    payload: null,
    src: src,
  };
};

export const resetUpdateProfileForm = (
  src: ActionSources
): UpdateProfileActionTypes => {
  return {
    type: RESET_UPDATE_FORM,
    payload: null,
    src: src,
  };
};
