import {
  mergeMap,
  catchError,
  filter,
  map,
  mapTo,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as POST_LISTING_ACTIONS from './postListing.actions';

import { combineEpics, Epic, ofType } from 'redux-observable';
import { ActionSources } from '../../types/base.types';

import { doPostQuery } from '../../services/firestoreQueries.service';
import { currentListingIDsSelector } from './postListing.selectors';

// INFO: SET_POST_LISTING => sets queryBy and filterBy. Initiate GET_POST_LISTING, based on these params in state
const SetPostListingEpic: Epic<any> = (action$) =>
  action$.pipe(
    ofType(POST_LISTING_ACTIONS.SET_POST_LISTING),
    mapTo({
      type: POST_LISTING_ACTIONS.GET_POST_LISTING,
      src: ActionSources.POST_LISTING_EPIC,
    })
  );

//INFO:  If posts are already loaded dispatch DISPLAY_CACHED_POSTS, if not, get posts from DB through dispatching GET_POST_LISTING
const GetPostListingAttemptEpic: Epic<any> = (action$, state$) =>
  action$.pipe(
    ofType(POST_LISTING_ACTIONS.GET_POST_LISTING),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      of(currentListingIDsSelector(state)).pipe(
        // DO POST EXIST IN CACHE ?
        mergeMap((cachedPosts) => {
          if (!cachedPosts) {
            // GET POSTS
            return from(
              doPostQuery({
                queryBy: state.postListing.queryBy,
                filterBy: state.postListing.filterBy,
              })
            ).pipe(
              filter((data: any) => Boolean(data)),
              map((data: any) => ({
                type: POST_LISTING_ACTIONS.GET_POST_LISTING_SUCCESS,
                payload: data,
                src: ActionSources.POST_LISTING_EPIC,
              }))
            );
          } else {
            // DISPLAY CACHED POSTS
            return of({
              type: POST_LISTING_ACTIONS.DISPLAY_CACHED_POSTS,
              src: ActionSources.POST_LISTING_EPIC,
            });
          }
        })
      )
    ),
    catchError((err: string) =>
      of({
        type: POST_LISTING_ACTIONS.GET_POST_LISTING_FAIL,
        payload: err,
        src: ActionSources.POST_LISTING_EPIC,
      })
    )
  );

export const PostListingEpic = combineEpics(
  SetPostListingEpic,
  GetPostListingAttemptEpic
);
