import styled from 'styled-components/macro';
import * as vars from '../../utils/phovoVars';
import footerBg from '../../assets/images/footer-bg.jpg';

export const Footer = styled.footer`
  height: 275px;
  width: 100%;
  border-top: 3px solid ${vars.branding.primary};
  background: url(${footerBg});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const Logo = styled.img`
  width: 200px;
`;

export const SocialContainer = styled.div`
  width: 100%;
  max-width: 150px;
  display: flex;
  justify-content: space-evenly;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: space-evenly;
  flex-direction: column;
  max-width: 600px;

  a {
    font-weight: bold;
    margin-bottom: 10px;
  }

  @media (min-width: ${vars.breakpoints.sm}) {
    flex-direction: row;

    a {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${vars.breakpoints.lg}) {
    width: 80%;
  }
`;
