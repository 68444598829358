import * as CREATE_LOCATION_ACTIONS from './createLocation.actions';
import * as CREATE_FORM_ACTIONS from '../createFormInfo/createFormInfo.actions';
import * as UPDATEPOST_ACTIONS from '../../updatePost/updatePost.actions';

import { clearGooglePlace } from '../../../services/base.service';

import { CreateLocationActionTypes } from './createLocation.types';
import { CreateFormActionTypes } from '../createFormInfo/createFormInfo.types';
import { UpdatePostActionTypes } from '../../updatePost/updatePost.types';
import { IPostLocation } from '../../../types/post.types';

/*** State builders ***/

const initialState: IPostLocation = {
  country: { longName: null, code: null },
  subRegion: { longName: null, code: null },
  city: '',
  googlePlaceID: '',
  coordinates: { longitude: 0, latitude: 0 },
  googlePlaceValue: '',
  alternateName: '',
};

export default function CreateLocationReducer(
  state = initialState,
  action:
    | CreateLocationActionTypes
    | CreateFormActionTypes
    | UpdatePostActionTypes
): IPostLocation {
  switch (action.type) {
    // *** GENERIC ACTIONS
    case CREATE_FORM_ACTIONS.RESET_FORM:
      return {
        ...initialState,
      };

    case UPDATEPOST_ACTIONS.SET_UPDATE_DATA: {
      const {
        country,
        subRegion,
        city,
        googlePlaceID,
        coordinates,
        googlePlaceValue,
        alternateName,
      } = action.payload.location;
      return {
        country,
        subRegion,
        city,
        googlePlaceID,
        coordinates,
        googlePlaceValue,
        alternateName,
      };
    }
    // ***

    case CREATE_LOCATION_ACTIONS.UPDATE_CREATE_LOCATION_FIELD: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case CREATE_LOCATION_ACTIONS.SET_AUTOCOMPLETE_STORY_LOCATION: {
      // REVIEW: Post location also must be LAST itinerary[] stop...possibly another action to trigger this later on
      // NOTE: with middle ware, any place change should trigger a secondary action to UPDATE_ITINERARY_FINAL_DESTINATION
      return {
        ...state,
        ...action.payload,
        googlePlaceValue: action.payload.city
          ? `${action.payload.city}, ${action.payload.country.longName}`
          : `${action.payload.country.longName}`,
      };
    }

    case CREATE_LOCATION_ACTIONS.CLEAR_AUTOCOMPLETE_STORY_LOCATION: {
      // REVIEW: Post location also must be LAST itinerary[] stop...possibly another action to trigger this later on
      // NOTE: with middle ware, any place change should trigger a secondary action to UPDATE_ITINERARY_FINAL_DESTINATION
      return {
        ...state,
        ...clearGooglePlace(),
      };
    }
    default:
      return state;
  }
}
