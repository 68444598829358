import { createSelector, Selector } from 'reselect';
import * as R from 'ramda';
import { cachedPostsStateSelector } from '../postDetail/postDetail.selectors';
import { buildPostListing } from '../../services/builder.service';
import { IAppState } from '../../types/appState.types';
import { IPostListingState, NestedListing } from './postListing.types';
import { IAggregate } from '../../types/base.types';
const getListingState: Selector<IAppState, IPostListingState> = (state) =>
  state.postListing;
const getQueryBy: Selector<IAppState, string | null> = (state) =>
  state.postListing.queryBy;
const getFilterBy: Selector<IAppState, string | null> = (state) =>
  state.postListing.filterBy;
const getIsLoading: Selector<IAppState, boolean> = (state) =>
  state.postListing.isLoading;
const getIsLoadingError: Selector<IAppState, boolean> = (state) =>
  state.postListing.isLoadingError;
const getCachedUserPosts: Selector<IAppState, NestedListing> = (state) =>
  state.postListing.user;

export const postsIsLoadingSelector = createSelector(
  getIsLoading,
  (stateValue) => stateValue
);

export const postsIsLoadingErrorSelector = createSelector(
  getIsLoadingError,
  (stateValue) => stateValue
);

export const queryBySelector = createSelector(
  getQueryBy,
  (stateValue) => stateValue
);

export const filterBySelector = createSelector(
  getFilterBy,
  (stateValue) => stateValue
);

export const listingStateSelector = createSelector(
  getListingState,
  (stateValue) => stateValue
);

export const currentListingIDsSelector = createSelector(
  [listingStateSelector, queryBySelector, filterBySelector],
  (listingState, queryBy, filterBy) => {
    // @ts-ignore
    if (listingState && queryBy && !R.isEmpty(listingState[queryBy])) {
      // @ts-ignore
      return Array.isArray(listingState[queryBy])
        ? // @ts-ignore
          // @ts-ignore
          listingState[queryBy]
        : // @ts-ignore
          // @ts-ignore
          listingState[queryBy][filterBy];
    } else {
      return null;
    }
  }
);

export const currentPostsSelector = createSelector(
  [currentListingIDsSelector, cachedPostsStateSelector],
  (displayPostIDs, cachedPosts) =>
    displayPostIDs && cachedPosts
      ? buildPostListing(displayPostIDs, cachedPosts)
      : null
);

export const currentPostsCountryCodes = createSelector(
  currentPostsSelector,
  (selectorData) =>
    selectorData
      ? selectorData.reduce((countryList: IAggregate, post) => {
          return post.country.code
            ? {
                ...countryList,
                [post.country.code]: (countryList[post.country.code] || 0) + 1,
              }
            : countryList;
        }, {})
      : {}
);

export const cachedUserPostsSelector = createSelector(
  getCachedUserPosts,
  (stateValue) => (!R.isEmpty(stateValue) ? stateValue : null)
);
