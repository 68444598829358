import * as CREATE_ITINERARY_ACTIONS from './createItinerary.actions';
import * as CREATE_LOCATION_ACTIONS from '../createLocation/createLocation.actions';
import * as CREATE_FORM_ACTIONS from '../createFormInfo/createFormInfo.actions';
import * as UPDATEPOST_ACTIONS from '../../updatePost/updatePost.actions';

import { generateUniqueId } from '../../../services/base.service';
import { clearGooglePlace } from '../../../services/base.service';
import { CreateItineraryActionTypes } from './createItinerary.types';
import {
  IPostItineraryStop,
  IPostItinerary,
  IGooglemapsPlace,
} from '../../../types/post.types';
import { CreateLocationActionTypes } from '../createLocation/createLocation.types';
import { CreateFormActionTypes } from '../createFormInfo/createFormInfo.types';
import { UpdatePostActionTypes } from '../../updatePost/updatePost.types';

/*** State builders ***/
const addToItinerary = (
  stateArr: Array<IPostItineraryStop>,
  payload: number
) => {
  const currentItinerary = stateArr;
  const operationAtIndex = payload;

  // add itinerary stop to specific index in stateArr
  currentItinerary.splice(operationAtIndex + 1, 0, newItineraryStop());

  return currentItinerary;
};

const newItineraryStop = (): IPostItineraryStop => ({
  id: generateUniqueId(),
  travelMethod: '',
  place: clearGooglePlace(),
  description: { ...quillTextareaInitialState },
});

const newItineraryStopWithFinalDestination = (
  stop: IPostItineraryStop,
  state: IPostItinerary
) => {
  const finalStop = stop;
  finalStop.place = state.itineraryStops[state.itineraryStops.length - 1].place;
  return finalStop;
};

const removePlaceFromStop = (stop: IPostItineraryStop) => {
  const stopWithPlace = stop;
  stopWithPlace.place = clearGooglePlace();
  return stopWithPlace;
};

const addNewStopToSingleStopItinerary = (state: Array<IPostItineraryStop>) => {
  const endDestination = { ...state[0], id: generateUniqueId() };
  return [removePlaceFromStop(state[0]), endDestination];
};

const updateEndDestination = (
  state: IPostItinerary,
  payload: IGooglemapsPlace
) => {
  if (state.itineraryStops.length) {
    const itinerary = state.itineraryStops;
    itinerary[itinerary.length - 1].place = { ...payload };
    return [...itinerary];
  } else {
    return [
      {
        ...newItineraryStop(),
        place: {
          ...payload,
          googlePlaceValue: payload.city
            ? `${payload.city}, ${payload.country.longName}`
            : `${payload.country.longName}`,
        },
      },
    ];
  }
};

const quillTextareaInitialState = {
  quillHTML: '',
  quillDelta: null,
  rawText: '',
};

const initialState: IPostItinerary = {
  showIntro: true,
  itineraryStops: [],
};

export default function CreateItineraryReducer(
  state = initialState,
  action:
    | CreateItineraryActionTypes
    | CreateLocationActionTypes
    | CreateFormActionTypes
    | UpdatePostActionTypes
): IPostItinerary {
  switch (action.type) {
    // *** GENERIC ACTIONS
    case CREATE_FORM_ACTIONS.RESET_FORM:
      return {
        ...initialState,
      };

    case UPDATEPOST_ACTIONS.SET_UPDATE_DATA:
      return {
        showIntro: false,
        itineraryStops: [...action.payload.itinerary.itineraryStops],
      };
    // ***

    // NOTE: with middle ware, any place change should trigger a secondary action to UPDATE_ITINERARY_FINAL_DESTINATION
    case CREATE_ITINERARY_ACTIONS.INITIATE_ITINERARY: {
      return {
        ...state,
        showIntro: false,
        itineraryStops:
          action.payload === 1
            ? [...state.itineraryStops]
            : [newItineraryStop(), ...state.itineraryStops],
      };
    }

    case CREATE_ITINERARY_ACTIONS.ADD_ITINERARY: {
      return {
        ...state,
        itineraryStops:
          state.itineraryStops.length === 1
            ? addNewStopToSingleStopItinerary(state.itineraryStops)
            : addToItinerary([...state.itineraryStops], action.payload),
      };
    }

    case CREATE_ITINERARY_ACTIONS.REMOVE_ITINERARY: {
      return {
        ...state,
        itineraryStops:
          state.itineraryStops.length === 2
            ? [
                newItineraryStopWithFinalDestination(
                  state.itineraryStops[0],
                  state
                ),
              ]
            : state.itineraryStops.filter(
                (stop, index) => index !== action.payload
              ),
      };
    }

    case CREATE_ITINERARY_ACTIONS.UPDATE_ITINERARY_FIELD: {
      return {
        ...state,
        itineraryStops: state.itineraryStops.map((stop: IPostItineraryStop) => {
          if (stop.id === action.payload.id) {
            return { ...stop, ...action.payload.value };
          }
          return stop;
        }),
      };
    }

    case CREATE_ITINERARY_ACTIONS.UPDATE_ITINERARY_GOOGLE_AUTOCOMPLETE: {
      return {
        ...state,
        itineraryStops: state.itineraryStops.map((stop: IPostItineraryStop) => {
          if (stop.id === action?.payload?.id) {
            stop.place = { ...stop.place, ...action.payload.value };
          }
          return stop;
        }),
      };
    }

    case CREATE_ITINERARY_ACTIONS.SET_ITINERARY_AUTOCOMPLETE_PLACE: {
      return {
        ...state,
        itineraryStops: state.itineraryStops.map((stop: IPostItineraryStop) => {
          if (stop.id === action.payload.id) {
            stop.place = { ...stop.place, ...action.payload.value };
          }
          return stop;
        }),
      };
    }

    case CREATE_ITINERARY_ACTIONS.CLEAR_ITINERARY_AUTOCOMPLETE_PLACE: {
      return {
        ...state,
        itineraryStops: state.itineraryStops.map((stop: IPostItineraryStop) => {
          if (stop.id === action.payload) {
            stop.place = clearGooglePlace();
          }
          return stop;
        }),
      };
    }

    case CREATE_LOCATION_ACTIONS.SET_AUTOCOMPLETE_STORY_LOCATION: {
      // REVIEW: Post location also must be LAST itinerary[] stop...possibly another action to trigger this later on
      // NOTE: with middle ware, any place change should trigger a secondary action to UPDATE_ITINERARY_FINAL_DESTINATION

      return {
        ...state,
        itineraryStops: updateEndDestination({ ...state }, action.payload),
      };
    }

    case CREATE_LOCATION_ACTIONS.CLEAR_AUTOCOMPLETE_STORY_LOCATION: {
      // REVIEW: Post location also must be LAST itinerary[] stop...possibly another action to trigger this later on
      // NOTE: with middle ware, any place change should trigger a secondary action to UPDATE_ITINERARY_FINAL_DESTINATION
      return {
        ...state,
        itineraryStops: state.itineraryStops.length
          ? updateEndDestination({ ...state }, clearGooglePlace())
          : [],
      };
    }

    default:
      return state;
  }
}
