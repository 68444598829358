import { createSelector, Selector } from 'reselect';
import { cachedPostsStateSelector } from '../postDetail/postDetail.selectors';
import { IAppState } from '../../types/appState.types';
import { completedCreateFormDataSelector } from '../createPost/createFormInfo/createFormInfo.selectors';

const getUpdatePostIDState: Selector<IAppState, string | null> = (state) =>
  state.updatePost.updatePostID;
const getUploadLoadingState: Selector<IAppState, boolean> = (state) =>
  state.updatePost.isLoading;

export const updatePostIDSelector = createSelector(
  getUpdatePostIDState,
  (stateValue) => stateValue
);

export const postToUpdateDataSelector = createSelector(
  [cachedPostsStateSelector, updatePostIDSelector],
  (cachedPosts, updateID) =>
    updateID && cachedPosts?.[updateID] ? cachedPosts[updateID] : null
);

export const updatePageLoadingSelector = createSelector(
  getUploadLoadingState,
  (stateValue) => stateValue
);

export const completedUpdateFormDataSelector = createSelector(
  [completedCreateFormDataSelector, updatePostIDSelector],
  (formData, updateID) => {
    return {
      ...formData,
      admin: {
        ...formData.admin,
        postID: updateID as string,
      },
    };
  }
);
