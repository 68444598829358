import { ActionSources } from '../../types/base.types';
import { IPost } from '../../types/post.types';
import { UpdatePostActionTypes } from './updatePost.types';

export const SET_UPDATE_MODE = 'SET_UPDATE_MODE';
export const SET_UPDATE_DATA = 'SET_UPDATE_DATA';

export const setUpdateMode = (
  payload: string,
  src: ActionSources
): UpdatePostActionTypes => {
  return {
    type: SET_UPDATE_MODE,
    payload: payload,
    src: src,
  };
};

export const setUpdateData = (
  payload: IPost,
  src: ActionSources
): UpdatePostActionTypes => {
  return {
    type: SET_UPDATE_DATA,
    payload: payload,
    src: src,
  };
};
