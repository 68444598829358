import * as CREATE_TAGS_ACTIONS from './createTagging.actions';
import * as CREATE_FORM_ACTIONS from '../createFormInfo/createFormInfo.actions';
import * as UPDATEPOST_ACTIONS from '../../updatePost/updatePost.actions';

import { CreateTagsActionTypes } from './createTagging.types';
import { CreateFormActionTypes } from '../createFormInfo/createFormInfo.types';
import { UpdatePostActionTypes } from '../../updatePost/updatePost.types';
import { IPostTags } from '../../../types/post.types';

const initialState: IPostTags = {
  tagIDs: [],
};

export default function CreateTaggingReducer(
  state = initialState,
  action: CreateTagsActionTypes | CreateFormActionTypes | UpdatePostActionTypes
): IPostTags {
  switch (action.type) {
    // *** GENERIC ACTIONS
    case CREATE_FORM_ACTIONS.RESET_FORM:
      return {
        ...initialState,
      };

    case UPDATEPOST_ACTIONS.SET_UPDATE_DATA:
      return {
        tagIDs: [...action.payload.tags.tagIDs],
      };
    // ***

    case CREATE_TAGS_ACTIONS.TOGGLE_TAG: {
      return {
        ...state,
        tagIDs: state.tagIDs.includes(action.payload)
          ? state.tagIDs.filter((tag) => tag !== action.payload)
          : [...state.tagIDs, action.payload],
      };
    }

    default:
      return state;
  }
}
