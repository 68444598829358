import {
  mergeMap,
  catchError,
  filter,
  map,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as USER_ACTIONS from './user.actions';
import { Epic, ofType } from 'redux-observable';
import { ActionSources, IFirestoreDocument } from '../../types/base.types';
import { IUserDocument } from '../../types/user.types';
import firestoreService from '../../services/firestore.service';
import { cachedUsersSelector } from './user.selectors';

export const GetUserDocumentAttemptEpic: Epic<any> = (action$, state$) =>
  action$.pipe(
    ofType(USER_ACTIONS.GET_USER_DOCUMENT),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      of(cachedUsersSelector(state)).pipe(
        mergeMap((cachedUsers) => {
          if (!cachedUsers || !cachedUsers[action.payload]) {
            return from(
              firestoreService.fbDocumentQuery('users', action.payload)
            ).pipe(
              filter((data: any) => Boolean(data)),
              map((data: IFirestoreDocument<IUserDocument>) => ({
                type: USER_ACTIONS.GET_USER_DOCUMENT_SUCCESS,
                payload: data,
                src: ActionSources.USER_EPIC,
              }))
            );
          } else {
            return of({
              type: USER_ACTIONS.DISPLAY_CACHED_USER,
              src: ActionSources.USER_EPIC,
            });
          }
        })
      )
    ),
    catchError((err: string) => {
      return of({
        type: USER_ACTIONS.GET_USER_DOCUMENT_FAIL,
        payload: err,
        src: ActionSources.USER_EPIC,
      });
    })
  );
