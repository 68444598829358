import * as POSTDETAIL_ACTIONS from './postDetail.actions';
import * as CREATE_FORM_INFO_ACTIONS from '../createPost/createFormInfo/createFormInfo.actions';
import {
  GET_POST_LISTING_SUCCESS,
  DISPLAY_CACHED_POSTS,
} from '../postListing/postListing.actions';

import * as R from 'ramda';
import { DELETE_POST_SUCCESS } from '../profile/profile.actions';
import {
  PostDetailActionTypes,
  IPostDetailState,
  ICachedPosts,
} from './postDetail.types';
import { PostListingActionTypes } from '../postListing/postListing.types';

import { IFirestoreDocument } from '../../types/base.types';

const initialState: IPostDetailState = {
  isPageLoading: false,
  isPageError: false,
  isPostLoading: false,
  currentPostID: null,
  cachedPosts: {},
};

export default function PostDetailReducer(
  state = initialState,
  action: PostDetailActionTypes | PostListingActionTypes | any // TODO: Profile actions, createpost Actions postListing actions
): IPostDetailState {
  switch (action.type) {
    case POSTDETAIL_ACTIONS.LOAD_POSTDETAIL_PAGE: {
      return {
        ...state,
        currentPostID: action.payload,
        isPageLoading: true,
      };
    }

    case POSTDETAIL_ACTIONS.LOAD_POSTDETAIL_PAGE_SUCCESS: {
      return {
        ...state,
        isPageLoading: false,
      };
    }

    case POSTDETAIL_ACTIONS.LOAD_POSTDETAIL_PAGE_FAIL: {
      return {
        ...state,
        isPageError: true,
      };
    }

    case POSTDETAIL_ACTIONS.GET_POST: {
      return {
        ...state,
        isPostLoading: true,
      };
    }

    case POSTDETAIL_ACTIONS.GET_POST_SUCCESS: {
      return {
        ...state,
        isPostLoading: false,
        cachedPosts: {
          ...state.cachedPosts,
          [action.payload.id]: action.payload.data,
        },
      };
    }

    case CREATE_FORM_INFO_ACTIONS.SUBMIT_FIRESTORE_POST_SUCCESS: {
      return {
        ...state,
        isPostLoading: false,
        cachedPosts: {
          ...state.cachedPosts,
          [action.payload.postID]: action.payload.data,
        },
      };
    }

    case POSTDETAIL_ACTIONS.GET_POST_FAIL: {
      return {
        ...state,
        isPostLoading: false,
        isPageError: true,
      };
    }

    //NOTE: in postListingReducer, the IDs for posts are stored when queried by specific criteria (country, tags, recent etc.), all post data get stored in postDetail cachedPosts
    case GET_POST_LISTING_SUCCESS: {
      return {
        ...state,
        isPageLoading: false,
        cachedPosts: {
          ...state.cachedPosts,
          ...action.payload.reduce(
            (posts: ICachedPosts, curr: IFirestoreDocument<any>) => ({
              ...posts,
              [curr.id]: curr.data,
            }),
            {}
          ),
        },
      };
    }

    case DISPLAY_CACHED_POSTS: {
      return {
        ...state,
        isPostLoading: false,
      };
    }

    case DELETE_POST_SUCCESS: {
      return {
        ...state,
        cachedPosts: R.filter((n) => n.admin.postID !== action.payload, {
          ...state.cachedPosts,
        }),
      };
    }
    default:
      return state;
  }
}
