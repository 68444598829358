import styled, { css } from 'styled-components/macro';
import * as vars from '../utils/phovoVars';
import { getBrandColor } from '../services/base.service';
import { ICommonTypographyProps } from './styled.types';

interface IHeadingProps extends ICommonTypographyProps {
  hero?: boolean;
  legal?: boolean;
}

interface IPProps extends ICommonTypographyProps {
  spacedOut?: boolean;
  callOut?: boolean;
  bodySize?: boolean;
}

interface IHighlightProps {
  brandColor?: string;
}

export const Heading = styled.h3<IHeadingProps>`
  // restyle as desired heading tag through the 'as' prop
  font-family: ${(p) => (p.bodyCopy ? vars.bodyFont : vars.brandFont)};
  font-size: ${(p) =>
    p.large ? '2.25rem' : p.small ? '1.25rem' : p.xSmall ? '1rem' : '1.75rem'};
  font-weight: ${(p) => (p.light ? 400 : 700)};
  text-transform: ${(p) => (p.uppercase ? 'uppercase' : 'inherit')};
  margin: ${(p) => (p.margin ? p.margin : 0)};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : 'auto')};
  text-align: ${(p) => (p.center ? 'center' : 'inherit')};
  color: ${(p) =>
    p.brandColor ? getBrandColor(p.brandColor) : vars.branding.grey1};
  ${(p) =>
    p.hero &&
    css<ICommonTypographyProps>`
      font-size: ${(p) => (p.large ? '2.75rem' : '2rem')};
      font-weight: 800;
      @media (min-width: ${vars.breakpoints.lg}) {
        font-size: ${(p) => (p.large ? '4rem' : '2rem')};
      }
    `}

  ${(p) =>
    p.legal &&
    css<ICommonTypographyProps>`
      font-size: ${(p) => (p.large ? '1.5rem' : p.small ? '1rem' : '1.25rem')};
      font-family: ${vars.bodyFont};
      color: #333;
    `}
`;

export const P = styled.p<IPProps>`
  font-family: ${vars.bodyFont};
  margin: ${(p) => (p.margin ? p.margin : '0.75rem 0')};
  line-height: ${(p) => (p.spacedOut ? 1.75 : 1.25)};
  color: ${(p) =>
    p.brandColor ? getBrandColor(p.brandColor) : vars.branding.darkGrey};
  font-weight: ${(p) => (p.bold ? 700 : 400)};
  text-align: ${(p) => (p.center ? 'center' : 'inherit')};
  font-size: ${(p) => (p.small ? '0.85rem' : '1rem')};

  ${(p) =>
    p.callOut &&
    css`
      font-weight: 700;
      color: ${vars.branding.grey1};
    `}

  ${(p) =>
    p.bodySize &&
    css`
      line-height: 1.5;
      font-size: 1.25rem;
    `}
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const Underline = styled.span`
  text-decoration: underline;
`;

export const PrimaryColor = styled.span`
  color: ${vars.branding.primary};
`;

export const WhiteColor = styled.span`
  color: '#fff';
`;

export const Capitalize = styled.span`
  text-transform: capitalize;
`;

export const Highlight = styled.span<IHighlightProps>`
  font-weight: 700;
  color: ${(p) => (p.brandColor ? getBrandColor(p.brandColor) : '#000')};
`;
