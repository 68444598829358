import { emailRegex, passwordRegex } from '../utils/regex';

type ValidatorFn = (val: string) => string | null;

export const validate = (
  inputValue: string,
  validations: Array<ValidatorFn>
): Array<string> => {
  return validations
    .map((validation) => validation(inputValue))
    .filter(Boolean) as Array<string>;
};

/*** VALIDATORS ***/
export const isRequired = (
  error: string = 'This is a required field'
): ValidatorFn => {
  return (val: string) => (val ? (val.trim() !== '' ? null : error) : error);
};

export const minChar = (
  min: number,
  error: string = `Must be a minimum of ${min} characters`
): ValidatorFn => {
  return (val: string) =>
    val.trim().length >= min ? null : `${error} (${val.length} of ${min})`;
};

export const maxChar = (
  max: number,
  error: string = `Cannot exceed ${max} characters`
): ValidatorFn => {
  return (val: string) =>
    val.trim().length <= max ? null : `${error} (${val.length} of ${max})`;
};

export const maxWord = (
  max: number,
  error: string = `Cannot exceed ${max} words`
): ValidatorFn => {
  return (val: string) =>
    val.split(' ').filter((word: string) => word !== '').length <= max
      ? null
      : `${error} (${
          val.split(' ').filter((word: string) => word !== '').length
        } of ${max})`;
};

export const minWord = (
  min: number,
  error: string = `Must be a minimum of ${min} words`
): ValidatorFn => {
  return (val: string) =>
    val.split(' ').filter((word: string) => word !== '').length >= min
      ? null
      : `${error} (${
          val.split(' ').filter((word: string) => word !== '').length
        } of ${min})`;
};

export const isEmail = (err: string = 'Must be a valid email address') => {
  return (val: string) => (emailRegex.test(val) ? null : err);
};

export const mustMatch = (
  matchToName: string,
  matchToValue: string,
  err: string = `Must match ${matchToName}`
): ValidatorFn => {
  return (val: string) => (val === matchToValue ? null : err);
};

export const isStrongPassword = (
  err: string = 'Password should be at least 8 characters including ONE number and ONE capital letter OR more than 15 characters.'
): ValidatorFn => {
  return (val: string) => (passwordRegex.test(val) ? null : err);
};
