export const emailRegex = new RegExp(
  '^(?!.*[\\.\\.][\\.@])[a-zA-Z0-9.!#$%&"*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]' +
    '{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'
);

// https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/ medium password regex.  Modified so if the password is more than 15 chars, no other requirement neccesary
export const passwordRegex = new RegExp(
  RegExp(
    '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})|((?=.{15,}))'
  )
);
