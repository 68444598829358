import * as POST_LISTING_ACTIONS from './postListing.actions';
import * as PROFILE_ACTIONS from '../profile/profile.actions';
import * as R from 'ramda';
import * as CREATE_FORM_INFO_ACTIONS from '../createPost/createFormInfo/createFormInfo.actions';
import { getTagNames } from '../../services/builder.service';
import {
  IPostListingState,
  PostListingActionTypes,
  NestedListing,
} from './postListing.types';
import { TagNameList, IFirestoreDocument } from '../../types/base.types';

const updateListingIDs = (
  state: IPostListingState,
  payload: Array<IFirestoreDocument<any>>
): Array<string> | NestedListing => {
  // NOTE: we are always getting 20 posts at a time from firestore, will never have to check how many are on each page before making a new one.

  const { queryBy, filterBy } = state;
  const newPostIDs = payload.map((post: IFirestoreDocument<any>) => post.id);

  if (queryBy && !Array.isArray(state[queryBy])) {
    return {
      ...state[queryBy],
      // @ts-ignore
      [filterBy]: state[queryBy][filterBy]
        ? // prettier-ignore
          // @ts-ignore
          // prettier-ignore
          [...state[queryBy][filterBy], ...newPostIDs]
        : [...newPostIDs],
    };
  } else {
    // @ts-ignore
    return [...state[queryBy], ...newPostIDs];
  }
};

const initialState: IPostListingState = {
  postsPerPage: 20,
  isLoading: false,
  isLoadingError: false,
  queryBy: null, // type of query eg: country
  filterBy: null, // subset of query eg: canada
  currentPagination: 1,
  featured: [], // postIDs listed if queryBy === featured
  recent: [], // postIDs listed if queryBy === recent
  popular: [], // postIDs listed if queryBy === popular
  country: {}, // postIDs listed if queryBy === country, keyed by filterBy (countryLongName)
  tag: {}, // postIDs listed if queryBy === tag, keyed by filterBy (tag slug name)
  user: {}, // postIDs listed if queryBy === user, keyed by filterBy (userID)
};

export default function PostListingReducer(
  state = initialState,
  action: PostListingActionTypes | any //TODO: update typings from different stores
) {
  switch (action.type) {
    case POST_LISTING_ACTIONS.UPDATE_QUERY_BY: {
      return {
        ...state,
        queryBy: action.payload,
        filterBy: null,
      };
    }

    case POST_LISTING_ACTIONS.UPDATE_FILTER_BY: {
      return {
        ...state,
        filterBy: action.payload,
      };
    }

    case POST_LISTING_ACTIONS.SET_POST_LISTING: {
      return {
        ...state,
        queryBy: action.payload.queryBy,
        filterBy: action.payload.filterBy ?? null,
      };
    }
    case POST_LISTING_ACTIONS.GET_POST_LISTING: {
      return {
        ...state,
        isLoading: true,
        isLoadingError: false,
      };
    }

    case POST_LISTING_ACTIONS.GET_POST_LISTING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        // @ts-ignore
        [state.queryBy]: updateListingIDs(state, action.payload),
      };
    }

    case PROFILE_ACTIONS.LOAD_PROFILE_PAGE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case POST_LISTING_ACTIONS.GET_POST_LISTING_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoadingError: action?.payload?.error ?? true,
      };
    }

    case POST_LISTING_ACTIONS.DISPLAY_CACHED_POSTS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case CREATE_FORM_INFO_ACTIONS.SUBMIT_FIRESTORE_POST_SUCCESS: {
      // New post created, add it to cache instead getting it from firestore
      const { tags, location } = action.payload.data;

      const authorID = action.payload.authorID;
      const postID = action.payload.postID;

      const newTags = tags.tagIDs.reduce((acc: TagNameList, tag: number) => {
        const tagName = getTagNames([tag]);
        return {
          ...acc,
          [tagName[0]]: state.tag[tagName[0]]
            ? R.uniq([...state.tag[tagName[0]], postID])
            : [postID],
        };
      }, {});

      return {
        ...state,
        user: {
          ...state.user,
          [authorID]: state.user[authorID]
            ? R.uniq([...state.user[authorID], postID])
            : [postID],
        },
        country: {
          ...state.country,
          [location.country.longName]: state.country[location.country.longName]
            ? R.uniq([...state.country[location.country.longName], postID])
            : [postID],
        },
        tag: {
          ...state.tag,
          ...newTags,
        },
      };
    }

    default:
      return state;
  }
}
