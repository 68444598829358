import firebase from 'firebase/app';
import 'firebase/firestore';
import firebaseService from './firebase.service';
import { IFirestoreDocument } from '../types/base.types';

class FirestoreService {
  phovoFirebase: any;
  db: any;
  constructor() {
    this.phovoFirebase = firebaseService.init();
    this.db = this.phovoFirebase.firestore();
  }

  fbCollectionQuery = async (
    collection: string,
    orderBy: string | null = null,
    limit: number = 20
  ) => {
    // get entire collection
    const collectionRef = orderBy
      ? this.db.collection(collection).orderBy(orderBy, 'desc').limit(limit)
      : this.db.collection(collection).limit(limit);
    return await this.doFbQuery(collectionRef);
  };

  fbCollectionWhereQuery = async (
    // returns empty array if no results from filter (not an error)
    collection: string,
    node: string,
    operator: string,
    filterBy: string | number | boolean,
    limit: number = 20,
    orderBy: string | null = null
  ): Promise<Array<IFirestoreDocument<any>> | null> => {
    const collectionRef = orderBy
      ? this.db
          .collection(collection)
          .where(node, operator, filterBy)
          .orderBy(orderBy, 'desc')
          .limit(limit)
      : this.db
          .collection(collection)
          .where(node, operator, filterBy)
          .limit(limit);
    return await this.doFbQuery(collectionRef);
  };

  fbDocumentQuery = async (
    collection: string,
    doc: string
  ): Promise<IFirestoreDocument<any> | null> => {
    const docRef = this.db.collection(collection).doc(doc);

    try {
      const docResponse = await docRef.get();
      if (docResponse.exists) {
        return {
          id: doc,
          data: docResponse.data(),
        };
      } else {
        throw new Error('Cannot find document');
      }
    } catch (err) {
      console.error('Error getting document: ', err);
      throw new Error(err);
    }
  };

  doFbQuery = async (
    query:
      | firebase.firestore.DocumentReference
      | firebase.firestore.CollectionReference
  ): Promise<Array<IFirestoreDocument<any>> | null> => {
    try {
      const doQuery = await query.get();
      const results = this.buildFbSnapshotResponse(doQuery);
      return results;
    } catch (err) {
      console.error('Error getting documents: ', err);
      throw new Error(err);
    }
  };

  buildFbSnapshotResponse = (
    querySnapshot: any
  ): Array<IFirestoreDocument<any>> => {
    const response: Array<IFirestoreDocument<any>> = [];
    querySnapshot.forEach((result: any) => {
      let item = {
        id: result.id,
        data: result.data(),
      };
      response.push(item);
    });
    return response;
  };

  getTimeStamp = (): firebase.firestore.Timestamp => {
    return firebase.firestore.Timestamp.now();
  };

  createDocument = async (
    collectionName: string,
    documentID: string,
    data: any
  ): Promise<boolean> => {
    if (documentID) {
      try {
        // create with specific docID
        await this.db.collection(collectionName).doc(documentID).set(data);
        console.log('Document successfully written!');
        return true;
      } catch (err) {
        console.error('Error writing document: ', err);
        throw new Error(err);
      }
    } else {
      try {
        // create with random docID
        await this.db.collection(collectionName).add(data);
        console.log('Document successfully written!');
        return true;
      } catch (err) {
        console.error('Error writing document: ', err);
        throw new Error(err);
      }
    }
  };

  updateDocument = async (
    collectionName: string,
    documentID: string | null,
    data: any
  ): Promise<boolean> => {
    try {
      await this.db.collection(collectionName).doc(documentID).update(data);
      console.log('Document successfully updated!');
      return true;
    } catch (err) {
      console.error('Error updating document: ', err);
      throw new Error(err);
    }
  };

  deleteDocument = async (
    collectionName: string,
    documentID: string
  ): Promise<boolean> => {
    try {
      await this.db.collection(collectionName).doc(documentID).delete();
      console.log('Document successfully deleted!');
      return true;
    } catch (err) {
      console.error('Error deleting document: ', err);
      throw new Error(err);
    }
  };
}

const firestoreService = new FirestoreService();

export default firestoreService;
