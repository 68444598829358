import styled, { keyframes } from 'styled-components/macro';
import * as vars from '../../utils/phovoVars';

interface ILoadingContainerProps {
  margin?: string;
}

const smallKeyframes = keyframes`
  0%,
  100% {
    box-shadow: ${vars.branding.primary} 0px 0px 0px, ${vars.branding.primary} 0px 0px 0px,
      ${vars.branding.primary} 0px 0px 0px, ${vars.branding.primary} 0px 0px 0px,
      ${vars.branding.primary} 0px 0px 0px, ${vars.branding.primary} 0px 0px 0px,
      ${vars.branding.primary} 0px 0px 0px, ${vars.branding.primary} 0px 0px 0px;
  }

  25%,
  75% {
    box-shadow: ${vars.branding.primary} 14px 0px 0px, ${vars.branding.primary} -14px 0px 0px,
      ${vars.branding.primary} 0px 14px 0px, ${vars.branding.primary} 0px -14px 0px,
      ${vars.branding.primary} 10px -10px 0px, ${vars.branding.primary} 10px 10px 0px,
      ${vars.branding.primary} -10px -10px 0px, ${vars.branding.primary} -10px 10px 0px;
  }

  100% {
    box-shadow: ${vars.branding.primary} 0px 0px 0px, ${vars.branding.primary} 0px 0px 0px,
      ${vars.branding.primary} 0px 0px 0px, ${vars.branding.primary} 0px 0px 0px,
      ${vars.branding.primary} 0px 0px 0px, ${vars.branding.primary} 0px 0px 0px,
      ${vars.branding.primary} 0px 0px 0px, ${vars.branding.primary} 0px 0px 0px;
  }
`;

const largeKeyframes = keyframes`
  0%,
  100% {
    box-shadow: ${vars.branding.primary} 0px 0px 0px, ${vars.branding.primary} 0px 0px 0px,
      ${vars.branding.primary} 0px 0px 0px, ${vars.branding.primary} 0px 0px 0px,
      ${vars.branding.primary} 0px 0px 0px, ${vars.branding.primary} 0px 0px 0px,
      ${vars.branding.primary} 0px 0px 0px, ${vars.branding.primary} 0px 0px 0px;
  }

  50% {
    transform: rotate(180deg);
  }

  25%,
  75% {
    box-shadow: ${vars.branding.primary} 26px 0px 0px, ${vars.branding.primary} -26px 0px 0px,
      ${vars.branding.primary} 0px 26px 0px, ${vars.branding.primary} 0px -26px 0px,
      ${vars.branding.primary} 19px -19px 0px, ${vars.branding.primary} 19px 19px 0px,
      ${vars.branding.primary} -19px -19px 0px, ${vars.branding.primary} -19px 19px 0px;
  }

  100% {
    transform: rotate(360deg);
    box-shadow: ${vars.branding.primary} 0px 0px 0px, ${vars.branding.primary} 0px 0px 0px,
      ${vars.branding.primary} 0px 0px 0px, ${vars.branding.primary} 0px 0px 0px,
      ${vars.branding.primary} 0px 0px 0px, ${vars.branding.primary} 0px 0px 0px,
      ${vars.branding.primary} 0px 0px 0px, ${vars.branding.primary} 0px 0px 0px;
  }
`;

export const LoadingContainer = styled.div<ILoadingContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${(p) => (p.margin ? p.margin : 0)};
`;

export const LoadingMessage = styled.span`
  text-transform: uppercase;
  font-weight: 700;
  color: ${vars.branding.grey1};
`;

export const FlowerSpinner = styled.div`
  height: 70px;
  width: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export const DotsContainer = styled.div`
  height: calc(70px / 7);
  width: calc(70px / 7);
`;

export const BiggerDot = styled.div`
  background: ${vars.branding.primary};
  height: 100%;
  width: 100%;
  padding: 10%;
  border-radius: 50%;
  animation: ${largeKeyframes} 2.5s 0s infinite both;
`;

export const SmallerDot = styled.div`
  background: ${vars.branding.primary};
  height: 100%;
  width: 100%;
  border-radius: 50%;
  animation: ${smallKeyframes} 2.5s 0s infinite both;
`;
