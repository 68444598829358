import { mergeMap, mapTo } from 'rxjs/operators';

import * as UI_ACTIONS from '../ui/ui.actions';
import * as USER_ACTIONS from '../user/user.actions';
import * as AGG_ACTIONS from '../aggregates/aggregates.actions';
import { Epic, ofType, combineEpics } from 'redux-observable';
import firebaseAuthService from '../../services/firebaseAuth.service';
import { ActionSources } from '../../types/base.types';

const getLoggedInUserAttemptEpic: Epic<any> = (action$) =>
  action$.pipe(
    ofType(UI_ACTIONS.APP_BOOTSTRAP_INIT),
    mergeMap(() =>
      firebaseAuthService.authState.pipe(
        mergeMap((user) => {
          if (user) {
            return [
              {
                type: USER_ACTIONS.IS_AUTHORIZED_USER,
                payload: user.uid,
                source: ActionSources.UI_EPIC,
              },
              {
                type: USER_ACTIONS.GET_USER_DOCUMENT,
                payload: user.uid,
                source: ActionSources.UI_EPIC,
              },
            ];
          } else {
            return [
              {
                type: USER_ACTIONS.IS_NOT_AUTHORIZED_USER,
                source: ActionSources.UI_EPIC,
              },
            ];
          }
        })
      )
    )
  );

const GetAppAggregatesAttemptEpic: Epic<any> = (action$) =>
  action$.pipe(
    ofType(UI_ACTIONS.APP_BOOTSTRAP_INIT),
    mapTo({
      type: AGG_ACTIONS.GET_AGGREGATES,
      source: ActionSources.UI_EPIC,
    })
  );

export const AppBootstrapEpic = combineEpics(
  getLoggedInUserAttemptEpic,
  GetAppAggregatesAttemptEpic
);
