import { ActionSources } from '../../types/base.types';
import { QueryTypes } from '../postListing/postListing.types';
import { SearchActionTypes } from './search.types';

export const UPDATE_SEARCH_QUERY_BY = 'UPDATE_SEARCH_QUERY_BY';
export const UPDATE_SEARCH_FILTER_BY = 'UPDATE_SEARCH_FILTER_BY';

export const updateSearchQueryBy = (
  payload: QueryTypes,
  src: ActionSources
): SearchActionTypes => {
  return {
    type: UPDATE_SEARCH_QUERY_BY,
    payload: payload,
    src: src
  };
};

export const updateSearchFilterBy = (
  payload: string,
  src: ActionSources
): SearchActionTypes => {
  return {
    type: UPDATE_SEARCH_FILTER_BY,
    payload: payload,
    src: src
  };
};
