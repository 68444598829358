import { createSelector, Selector } from 'reselect';
import { IAppState } from '../../../types/appState.types';
import * as validators from '../../../services/validation.service';
import {
  IPostLocation,
  IGoogleMapsCoordinates,
} from '../../../types/post.types';

const getLocationPlace: Selector<IAppState, IPostLocation> = (state) =>
  state.createPost.location;

const getCountry: Selector<IAppState, string | null> = (state) =>
  state.createPost.location.country.longName;
const getCity: Selector<IAppState, string | null> = (state) =>
  state.createPost.location.city;
const getAlternateName: Selector<IAppState, string> = (state) =>
  state.createPost.location.alternateName;
const getGooglePlaceID: Selector<IAppState, string | null> = (state) =>
  state.createPost.location.googlePlaceID;
const getGooglePlaceValue: Selector<IAppState, string | null> = (state) =>
  state.createPost.location.googlePlaceValue;

const getGoogleMapCoordinates: Selector<IAppState, IGoogleMapsCoordinates> = (
  state
) => state.createPost.location.coordinates;

export const locationPlaceSelector = createSelector(
  getLocationPlace,
  (stateValue) => stateValue
);

export const googleMapCoordinatesSelector = createSelector(
  getGoogleMapCoordinates,
  (stateValue) => stateValue
);

export const countrySelector = createSelector(
  getCountry,
  (stateValue) => stateValue
);

export const citySelector = createSelector(getCity, (stateValue) => stateValue);

export const alternateNameSelector = createSelector(
  getAlternateName,
  (stateValue) => stateValue
);

export const googlePlaceIDSelector = createSelector(
  getGooglePlaceID,
  (stateValue) => stateValue
);

export const googlePlaceValueSelector = createSelector(
  getGooglePlaceValue,
  (stateValue) => stateValue
);

// *** Validations
export const createLocationFormConfig = {
  googlePlaceValue: [], // custom component, handles own validation
  alternateName: [validators.maxChar(75)],
};

const alternateNameValidations = [...createLocationFormConfig.alternateName];

export const alternateNameValidationsSelector = createSelector(
  alternateNameSelector,
  (stateValue) => validators.validate(stateValue, alternateNameValidations)
);

export const createLocationStepValidatorSelector = createSelector(
  googlePlaceIDSelector,
  (googleID) => Boolean(googleID)
);
