import { ActionSources, IFormFieldPayload } from '../../types/base.types';
import { LoginActionTypes, ILoginPayload } from './login.types';

export const UPDATE_LOGIN_FIELD = 'UPDATE_LOGIN_FIELD';
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
export const AUTHENTICATE_USER_FAIL = 'AUTHENTICATE_USER_FAIL';

export const updateLoginField = (
  payload: IFormFieldPayload,
  src: ActionSources
): LoginActionTypes => {
  return {
    type: UPDATE_LOGIN_FIELD,
    payload: payload,
    src: src,
  };
};

export const authenticateUser = (
  payload: ILoginPayload,
  src: ActionSources
): LoginActionTypes => {
  return {
    type: AUTHENTICATE_USER,
    payload: payload,
    src: src,
  };
};

export const authenticateUserSuccess = (
  payload: string,
  src: ActionSources
): LoginActionTypes => {
  return {
    type: AUTHENTICATE_USER_SUCCESS,
    payload: payload,
    src: src,
  };
};

export const authenticateUserFail = (
  payload: string,
  src: ActionSources
): LoginActionTypes => {
  return {
    type: AUTHENTICATE_USER_FAIL,
    payload: payload,
    src: src,
  };
};
