import { createSelector, Selector } from 'reselect';
import * as validators from '../../../services/validation.service';
import { IAppState } from '../../../types/appState.types';
import { IPostUpload } from '../../../types/post.types';

const getUploadPhotos: Selector<IAppState, Array<IPostUpload>> = (state) =>
  state.createPost.uploadMedia.photos;
const getCoverPhotoIndex: Selector<IAppState, number | null> = (state) =>
  state.createPost.uploadMedia.coverPhotoIndex;
const getWidgetOpenedById: Selector<IAppState, number | undefined | null> = (
  state
) => state.createPost.uploadMedia.widgetOpenedById;

export const coverPhotoIndexSelector = createSelector(
  getCoverPhotoIndex,
  (stateValue) => stateValue
);

export const photosSelector = createSelector(
  getUploadPhotos,
  (stateValue) => stateValue
);

export const doesCoverPhotoExistSelector = createSelector(
  [coverPhotoIndexSelector, photosSelector],
  (coverIndex, photos) => {
    const coverPhotoExists =
      coverIndex !== -1 && coverIndex !== null
        ? photos[coverIndex].id !== null
        : false;
    return coverPhotoExists;
  }
);

export const firstPhotoIndexSelector = createSelector(
  photosSelector,
  (photoState) => {
    return photoState.findIndex((photo) => photo.id);
  }
);

export const widgetOpenedByIdSelector = createSelector(
  getWidgetOpenedById,
  (stateValue) => stateValue
);

export const photoCaptionValidationsSelector = createSelector(
  photosSelector,
  (photosState) => {
    return photosState.reduce(
      (acc: Array<Array<string>>, photo: IPostUpload) => {
        return photo.caption
          ? [
              ...acc,
              [
                ...validators.validate(photo.caption, [
                  validators.maxChar(180),
                ]),
              ],
            ]
          : [...acc, []];
      },
      []
    );
  }
);

export const createPhotoUploadStepValidatorSelector = createSelector(
  [photosSelector, photoCaptionValidationsSelector],
  (photos, captionValidations) => {
    const hasPhotos = photos.filter((photo) => photo.id !== null);
    const captionsValid = captionValidations.reduce(
      (acc: boolean, item: Array<string | null>) => {
        return item.length ? false : acc;
      },
      true
    );
    return Boolean(hasPhotos.length && captionsValid);
  }
);
