import {
  IUpdateExtraQuestionPayload,
  CreateExtraQuestionActionTypes,
} from './createExtraQuestions.types';
import { ActionSources } from '../../../types/base.types';

export const UPDATE_EXTRA_QUESTION = 'UPDATE_EXTRA_QUESTION';

export const updateExtraQuestion = (
  payload: IUpdateExtraQuestionPayload,
  src: ActionSources
): CreateExtraQuestionActionTypes => ({
  type: UPDATE_EXTRA_QUESTION,
  payload: payload,
  src: src,
});
