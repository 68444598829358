import styled, { css, keyframes } from 'styled-components/macro';
import { darken } from 'polished';
import * as vars from '../utils/phovoVars';
import {
  getBrandColor,
  hiddenSmall,
  visibleSmall,
} from '../services/base.service';

interface IRibbonProps {
  bgImg?: string;
  brandColor?: string;
  bottomShadow?: boolean;
  tight?: boolean;
}

interface ISpacingDivProps {
  margin?: string;
  padding?: string;
}

interface IButtonProps {
  margin?: string;
  selected?: boolean | string;
  primary?: boolean | string;
  bordered?: boolean | string;
  large?: boolean | string;
  small?: boolean | string;
  white?: boolean | string;
  pill?: boolean | string;
  to?: string;
  disabled?: boolean | string;
}

interface IIconButtonProps {
  brandColor?: string;
}

interface IHrefLinkProps {
  small?: boolean;
}

interface IGlobalWrapperProps {
  textCenter?: boolean;
  minHeight?: string;
  white?: boolean;
}

interface ITopSpacerProps {
  height?: string;
}

interface ILabelProps {
  htmlFor?: any;
  uppercase?: boolean;
  bold?: boolean;
  color?: string;
  mobileOnly?: boolean;
}

interface INotificationBoxProps {
  margin?: string;
  failure?: boolean;
  success?: boolean;
}

interface IStickyContainerProps {
  top?: string;
}

interface IUListProps {
  margin?: string;
}

interface IOListProps {
  listType?: string;
  margin?: string;
}

interface IListItemProps {
  small?: boolean;
}

interface ISkeletonProps {
  height?: string;
  width?: string;
  borderRadius?: string;
  margin?: string;
}

interface IHRulerProps {
  margin?: string;
}

export const ShadowOverlay = styled.div`
  background-color: #000;
  position: absolute;
  padding: 0 0 20px 0;
  text-align: center;
  width: 100%;
  min-height: 125px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.7) 20%,
    rgba(0, 0, 0, 0) 100%
  );
`;

export const Ribbon = styled.section<IRibbonProps>`
  position: relative;
  padding: 75px 0;
  background: url(${(p) => (p.bgImg ? p.bgImg : null)});
  background-color: ${(p) =>
    p.brandColor ? getBrandColor(p.brandColor) : vars.branding.offWhite};
  background-size: cover;
  background-position: center;
  min-height: 275px;
  box-shadow: ${(p) => (p.bottomShadow ? '0 0px 5px rgba(0,0,0,0.2)' : 'none')};

  ${(p) =>
    p.tight &&
    css`
      min-height: 250px;
      padding: 25px 0;
    `};
`;

export const SpacingDiv = styled.div<ISpacingDivProps>`
  margin: ${(p) => (p.margin ? p.margin : '0')};
  padding: ${(p) => (p.padding ? p.padding : '0')};
`;

export const Button = styled.button<IButtonProps>`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 15px;
  border: 2px solid ${vars.branding.grey1};
  font-family: ${vars.bodyFont};
  font-size: 1rem;
  margin: ${(p) => (p.margin ? p.margin : '0')};
  font-weight: 700;
  color: #fff;
  background: ${(p) =>
    p.selected ? vars.branding.primary : vars.branding.grey1};
  border-color: ${(p) =>
    p.selected ? vars.branding.primary : vars.branding.grey1};
  text-transform: uppercase;
  transition: all 250ms ease-out;
  flex-shrink: 0;

  /* Any direct svgs will have this style, if you need specific spacing use SpacingDiv styledComponent */
  > svg {
    margin-right: 5px;
  }

  &:hover {
    cursor: pointer;
    background: ${vars.branding.darkGrey};
    border-color: ${vars.branding.darkGrey};
  }

  &:disabled {
    background: ${vars.branding.grey3};
    border-color: ${vars.branding.grey3};
    color: ${vars.branding.offWhite};
    cursor: not-allowed;
  }

  ${(p) =>
    p.primary &&
    css`
      background: ${vars.branding.primary};
      border-color: ${vars.branding.primary};
    `}

  ${(p) =>
    p.large &&
    css`
      font-size: 1.15rem;
      flex-shrink: 1;
      @media (min-width: ${vars.breakpoints.md}) {
        font-size: 1.5rem;
        flex-shrink: 1;
      }
    `}


  ${(p) =>
    p.white &&
    css`
      background: #fff;
      border-color: #fff;
      color: ${vars.branding.primary};
      &:hover {
        background: ${vars.branding.primary};
        border-color: ${vars.branding.primary};
        color: #fff;
      }
    `}

    ${(p) =>
    p.bordered &&
    p.white &&
    css`
      background: transparent;
      color: #fff;
      border-color: #fff;
      &:hover {
        background: #fff;
        color: ${vars.branding.primary};
        border-color: #fff;
      }
    `}

    ${(p) =>
    p.bordered &&
    p.primary &&
    css`
      background: transparent;
      color: ${vars.branding.primary};
      border-color: ${vars.branding.primary};
      &:hover {
        background: ${vars.branding.primary};
        border-color: ${vars.branding.primary};
        color: #fff;
      }
    `}

       ${(p) =>
    p.small &&
    css`
      font-size: 0.75rem;
      border-radius: 0px;
      padding: 5px;
      display: flex;
      align-items: center;

      svg {
        font-size: 1.25rem;
      }
    `}

        ${(p) =>
    p.pill &&
    css`
      border-radius: 50px;
    `}
`;

export const IconButton = styled.button<IIconButtonProps>`
  background: transparent;
  border: none;
  color: ${(p) =>
    p.brandColor ? getBrandColor(p.brandColor) : vars.branding.grey1};
  :hover {
    cursor: pointer;
  }
`;

export const HrefLink = styled.a<IHrefLinkProps>`
  color: ${vars.branding.primary};
  font-size: ${(p) => (p.small ? '0.75rem' : 'inherit')};

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const GlobalGridContainer = styled.div`
  display: grid;
  min-height: 100%;
  grid-auto-rows: 1fr auto;
  grid-template-columns: 100%;
`;

export const MainContent = styled.main`
  height: 100%;
`;

export const GlobalWrapper = styled.div<IGlobalWrapperProps>`
  padding: 0 ${vars.colGutter};
  max-width: ${vars.maxWidth};
  margin: 0 auto;
  text-align: ${(p) => (p.textCenter ? 'center' : 'left')};
  min-height: ${(p) => (p.minHeight ? p.minHeight : '0px')};
  background: ${(p) => (p.white ? '#fff' : 'transparent')};
`;

export const TwoColGrid = styled.div`
  max-width: ${vars.maxWidth};
  display: block;

  @media (min-width: ${vars.breakpoints.lg}) {
    margin: 0 auto;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
  }
`;

export const Panel = styled.section`
  margin: 0 auto;
  width: 500px;
  border-radius: ${vars.lgBorderRadius};
  padding: 20px;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
`;

export const TopSpacer = styled.div<ITopSpacerProps>`
  height: ${(p) => (p.height ? p.height : '100px')};
`;

export const HeaderSpacer = styled.div`
  height: 45px;
  @media (min-width: ${vars.breakpoints.md}) {
    height: 100px;
  }
`;

export const Label = styled.label<ILabelProps>`
  display: block;
  font-size: 0.85rem;
  margin-bottom: 5px;
  text-transform: ${(p) => (p.uppercase ? 'uppercase' : 'none')};
  font-weight: ${(p) => (p.bold ? '700' : '400')};
  color: ${(p) => (p.color ? getBrandColor(p.color) : vars.branding.grey2)};

  @media (min-width: ${vars.breakpoints.md}) {
    display: ${(p) => (p.mobileOnly ? 'none' : 'block')};
  }
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const Italic = styled.span`
  font-style: italic;
`;

export const HiddenSmall = styled.div`
  ${hiddenSmall()};
`;

export const VisibleSmall = styled.div`
  ${visibleSmall()};
`;

export const NotificationBox = styled.div<INotificationBoxProps>`
  display: flex;
  padding: 10px;
  margin: ${(p) => (p.margin ? p.margin : 0)};
  background: ${(p) =>
    p.failure
      ? vars.branding.lightRed
      : p.success
      ? vars.branding.lightGreen
      : vars.branding.grey3};
  font-size: 0.75rem;
  border: 1px dotted;
  border-color: ${(p) =>
    p.failure
      ? vars.branding.red
      : p.success
      ? vars.branding.green
      : vars.branding.grey1};

  svg {
    margin-right: 10px;
  }
`;

export const MiniTextBtn = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font-weight: 700;
  font-size: 0.75rem;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ValidationMessage = styled.span`
  font-size: 0.75rem;
  color: ${vars.branding.red};
`;

export const StickyContainer = styled.div<IStickyContainerProps>`
  position: sticky;
  top: ${(p) => (p.top ? p.top : 'auto')};
`;

export const UList = styled.ul<IUListProps>`
  list-style: disc;
  padding-left: ${vars.colGutter};
  margin: ${(p) => (p.margin ? p.margin : 0)};
`;

export const OList = styled.ul<IOListProps>`
  list-style-type: ${(p) => (p.listType ? p.listType : 'decimal')};
  padding-left: ${vars.colGutter};
  margin: ${(p) => (p.margin ? p.margin : 0)};
  font-weight: 700;
`;

export const ListItem = styled.li<IListItemProps>`
  font-size: ${(p) => (p.small ? '0.85rem' : '1rem')};
  line-height: 1.25;
  margin-bottom: 10px;
`;

export const Legend = styled.legend`
  font-weight: 700;
  margin-bottom: 10px;
`;

export const InfoPill = styled.span`
  font-size: 0.5rem;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  background: ${vars.branding.darkGrey};
  padding: 2px 5px;
  color: #fff;
  border-radius: 20px;
  margin-left: 5px;
`;

const pulse = keyframes`
  0% {
    background: ${vars.branding.grey4};
  }

  50% {
    background: ${darken(0.05, vars.branding.grey4)};
  }

  100% {
    background: ${vars.branding.grey4};
  }
`;

export const Skeleton = styled.div<ISkeletonProps>`
  width: ${(p) => (p.width ? p.width : '100%')};
  height: ${(p) => (p.height ? p.height : '50px')};
  margin: ${(p) => (p.margin ? p.margin : '10px 0')};
  border-radius: ${(p) =>
    p.borderRadius ? p.borderRadius : vars.smBorderRadius};
  background: ${vars.branding.grey4};
  animation: ${pulse} 1.5s ease-in-out infinite;
`;

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${vars.branding.offWhite};
`;

export const WhiteWrapper = styled.div`
  background: #fff;
  width: auto;
  max-height: 100%;
  max-width: ${vars.maxWidth};
  margin: 0 auto;
`;

export const HRuler = styled.hr<IHRulerProps>`
  margin: ${(p) => (p.margin ? p.margin : 0)};
  border-color: ${vars.branding.grey4};
  border-style: dotted;
`;
