import * as CREATE_EQ_ACTIONS from './createExtraQuestions.actions';
import * as CREATE_FORM_ACTIONS from '../createFormInfo/createFormInfo.actions';
import * as UPDATEPOST_ACTIONS from '../../updatePost/updatePost.actions';

import { createExtraQuestionsInitialState } from '../../../services/constants';
import { CreateExtraQuestionActionTypes } from './createExtraQuestions.types';
import { CreateFormActionTypes } from '../createFormInfo/createFormInfo.types';
import { UpdatePostActionTypes } from '../../updatePost/updatePost.types';
import { IPostExtraQuestions } from '../../../types/post.types';

const initialState: IPostExtraQuestions = {
  questions: [...createExtraQuestionsInitialState],
};

export default function CreateExtraQuestionsReducer(
  state = initialState,
  action:
    | CreateExtraQuestionActionTypes
    | CreateFormActionTypes
    | UpdatePostActionTypes
): IPostExtraQuestions {
  switch (action.type) {
    // *** GENERIC ACTIONS
    case CREATE_FORM_ACTIONS.RESET_FORM:
      return {
        ...initialState,
      };

    case UPDATEPOST_ACTIONS.SET_UPDATE_DATA: {
      const updateData = action.payload.extraQuestions.questions;
      const userAnsweredQuestionsIDs = updateData.map(
        (question) => question.id
      );
      const mergedQuestions = [...createExtraQuestionsInitialState]
        .filter((question) => !userAnsweredQuestionsIDs.includes(question.id))
        .concat(updateData)
        .sort((a, b) => a.id - b.id);
      return {
        questions: mergedQuestions,
      };
    }

    // ***

    case CREATE_EQ_ACTIONS.UPDATE_EXTRA_QUESTION: {
      return {
        ...state,
        questions: state.questions.map((question) => {
          if (question.id === action.payload.id) {
            question.writerAnswer = { ...action.payload.writerAnswer };
          }
          return question;
        }),
      };
    }
    default:
      return state;
  }
}
