import styled from 'styled-components/macro';
import * as vars from '../../utils/phovoVars';

export const LoginFormContainer = styled.div`
  h2 {
    text-align: center;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 25px 0;
`;

export const LegalContainer = styled.div`
  margin: 10px;
  font-size: 0.7rem;
  color: ${vars.branding.grey2};
  font-weight: 700;
  width: 100%;
  text-align: center;
  line-height: 1.5;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MiniTextBtn = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font-weight: 700;
  font-size: 0.75rem;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
