import { AggregatesActionTypes } from './aggregates.types';
import { ActionSources } from '../../types/base.types';

export const GET_AGGREGATES = 'GET_AGGREGATES';
export const GET_AGGREGATES_SUCCESS = 'GET_AGGREGATES_SUCCESS';
export const GET_AGGREGATES_FAIL = 'GET_AGGREGATES_FAIL';

export const getAggregates = (src: ActionSources): AggregatesActionTypes => {
  return {
    type: GET_AGGREGATES,
    payload: null,
    src: src,
  };
};
