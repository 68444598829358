import { createSelector, Selector } from 'reselect';
import { IAppState } from '../../types/appState.types';

const getIsAppBootstrapComplete: Selector<IAppState, boolean> = (state) =>
  state.ui.isAppBootstrapComplete;
const getIsMobileMenuOpen: Selector<IAppState, boolean> = (state) =>
  state.ui.isMobileMenuOpen;
const getIsModalOpen: Selector<IAppState, boolean> = (state) =>
  state.ui.isModalOpen;
const getModalType: Selector<IAppState, string | null> = (state) =>
  state.ui.modalType;

export const isAppBootstrapCompleteSelector = createSelector(
  getIsAppBootstrapComplete,
  (stateValue) => stateValue
);

export const isMobileMenuOpenSelector = createSelector(
  getIsMobileMenuOpen,
  (stateValue) => stateValue
);

export const isModalOpenSelector = createSelector(
  getIsModalOpen,
  (stateValue) => stateValue
);

export const modalTypeSelector = createSelector(
  getModalType,
  (stateValue) => stateValue
);
