import { createSelector, Selector } from 'reselect';

import * as validators from '../../services/validation.service';
import { IAppState } from '../../types/appState.types';
import { ICloudinaryUploadResponse } from '../../types/post.types';
import { loggedInUserDataSelector } from '../user/user.selectors';

const getUpdatedDisplayName: Selector<IAppState, string> = (state) =>
  state.updateProfile.updatedDisplayName;

const getUpdatedProfilePicture: Selector<
  IAppState,
  ICloudinaryUploadResponse | null
> = (state) => state.updateProfile.updatedProfilePicture;

const getIsUpdateSuccess: Selector<IAppState, boolean> = (state) =>
  state.updateProfile.isUpdateSuccess;
const getIsUpdateError: Selector<IAppState, boolean> = (state) =>
  state.updateProfile.isUpdateError;

export const updatedDisplayNameSelector = createSelector(
  getUpdatedDisplayName,
  (stateValue) => stateValue
);

export const updateProfileConfig = {
  updatedDisplayName: [
    validators.minChar(3),
    validators.maxChar(20),
    validators.isRequired(),
  ],
};

export const updatedDisplayNameValidationsSelector = createSelector(
  updatedDisplayNameSelector,
  (stateValue) =>
    validators.validate(stateValue, [...updateProfileConfig.updatedDisplayName])
);

export const updatedProfilePictureSelector = createSelector(
  getUpdatedProfilePicture,
  (stateValue) => stateValue
);

export const isUpdateSuccessSelector = createSelector(
  getIsUpdateSuccess,
  (stateValue) => stateValue
);

export const isUpdateErrorSelector = createSelector(
  getIsUpdateError,
  (stateValue) => stateValue
);

export const isUpdateProfileDataChanged = createSelector(
  [
    loggedInUserDataSelector,
    updatedDisplayNameSelector,
    updatedProfilePictureSelector,
  ],
  (loggedInUserData, updatedDisplayName, updatedProfilePicture) => {
    return (
      loggedInUserData?.displayName !== updatedDisplayName ||
      loggedInUserData.profilePicture?.id !== updatedProfilePicture?.id
    );
  }
);
