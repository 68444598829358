import styled from 'styled-components/macro';
import * as vars from '../utils/phovoVars';
import { Link } from 'react-router-dom';

interface ITextLinkRR {
  capitalized?: string;
  small?: string;
}

export const TextLinkRR = styled(Link)<ITextLinkRR>`
  color: ${vars.branding.primary};
  text-decoration: none;
  font-weight: 700;
  text-transform: ${(p) => (p.capitalized ? 'uppercase' : 'inherit')};
  font-size: ${(p) => (p.small ? '0.75rem' : 'inherit')};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const LargeCircleBtnRR = styled(Link)`
  height: 90px;
  width: 90px;
  background: ${vars.branding.primary};
  display: flex;
  border-radius: 999px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 10px ${vars.branding.grey3};
  color: #fff;
  transition: all 200ms ease-out;
  &:hover {
    transform: scale(1.1);
  }
`;
