import * as POST_LISTING_ACTIONS from './postListing.actions';
import { ActionSources, IFirestoreCollection } from '../../types/base.types';

export type NestedListing = { [filterKey: string]: Array<string> };

export type SetPostListingPayload = {
  queryBy: QueryTypes;
  filterBy: string | null;
};

export enum QueryTypes {
  FEATURED = 'featured',
  COUNTRY = 'country',
  POPULAR = 'popular',
  RECENT = 'recent',
  TAG = 'tag',
  USER = 'user',
}

export interface IPostListingState {
  postsPerPage: number;
  isLoading: boolean;
  isLoadingError: boolean;
  queryBy: QueryTypes | null;
  filterBy: string | null;
  currentPagination: number;
  featured: Array<string>;
  recent: Array<string>;
  popular: Array<string>;
  country: NestedListing;
  tag: NestedListing;
  user: NestedListing;
}

interface IUpdateQueryByAction {
  type: typeof POST_LISTING_ACTIONS.UPDATE_QUERY_BY;
  payload: QueryTypes;
  src: ActionSources;
}

interface IUpdateFilterByAction {
  type: typeof POST_LISTING_ACTIONS.UPDATE_FILTER_BY;
  payload: string;
  src: ActionSources;
}

export interface ISetPostListingAction {
  type: typeof POST_LISTING_ACTIONS.SET_POST_LISTING;
  payload: SetPostListingPayload;
  src: ActionSources;
}

export interface IGetPostListingAction {
  type: typeof POST_LISTING_ACTIONS.GET_POST_LISTING;
  payload: null;
  src: ActionSources;
}

interface IGetPostListingSuccessAction {
  type: typeof POST_LISTING_ACTIONS.GET_POST_LISTING_SUCCESS;
  payload: IFirestoreCollection;
  src: ActionSources;
}

interface IGetPostListingFailAction {
  type: typeof POST_LISTING_ACTIONS.GET_POST_LISTING_FAIL;
  payload: string | null;
  src: ActionSources;
}

interface IDisplayCachedPostsAction {
  type: typeof POST_LISTING_ACTIONS.DISPLAY_CACHED_POSTS;
  payload: null;
  src: ActionSources;
}

export type PostListingActionTypes =
  | IUpdateQueryByAction
  | IUpdateFilterByAction
  | ISetPostListingAction
  | IGetPostListingAction
  | IGetPostListingSuccessAction
  | IGetPostListingFailAction
  | IDisplayCachedPostsAction;
