import * as CREATE_PHOTO_UPLOAD_ACTIONS from './createPhotoUpload.actions';
import * as CREATE_FORM_ACTIONS from '../createFormInfo/createFormInfo.actions';
import * as UPDATEPOST_ACTIONS from '../../updatePost/updatePost.actions';

import { CreatePhotoUploadActionTypes } from './createPhotoUpload.types';
import { IPostUpload, IPostPhotos } from '../../../types/post.types';
import { CreateFormActionTypes } from '../createFormInfo/createFormInfo.types';
import { UpdatePostActionTypes } from '../../updatePost/updatePost.types';

const clearPhoto: IPostUpload = {
  orientation: 'LANDSCAPE',
  id: '',
  caption: '',
};

const initialState: IPostPhotos = {
  widgetOpenedById: null,
  coverPhotoIndex: null,
  photos: [
    { ...clearPhoto },
    { ...clearPhoto },
    { ...clearPhoto },
    { ...clearPhoto },
    { ...clearPhoto },
  ],
};

export default function CreatePhotoUploadReducer(
  state = initialState,
  action:
    | CreatePhotoUploadActionTypes
    | CreateFormActionTypes
    | UpdatePostActionTypes
): IPostPhotos {
  switch (action.type) {
    // *** GENERIC ACTIONS
    case CREATE_FORM_ACTIONS.RESET_FORM:
      return {
        ...initialState,
      };

    case UPDATEPOST_ACTIONS.SET_UPDATE_DATA: {
      const { coverPhotoIndex, photos } = action.payload.uploadMedia;
      return {
        ...initialState,
        coverPhotoIndex,
        photos,
      };
    }
    // ***

    case CREATE_PHOTO_UPLOAD_ACTIONS.UPLOAD_SUCCESS: {
      return {
        ...state,
        coverPhotoIndex: state.coverPhotoIndex
          ? state.coverPhotoIndex
          : state.widgetOpenedById || 0,
        photos: state.photos.map((photo, i) => {
          if (i === state.widgetOpenedById) {
            photo.orientation = action.payload.orientation;
            photo.id = action.payload.id;
          }
          return photo;
        }),
      };
    }

    case CREATE_PHOTO_UPLOAD_ACTIONS.OPEN_UPLOAD_WIDGET: {
      return {
        ...state,
        widgetOpenedById: action.payload,
      };
    }

    case CREATE_PHOTO_UPLOAD_ACTIONS.DELETE_UPLOAD_PHOTO: {
      return {
        ...state,
        photos: state.photos.map((photo, i) =>
          i === action.payload ? { ...clearPhoto } : photo
        ),
        coverPhotoIndex:
          state.coverPhotoIndex === action.payload
            ? state.photos.findIndex(
                (photo, i) => photo.id !== null && i !== action.payload
              )
            : state.coverPhotoIndex,
      };
    }

    case CREATE_PHOTO_UPLOAD_ACTIONS.MAKE_COVER_PHOTO: {
      return {
        ...state,
        coverPhotoIndex: action.payload,
      };
    }

    case CREATE_PHOTO_UPLOAD_ACTIONS.UPDATE_PHOTO_CAPTION: {
      return {
        ...state,
        photos: state.photos.map((photo, i) => ({
          ...photo,
          caption:
            action.payload.id === i ? action.payload.caption : photo.caption,
        })),
      };
    }
    default:
      return state;
  }
}
