import * as UI_ACTIONS from '../ui/ui.actions';
import { IUIState, UIActionTypes } from './ui.types';

const initialState: IUIState = {
  isAppBootstrapComplete: false,
  isMobileMenuOpen: false,
  isModalOpen: false,
  modalType: null,
};

export default function UIReducer(state = initialState, action: UIActionTypes) {
  switch (action.type) {
    case UI_ACTIONS.APP_BOOTSTRAP_COMPLETE:
      return {
        ...state,
        isAppBootstrapComplete: true,
      };
    case UI_ACTIONS.TOGGLE_MOBILE_MENU:
      return {
        ...state,
        isMobileMenuOpen: !state.isMobileMenuOpen,
      };
    case UI_ACTIONS.TOGGLE_MODAL:
      return {
        ...state,
        isModalOpen: Boolean(action.payload),
        modalType: action.payload || null,
      };

    default:
      return state;
  }
}
