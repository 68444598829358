import * as SEARCH_ACTIONS from './search.actions';
import { ISearchState, SearchActionTypes } from './search.types';
import { QueryTypes } from '../postListing/postListing.types';

const initialState: ISearchState = {
  queryBy: QueryTypes.COUNTRY,
  filterBy: ''
};

export default function PostsReducer(
  state = initialState,
  action: SearchActionTypes
) {
  switch (action.type) {
    case SEARCH_ACTIONS.UPDATE_SEARCH_QUERY_BY:
      return {
        ...state,
        queryBy: action.payload,
        filterBy: ''
      };

    case SEARCH_ACTIONS.UPDATE_SEARCH_FILTER_BY:
      return {
        ...state,
        filterBy: action.payload
      };
    default:
      return state;
  }
}
