import { ActionSources, IFirestoreDocument } from '../../types/base.types';
import { PostDetailActionTypes } from './postDetail.types';

export const LOAD_POSTDETAIL_PAGE = 'LOAD_POSTDETAIL_PAGE';
export const LOAD_POSTDETAIL_PAGE_SUCCESS = 'LOAD_POSTDETAIL_PAGE_SUCCESS';
export const LOAD_POSTDETAIL_PAGE_FAIL = 'LOAD_POSTDETAIL_PAGE_FAIL';

export const DISPLAY_CACHED_POST = 'DISPLAY_CACHED_POST';

export const GET_POST = 'GET_POST';
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS';
export const GET_POST_FAIL = 'GET_POST_FAIL';

export const loadPostDetailPage = (
  payload: string,
  src: ActionSources
): PostDetailActionTypes => {
  return {
    type: LOAD_POSTDETAIL_PAGE,
    payload: payload,
    src: src,
  };
};

export const loadPostDetailPageSuccess = (
  src: ActionSources
): PostDetailActionTypes => {
  return {
    type: LOAD_POSTDETAIL_PAGE_SUCCESS,
    payload: null,
    src: src,
  };
};

export const loadPostDetailPageFail = (
  src: ActionSources
): PostDetailActionTypes => {
  return {
    type: LOAD_POSTDETAIL_PAGE_FAIL,
    payload: null,
    src: src,
  };
};

export const getPost = (
  payload: string,
  src: ActionSources
): PostDetailActionTypes => {
  return {
    type: GET_POST,
    payload: payload,
    src: src,
  };
};

export const getPostSuccess = (
  payload: IFirestoreDocument<any>,
  src: ActionSources
): PostDetailActionTypes => {
  return {
    type: GET_POST_SUCCESS,
    payload: payload,
    src: src,
  };
};

export const getPostFail = (src: ActionSources): PostDetailActionTypes => {
  return {
    type: GET_POST_FAIL,
    payload: null,
    src: src,
  };
};
