import React, { useRef, useEffect } from 'react';
import firebaseAuthService from '../../services/firebaseAuth.service';
import { Redirect } from 'react-router';
import { Dispatch } from 'redux';

import * as el from './JoinForm.styled';
import { Heading, Bold, P } from '../../styled-components/typography';
import TextInput, {
  TextInputWithRef,
} from '../inputs/TextInput/TextInput.component';
import { Button, NotificationBox } from '../../styled-components/shared';
import { TextLinkRR } from '../../styled-components/react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faUserLock } from '@fortawesome/pro-solid-svg-icons';

import { connect, ConnectedProps } from 'react-redux';
import * as UI_ACTIONS from '../../store/ui/ui.actions';
import * as JOIN_ACTIONS from '../../store/join/join.actions';
import * as JOIN_SELECTORS from '../../store/join/join.selectors';
import * as USER_SELECTORS from '../../store/user/user.selectors';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner.component';
import { ActionSources, IFormFieldPayload } from '../../types/base.types';
import { IAppState } from '../../types/appState.types';
import { JoinActionTypes } from '../../store/join/join.types';
import { UIActionTypes, ModalTypes } from '../../store/ui/ui.types';

// *******************
// *** REACT-REDUX ***
// *******************

const mapState = (state: IAppState) => ({
  isJoinSuccess: JOIN_SELECTORS.joinFormSuccessSelector(state),
  isJoinLoading: JOIN_SELECTORS.isJoinLoadingSelector(state),
  newAccountID: JOIN_SELECTORS.newAccountIDSelector(state),
  userEmail: JOIN_SELECTORS.emailSelector(state),
  userEmailVerify: JOIN_SELECTORS.emailVerifySelector(state),
  userPwd: JOIN_SELECTORS.pwdSelector(state),
  userPwdVerify: JOIN_SELECTORS.pwdVerifySelector(state),
  formError: JOIN_SELECTORS.joinFomErrorSelector(state),
  joinStep: JOIN_SELECTORS.joinStepSelector(state),
  isStepOneValid: JOIN_SELECTORS.stepOneValidSelector(state),
  isStepTwoValid: JOIN_SELECTORS.stepTwoValidSelector(state),
  emailValidations: JOIN_SELECTORS.emailValidationsSelector(state),
  emailVerifyValidations: JOIN_SELECTORS.emailVerifyValidationsSelector(state),
  pwdValidations: JOIN_SELECTORS.pwdValidationsSelector(state),
  pwdVerifyValidations: JOIN_SELECTORS.pwdVerifyValidationsSelector(state),
  loggedInUserID: USER_SELECTORS.loggedInUserIDSelector(state),
});

const mapDispatch = (dispatch: Dispatch<JoinActionTypes | UIActionTypes>) => ({
  onFieldChangeHandler: (payload: IFormFieldPayload) => {
    dispatch(JOIN_ACTIONS.updateJoinField(payload, ActionSources.JOIN_MODAL));
  },
  stepCompleteHandler: () => {
    dispatch(JOIN_ACTIONS.completeStep(ActionSources.JOIN_MODAL));
  },
  createAccountHandler: () => {
    dispatch(JOIN_ACTIONS.createUser(ActionSources.JOIN_MODAL));
  },
  createAccountSuccessHandler: (payload: string) => {
    dispatch(JOIN_ACTIONS.createUserSuccess(payload, ActionSources.JOIN_MODAL));
  },
  createAccountFailureHandler: () => {
    dispatch(JOIN_ACTIONS.createUserFail(ActionSources.JOIN_MODAL));
  },
  resetFormHandler: () => {
    dispatch(JOIN_ACTIONS.resetForm(ActionSources.JOIN_MODAL));
  },
  toggleModalHandler: () => {
    dispatch(UI_ACTIONS.toggleModal(ModalTypes.JOIN, ActionSources.JOIN_MODAL));
  },
});

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

// *****

interface IJoinFormProps extends ReduxProps {}

const JoinForm: React.FC<IJoinFormProps> = ({
  isJoinSuccess,
  loggedInUserID,
  userEmail,
  userEmailVerify,
  joinStep,
  userPwd,
  userPwdVerify,
  formError,
  onFieldChangeHandler,
  emailValidations,
  emailVerifyValidations,
  pwdValidations,
  pwdVerifyValidations,
  resetFormHandler,
  isJoinLoading,
  newAccountID,
  isStepOneValid,
  isStepTwoValid,
  toggleModalHandler,
  stepCompleteHandler,
  createAccountHandler,
  createAccountSuccessHandler,
  createAccountFailureHandler,
}) => {
  const pwdRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isJoinSuccess && loggedInUserID) {
      toggleModalHandler();
    }
  }, [isJoinSuccess, loggedInUserID, toggleModalHandler]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (joinStep === 1 && isStepOneValid) {
      stepCompleteHandler();
      setTimeout(() => pwdRef.current && pwdRef.current.focus(), 100);
    } else if (joinStep === 2 && isStepTwoValid) {
      stepCompleteHandler();
      const newAccount = await firebaseAuthService.createAccount(
        userEmail,
        userPwd
      );
      createAccountHandler();
      setTimeout(() => {
        newAccount && newAccount.uid
          ? createAccountSuccessHandler(newAccount.uid)
          : createAccountFailureHandler();
      }, 5500); // give firestore time to create account & cloud function to run, before getting account info back
    }
  };

  const joinFormFields = Object.keys(JOIN_SELECTORS.joinFormConfig);

  // *** REDIRECT ON SUCCESS
  if (isJoinSuccess && newAccountID && !isJoinLoading) {
    return <Redirect to={`/traveler/${newAccountID}`} />;
  }

  return (
    <el.LoginFormContainer>
      <Heading as="h2">
        {isJoinLoading ? 'Creating your account...' : 'Join us!'}
      </Heading>

      {/* LOADING STATE */}
      {isJoinLoading ? (
        <el.LoadingContainer>
          <LoadingSpinner />
        </el.LoadingContainer>
      ) : null}

      {/* NEW USER ERROR STATE */}
      {isJoinSuccess === false && !isJoinLoading ? (
        <el.ErrorContainer>
          <NotificationBox margin="10px 0 0 0" failure>
            <FontAwesomeIcon icon={faUserLock} size="2x" />
            <span>
              Oops...There was a problem creating an account for
              <Bold> {userEmail}</Bold>
            </span>
          </NotificationBox>
          <P bold center>
            {formError}
          </P>
          <Button margin="20px 0 10px 0" onClick={() => resetFormHandler()}>
            Try again
          </Button>
        </el.ErrorContainer>
      ) : null}

      {/* SHOW FORM / DEFAULT READY STATE */}
      {isJoinSuccess !== false && !isJoinLoading ? (
        <el.Form onSubmit={handleSubmit}>
          {joinStep === 1 ? (
            <>
              <TextInput
                key="1"
                fieldName={joinFormFields[0]}
                label="Email address"
                inputValue={userEmail}
                changeHandler={onFieldChangeHandler}
                validationErrors={emailValidations}
              />
              <TextInput
                key="2"
                fieldName={joinFormFields[1]}
                label="Confirm email address"
                inputValue={userEmailVerify}
                changeHandler={onFieldChangeHandler}
                validationErrors={emailVerifyValidations}
              />
            </>
          ) : (
            <>
              <NotificationBox success margin="10px 0 0 0">
                <FontAwesomeIcon icon={faCheckCircle} size="2x" />
                <span>
                  Great! One last step, enter a strong password for{' '}
                  <Bold>{userEmail}</Bold>
                </span>
              </NotificationBox>
              <TextInputWithRef
                ref={pwdRef}
                key="3"
                fieldName={joinFormFields[2]}
                label="Password"
                inputType="password"
                inputValue={userPwd}
                changeHandler={onFieldChangeHandler}
                validationErrors={pwdValidations}
              />
              <TextInput
                key="4"
                fieldName={joinFormFields[3]}
                label="Confirm password"
                inputType="password"
                inputValue={userPwdVerify}
                changeHandler={onFieldChangeHandler}
                validationErrors={pwdVerifyValidations}
              />
            </>
          )}

          {joinStep === 2 ? (
            <el.LegalContainer>
              By clicking COMPLETE REGISTRATION you agree to our
              <br />
              <TextLinkRR to="/">Terms of service</TextLinkRR> and{' '}
              <TextLinkRR to="/">Privacy policy</TextLinkRR>.
            </el.LegalContainer>
          ) : null}

          <Button primary margin="20px 0 10px 0" type="submit">
            {joinStep === 1 ? 'Continue' : 'Complete registration'}
          </Button>
        </el.Form>
      ) : null}
    </el.LoginFormContainer>
  );
};

export default connector(JoinForm);
