import { createSelector, Selector } from 'reselect';
import { IAppState } from '../../../types/appState.types';
import { IPostItineraryStop } from '../../../types/post.types';
import * as validators from '../../../services/validation.service';

const getItinerary: Selector<IAppState, Array<IPostItineraryStop>> = (state) =>
  state.createPost.itinerary.itineraryStops;
const getShowIntro: Selector<IAppState, boolean> = (state) =>
  state.createPost.itinerary.showIntro;

export const getItineraryStateSelector = createSelector(
  getItinerary,
  (itineraryData) => itineraryData
);

export const showItineraryIntroSelector = createSelector(
  getShowIntro,
  (stateValue) => stateValue
);

export const itineraryConfig = {
  travelMethod: [validators.isRequired()],
  description: [
    validators.isRequired(),
    validators.minWord(10),
    validators.maxWord(500),
  ],
  place: {
    googlePlaceID: [validators.isRequired()],
    googlePlaceValue: [validators.isRequired()],
  },
};

export const firstItineraryIDSelector = createSelector(
  getItinerary,
  (stateValue) => stateValue[0].id
);

export const lastItineraryIDSelector = createSelector(
  getItinerary,
  (stateValue) => stateValue[stateValue.length + 1].id
);

export const itineraryValidationsSelector = createSelector(
  getItineraryStateSelector,
  (itinerary) => {
    const validationResult = itinerary.map((itineraryStop) => ({
      travelMethod: validators.validate(itineraryStop.travelMethod, [
        ...itineraryConfig.travelMethod,
      ]),
      description: validators.validate(itineraryStop.description.rawText, [
        ...itineraryConfig.description,
      ]),
      googlePlaceValue: validators.validate(
        itineraryStop.place.googlePlaceValue,
        [...itineraryConfig.place.googlePlaceValue]
      ),
      googlePlaceID: validators.validate(itineraryStop.place.googlePlaceID, [
        ...itineraryConfig.place.googlePlaceID,
      ]),
    }));
    return validationResult;
  }
);

export const createTravelStepValidatorSelector = createSelector(
  itineraryValidationsSelector,
  (itineraryValidations) => {
    const allItineraryValid = itineraryValidations.reduce((acc, itinerary) => {
      return itinerary.travelMethod.length ||
        itinerary.googlePlaceID.length ||
        itinerary.description.length
        ? false
        : acc;
    }, true);
    return allItineraryValid;
  }
);
