export const breakpointNumbers = {
  // xs <= 540, base breakpoint
  sm: 540,
  md: 720,
  lg: 960,
  xl: 1440,
};

export const breakpoints = {
  // xs <= 540, base breakpoint
  sm: `${breakpointNumbers['sm']}px`,
  md: `${breakpointNumbers['md']}px`,
  lg: `${breakpointNumbers['lg']}px`,
  xl: `${breakpointNumbers['xl']}px`,
};

export const branding = {
  primary: '#ea7934',
  primaryHighlight: '#ff9966',
  primaryLightTint: '#f5bc9a',
  darkGrey: '#333333',
  grey1: '#999999',
  grey2: '#666666',
  grey3: '#dfdfdf',
  grey4: '#eeeeee',
  offWhite: '#f9f9f9',
  red: '#ff0b0b',
  lightRed: '#f7c3ce',
  green: '#59d672',
  lightGreen: '#c7f9d1',
};

export const maxWidth = '1280px';
export const colGutter = '20px';
export const smBorderRadius = '5px';
export const lgBorderRadius = '15px';

export const brandFont = "'Nunito', arial";
export const bodyFont = "'Open Sans', sans-serif";
export const storyFont = "'Lora', serif";
