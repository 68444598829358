import React from 'react';
import { Dispatch } from 'redux';

import * as el from './LoginForm.styled';
import { Heading } from '../../styled-components/typography';
import TextInput from '../inputs/TextInput/TextInput.component';
import { Button, NotificationBox } from '../../styled-components/shared';
import { TextLinkRR } from '../../styled-components/react-router';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLock } from '@fortawesome/pro-solid-svg-icons';

import * as LOGIN_SELECTORS from '../../store/login/login.selectors';
import * as LOGIN_ACTIONS from '../../store/login/login.actions';
import * as USER_SELECTORS from '../../store/user/user.selectors';
import { connect, ConnectedProps } from 'react-redux';
import { ActionSources, IFormFieldPayload } from '../../types/base.types';
import { IAppState } from '../../types/appState.types';
import { LoginActionTypes, ILoginPayload } from '../../store/login/login.types';
import { UserActionTypes } from '../../store/user/user.types';

// *******************
// *** REACT-REDUX ***
// *******************

const mapState = (state: IAppState) => ({
  loggedInUserID: USER_SELECTORS.loggedInUserIDSelector(state),
  isAuthorizedState: USER_SELECTORS.isAuthenticatedSelector(state),
  formValid: LOGIN_SELECTORS.formValidSelector(state),
  emailValidations: LOGIN_SELECTORS.emailValidationsSelector(state),
  pwdValidations: LOGIN_SELECTORS.pwdValidationsSelector(state),
  loginEmail: LOGIN_SELECTORS.loginEmailSelector(state),
  loginPwd: LOGIN_SELECTORS.loginPwdSelector(state),
  isLoginSuccess: LOGIN_SELECTORS.isLoginSuccessSelector(state),
  isLoginError: LOGIN_SELECTORS.isLoginErrorSelector(state),
});

const mapDispatch = (
  dispatch: Dispatch<LoginActionTypes | UserActionTypes>
) => ({
  onFieldChange: (payload: IFormFieldPayload) => {
    dispatch(
      LOGIN_ACTIONS.updateLoginField(payload, ActionSources.LOGIN_MODAL)
    );
  },
  loginUserHandler: (payload: ILoginPayload) => {
    dispatch(
      LOGIN_ACTIONS.authenticateUser(payload, ActionSources.LOGIN_MODAL)
    );
  },
});

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

// *******************

interface ILoginFormProps extends ReduxProps {}

const LoginForm: React.FC<ILoginFormProps> = ({
  loginEmail,
  loginPwd,
  loginUserHandler,
  isLoginSuccess,
  isLoginError,
  onFieldChange,
  emailValidations,
  pwdValidations,
  loggedInUserID,
  isAuthorizedState,
}) => {
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    loginUserHandler({ email: loginEmail, pwd: loginPwd });
  };
  const loginFormFields = Object.keys(LOGIN_SELECTORS.loginFormConfig);

  return isAuthorizedState ? (
    <Redirect to={`/traveler/${loggedInUserID}`} />
  ) : (
    <el.LoginFormContainer>
      <Heading as="h2">Login</Heading>
      {isLoginSuccess === false ? (
        <NotificationBox margin="10px 0 0 0" failure>
          <FontAwesomeIcon icon={faUserLock} size="2x" />
          <span>{isLoginError}</span>
        </NotificationBox>
      ) : null}
      <el.Form onSubmit={handleSubmit}>
        <TextInput
          fieldName={loginFormFields[0]}
          label="Email address"
          inputValue={loginEmail}
          changeHandler={onFieldChange}
          validationErrors={emailValidations}
        />
        <TextInput
          fieldName={loginFormFields[1]}
          label="Password"
          inputType="password"
          inputValue={loginPwd}
          changeHandler={onFieldChange}
          validationErrors={pwdValidations}
        />
        <Button primary margin="20px 0 10px 0" type="submit">
          Enter
        </Button>
      </el.Form>

      <Heading as="h4" small margin="15px 0 5px 0">
        Not a member yet?
      </Heading>
      <TextLinkRR to="/join">Join us!</TextLinkRR>
    </el.LoginFormContainer>
  );
};

export default connector(LoginForm);
