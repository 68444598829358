import React, { Suspense, lazy, useCallback, useEffect } from 'react';
import {
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import { MainContent } from '../styled-components/shared';
import { Dispatch } from 'redux';
import { UIActionTypes } from '../store/ui/ui.types';
import { IAppState } from '../types/appState.types';
import { ActionSources } from '../types/base.types';
import { ConnectedProps, connect } from 'react-redux';
import * as UI_SELECTORS from '../store/ui/ui.selectors';
import * as UI_ACTIONS from '../store/ui/ui.actions';

const AboutPage = lazy(() => import('./AboutPage/AboutPage.component'));
const HomePage = lazy(() => import('./Homepage/HomePage.component'));
const ListingPage = lazy(() => import('./ListingPage/ListingPage.component'));
const PostDetailPage = lazy(() =>
  import('./PostDetailPage/PostDetailPage.component')
);
const CreatePostPage = lazy(() =>
  import('./CreatePostPage/CreatePostPage.component')
);
const LoginPage = lazy(() => import('./LoginPage/LoginPage.component'));
const JoinPage = lazy(() => import('./JoinPage/JoinPage.component'));
const ProfilePage = lazy(() => import('./ProfilePage/ProfilePage.component'));
const TermsPage = lazy(() => import('./TermsPage/TermsPage.component'));
const PrivacyPage = lazy(() => import('./PrivacyPage/PrivacyPage.component'));
const ContactPage = lazy(() => import('./ContactPage/ContactPage.component'));
const UpdateProfilePage = lazy(() =>
  import('./UpdateProfilePage/UpdateProfilePage.component')
);

// *******************
// *** REACT-REDUX ***
// *******************

const mapState = (state: IAppState) => ({
  isMobileMenuOpen: UI_SELECTORS.isMobileMenuOpenSelector(state),
  isModalOpen: UI_SELECTORS.isModalOpenSelector(state),
});

const mapDispatch = (dispatch: Dispatch<UIActionTypes>) => ({
  toggleModalHandler: () => {
    dispatch(UI_ACTIONS.toggleModal(null, ActionSources.ROUTE_CHANGE));
  },
  toggleMobileMenuHandler: () => {
    dispatch(UI_ACTIONS.toggleMobileMenu(ActionSources.ROUTE_CHANGE));
  },
});

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

interface IMainRouterOutletProps extends ReduxProps, RouteComponentProps {}

const MainRouterOutlet: React.FC<IMainRouterOutletProps> = ({
  location,
  isMobileMenuOpen,
  isModalOpen,
  toggleModalHandler,
  toggleMobileMenuHandler,
}) => {
  // ***** callbacks
  const toggleMobileMenuHandler_CB = useCallback(
    () => toggleMobileMenuHandler(),
    [toggleMobileMenuHandler]
  );

  const toggleModalHandler_CB = useCallback(() => toggleModalHandler(), [
    toggleModalHandler,
  ]);

  const onRouterChange_CB = useCallback(() => {
    window.scrollTo(0, 0);
    isMobileMenuOpen && toggleMobileMenuHandler_CB();
    isModalOpen && toggleModalHandler_CB();
  }, [
    isMobileMenuOpen,
    isModalOpen,
    toggleMobileMenuHandler_CB,
    toggleModalHandler_CB,
  ]);
  // *****

  // ***** Only trigger on location change
  useEffect(() => {
    onRouterChange_CB();
    // eslint-disable-next-line
  }, [location]);

  return (
    <MainContent>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/" component={AboutPage} />
          <Route path="/explore" component={HomePage} />
          <Route
            path="/search/destination/:queryBy?/:filterBy?"
            component={ListingPage}
          />
          <Route path="/travel/:postID" component={PostDetailPage} />
          <Route path="/create" component={CreatePostPage} />
          <Route path="/update/:postID" component={CreatePostPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/join" component={JoinPage} />
          <Route exact path="/traveler/:authorID" component={ProfilePage} />
          <Route
            exact
            path="/updateProfile/:authorID"
            component={UpdateProfilePage}
          ></Route>
          <Route path="/terms" component={TermsPage} />
          <Route path="/privacy" component={PrivacyPage} />
          <Route path="/contact" component={ContactPage} />
        </Switch>
      </Suspense>
    </MainContent>
  );
};

export default withRouter(connector(MainRouterOutlet));
