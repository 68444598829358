import { ActionSources } from '../../types/base.types';
import { UIActionTypes, ModalTypes } from './ui.types';

export const APP_BOOTSTRAP_INIT = 'APP_BOOTSTRAP_INIT';
export const APP_BOOTSTRAP_COMPLETE = 'APP_BOOTSTRAP_COMPLETE';
export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';

export const appBootstrapInit = () => {
  return {
    type: APP_BOOTSTRAP_INIT,
  };
};

export const appBootstrapComplete = (src: ActionSources): UIActionTypes => {
  return {
    type: APP_BOOTSTRAP_COMPLETE,
    payload: null,
    src: src,
  };
};

export const toggleMobileMenu = (src: ActionSources): UIActionTypes => {
  return {
    type: TOGGLE_MOBILE_MENU,
    payload: null,
    src: src,
  };
};

export const toggleModal = (
  payload: ModalTypes | null,
  src: ActionSources
): UIActionTypes => {
  return {
    type: TOGGLE_MODAL,
    payload: payload,
    src: src,
  };
};
