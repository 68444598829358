import * as PROFILE_ACTIONS from './profile.actions';
import { IProfileState, ProfileActionTypes } from './profile.types';

const initialState: IProfileState = {
  loadProfileID: null,
  isLoading: false,
  isLoadingError: false,
  deleteID: null,
  postsSuccessfullyRemoved: [],
  isDeleteError: false,
};

export default function ProfileReducer(
  state = initialState,
  action: ProfileActionTypes
): IProfileState {
  switch (action.type) {
    case PROFILE_ACTIONS.LOAD_PROFILE_PAGE:
      return {
        ...state,
        isLoading: true,
        isLoadingError: false,
        loadProfileID: action.payload,
      };

    case PROFILE_ACTIONS.LOAD_PROFILE_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingError: false,
      };

    case PROFILE_ACTIONS.LOAD_PROFILE_PAGE_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingError: true,
      };

    case PROFILE_ACTIONS.OPEN_DELETE_PROMPT:
      return {
        ...state,
        deleteID: action.payload,
      };

    case PROFILE_ACTIONS.CLOSE_DELETE_PROMPT:
      return {
        ...state,
        deleteID: '',
        isDeleteError: false,
      };

    case PROFILE_ACTIONS.DELETE_POST_SUCCESS:
      return {
        ...state,
        deleteID: '',
        isDeleteError: false,
        postsSuccessfullyRemoved: [
          ...state.postsSuccessfullyRemoved,
          action.payload,
        ],
      };

    case PROFILE_ACTIONS.DELETE_POST_FAIL:
      return {
        ...state,
        isDeleteError: true,
      };

    default:
      return state;
  }
}
