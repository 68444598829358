import { createSelector, Selector } from 'reselect';
import { IAppState } from '../../types/appState.types';
import { isAppBootstrapCompleteSelector } from '../ui/ui.selectors';
import { ICachedUsers } from './user.types';

const getLoggedInUserID: Selector<IAppState, string | null> = (state) =>
  state.user.loggedInUserID;
const getCachedUsers: Selector<IAppState, ICachedUsers> = (state) =>
  state.user.cachedUsers;
const getUserLoading: Selector<IAppState, boolean> = (state) =>
  state.user.isLoading;
const getUserLoadingError: Selector<IAppState, boolean> = (state) =>
  state.user.isLoadingError;

export const loggedInUserIDSelector = createSelector(
  getLoggedInUserID,
  (selectorData) => selectorData
);

export const isAuthenticatedSelector = createSelector(
  loggedInUserIDSelector,
  (selectorData) => Boolean(selectorData)
);

export const cachedUsersSelector = createSelector(
  getCachedUsers,
  (stateValue) => stateValue
);

export const cachedUserIDsSelector = createSelector(
  getCachedUsers,
  (selectorData) => (selectorData ? Object.keys(selectorData) : null)
);

export const isUserLoadingSelector = createSelector(
  getUserLoading,
  (stateValue) => stateValue
);

export const isUserLoadingErrorSelector = createSelector(
  getUserLoadingError,
  (stateValue) => stateValue
);

export const loggedInUserDataSelector = createSelector(
  [loggedInUserIDSelector, cachedUsersSelector],
  //@ts-ignore
  (loggedInID, cachedUsers) => (cachedUsers ? cachedUsers[loggedInID] : null)
);

export const shouldAuthRedirectSelector = createSelector(
  [
    isAppBootstrapCompleteSelector,
    isUserLoadingSelector,
    isAuthenticatedSelector,
  ],
  (isAppBootstrapped, isUserLoading, isAuthenticated) =>
    Boolean(isAppBootstrapped && !isUserLoading && !isAuthenticated)
);
