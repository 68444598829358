import { ActionSources } from '../../types/base.types';
import { ProfileActionTypes } from './profile.types';

export const LOAD_PROFILE_PAGE = 'LOAD_PROFILE_PAGE';
export const LOAD_PROFILE_PAGE_SUCCESS = 'LOAD_PROFILE_PAGE_SUCCESS';
export const LOAD_PROFILE_PAGE_FAIL = 'LOAD_PROFILE_PAGE_FAIL';

export const DISPLAY_CACHED_PROFILE = 'DISPLAY_CACHED_PROFILE';

export const OPEN_DELETE_PROMPT = 'OPEN_DELETE_PROMPT';
export const CLOSE_DELETE_PROMPT = 'CLOSE_DELETE_PROMPT';

export const DELETE_POST = 'DELETE_POST';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export const DELETE_POST_FAIL = 'DELETE_POST_FAIL';

export const loadProfilePage = (
  payload: string,
  src: ActionSources
): ProfileActionTypes => {
  return {
    type: LOAD_PROFILE_PAGE,
    payload: payload,
    src: src,
  };
};

export const loadProfilePageSuccess = (
  src: ActionSources
): ProfileActionTypes => {
  return {
    type: LOAD_PROFILE_PAGE_SUCCESS,
    payload: null,
    src: src,
  };
};

export const loadProfilePageFail = (src: ActionSources): ProfileActionTypes => {
  return {
    type: LOAD_PROFILE_PAGE_FAIL,
    payload: null,
    src: src,
  };
};

export const openDeletePrompt = (
  payload: string,
  src: ActionSources
): ProfileActionTypes => {
  return {
    type: OPEN_DELETE_PROMPT,
    payload: payload,
    src: src,
  };
};

export const closeDeletePrompt = (src: ActionSources): ProfileActionTypes => {
  return {
    type: CLOSE_DELETE_PROMPT,
    payload: null,
    src: src,
  };
};

export const deletePost = (
  payload: string,
  src: ActionSources
): ProfileActionTypes => {
  return {
    type: DELETE_POST,
    payload: payload,
    src: src,
  };
};

export const deletePostSuccess = (
  payload: string,
  src: ActionSources
): ProfileActionTypes => {
  return {
    type: DELETE_POST_SUCCESS,
    payload: payload,
    src: src,
  };
};

export const deletePostFail = (src: ActionSources): ProfileActionTypes => {
  return {
    type: DELETE_POST_FAIL,
    payload: null,
    src: src,
  };
};
