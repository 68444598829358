import { createSelector, Selector } from 'reselect';
import { IAppState } from '../../../types/appState.types';
import { IQuillContent } from '../../../types/post.types';
import * as validators from '../../../services/validation.service';

const getWhyAwesome: Selector<IAppState, IQuillContent> = (state) =>
  state.createPost.description.whyAwesome;
const getQuote: Selector<IAppState, string> = (state) =>
  state.createPost.description.quote;
const getMonth: Selector<IAppState, string> = (state) =>
  state.createPost.description.dateVisited.month;
const getYear: Selector<IAppState, string> = (state) =>
  state.createPost.description.dateVisited.year;
const getBudget: Selector<IAppState, string> = (state) =>
  state.createPost.description.budget;

export const whyAwesomeStateSelector = createSelector(
  getWhyAwesome,
  (stateValue) => stateValue.quillHTML
);

export const quoteStateSelector = createSelector(
  getQuote,
  (stateValue) => stateValue
);

export const monthStateSelector = createSelector(
  getMonth,
  (stateValue) => stateValue
);

export const yearStateSelector = createSelector(
  getYear,
  (stateValue) => stateValue
);

export const budgetStateSelector = createSelector(
  getBudget,
  (stateValue) => stateValue
);

// *** Validation
export const createDescriptionConfig = {
  whyAwesome: [
    validators.isRequired(),
    validators.maxWord(750),
    validators.minWord(50),
  ],
  quote: [validators.isRequired(), validators.maxChar(200)],
  month: [validators.isRequired()],
  year: [validators.isRequired()],
  budget: [validators.isRequired()],
};

export const whyAwesomeValidationsSelector = createSelector(
  getWhyAwesome,
  (stateValue) =>
    validators.validate(stateValue.rawText, [
      ...createDescriptionConfig.whyAwesome,
    ])
);

export const quoteValidationsSelector = createSelector(getQuote, (stateValue) =>
  validators.validate(stateValue, [...createDescriptionConfig.quote])
);
export const budgetValidationsSelector = createSelector(
  getBudget,
  (stateValue) =>
    validators.validate(stateValue, [...createDescriptionConfig.budget])
);

export const monthValidationsSelector = createSelector(getMonth, (stateValue) =>
  validators.validate(stateValue, [...createDescriptionConfig.month])
);

export const yearValidationsSelector = createSelector(getYear, (stateValue) =>
  validators.validate(stateValue, [...createDescriptionConfig.year])
);

export const createDescriptionStepValidatorSelector = createSelector(
  [
    whyAwesomeValidationsSelector,
    quoteValidationsSelector,
    budgetValidationsSelector,
    monthValidationsSelector,
    yearValidationsSelector,
  ],
  (whyAwesomeErrors, quoteErrors, budgetErrors, monthErrors, yearErrors) =>
    [
      ...whyAwesomeErrors,
      ...quoteErrors,
      ...budgetErrors,
      ...monthErrors,
      ...yearErrors,
    ].length === 0
);
