import { IQuillContent } from './post.types';

export interface IGenericDispatch<T> {
  type: string;
  payload?: T;
}
export interface ILabelValue {
  label: string | number;
  value: number | string;
}

export interface IFirestoreDocument<T> {
  id: string;
  data: T; // TODO: make union type for IPostData, IAggregateData, IUserData
}
export interface IFirestoreCollection {
  data: Array<IFirestoreDocument<any>>;
}

export interface IAggregate {
  [name: string]: number;
}

export interface IFormFieldPayload {
  [name: string]: string;
}

export interface IFormFieldQuillPayload {
  [name: string]: IQuillContent;
}

export type TagNameList = { [tagName: string]: string };

export type TravelMethods =
  | ''
  | 'PLANE'
  | 'BUS'
  | 'TRAIN'
  | 'BOAT'
  | 'CAR'
  | 'TAXI'
  | 'BIKE'
  | 'WALK'
  | 'END';

export enum ActionSources {
  APP_DID_MOUNT = 'APP_DID_MOUNT',
  JOIN_MODAL = 'JOIN_MODAL',
  LOGIN_MODAL = 'LOGIN_MODAL',
  GLOBAL_MENU = 'GLOBAL_MENU',
  ROUTE_CHANGE = 'ROUTE_CHANGE',
  EXPLORE_PAGE = 'EXPLORE_PAGE',
  LISTING_PAGE = 'LISTING_PAGE',
  SEARCH_PAGE = 'SEARCH_PAGE',
  PROFILE_PAGE = 'PROFILE_PAGE',
  UPDATE_PROFILE_PAGE = 'UPDATE_PROFILE_PAGE',
  SEARCH_RIBBON = 'SEARCH_RIBBON',
  CREATE_POST_PAGE = 'CREATE_POST_PAGE',
  POST_DETAIL_PAGE = 'POST_DETAIL_PAGE',
  LOGIN_EPIC = 'LOGIN_EPIC',
  USER_EPIC = 'USER_EPIC',
  AGGREGATES_EPIC = 'AGGREGATES_EPIC',
  UI_EPIC = 'UI_EPIC',
  POST_LISTING_EPIC = 'POST_LISTING_EPIC',
  POST_DETAIL_EPIC = 'POST_DETAIL_EPIC',
  PROFILE_PAGE_EPIC = 'PROFILE_PAGE_EPIC',
  CREATE_FROM_INFO_EPIC = 'CREATE_FROM_INFO_EPIC',
}
