import { switchMap, mergeMap, catchError, filter } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as LOGIN_ACTIONS from './login.actions';
import * as USER_ACTIONS from '../user/user.actions';
import firebaseAuthService from '../../services/firebaseAuth.service';

import { IAuthenticateUserAction } from './login.types';
import { Epic, ofType } from 'redux-observable';

import { ActionSources } from '../../types/base.types';

import { ILoggedInUserData } from '../../types/user.types';

export const LoginAttemptEpic: Epic<any> = (action$) =>
  action$.pipe(
    ofType(LOGIN_ACTIONS.AUTHENTICATE_USER),
    mergeMap((action: IAuthenticateUserAction) =>
      from(
        firebaseAuthService.login(action.payload.email, action.payload.pwd)
      ).pipe(
        filter((data: any) => Boolean(data)),
        switchMap((data: ILoggedInUserData) => [
          {
            type: LOGIN_ACTIONS.AUTHENTICATE_USER_SUCCESS,
            payload: data.uid,
            src: ActionSources.LOGIN_EPIC,
          },
          {
            type: USER_ACTIONS.GET_USER_DOCUMENT,
            payload: data.uid,
            src: ActionSources.LOGIN_EPIC,
          },
        ]),
        catchError((err: string) => {
          return of({
            type: LOGIN_ACTIONS.AUTHENTICATE_USER_FAIL,
            payload: err,
            src: ActionSources.LOGIN_EPIC,
          });
        })
      )
    )
  );
