import { createSelector, Selector } from 'reselect';
import { IAppState } from '../../../types/appState.types';
import { ICreatePostState } from '..';

import { generateUniqueId } from '../../../services/base.service';
import {
  createLocationStepValidatorSelector,
  citySelector,
  countrySelector,
  googlePlaceValueSelector,
} from '../createLocation/createLocation.selectors';
import { createPhotoUploadStepValidatorSelector } from '../createPhotoUpload/createPhotoUpload.selectors';
import { createDescriptionStepValidatorSelector } from '../createDescription/createDescription.selectors';
import { createTravelStepValidatorSelector } from '../createItinerary/createItinerary.selectors';
import { createTaggingStepValidator } from '../createTagging/createTagging.selectors';
import { loggedInUserIDSelector } from '../../user/user.selectors';

const getPageMode: Selector<IAppState, string | null> = (state) =>
  state.createPost.formInfo.pageMode;
const getCurrentStep: Selector<IAppState, number> = (state) =>
  state.createPost.formInfo.currentStep;
const getCreatePostState: Selector<IAppState, ICreatePostState> = (state) =>
  state.createPost;

export const pageModeSelector = createSelector(
  getPageMode,
  (stateValue) => stateValue
);

export const createPostStateSelector = createSelector(
  getCreatePostState,
  (createData) => createData
);

// *** STEP BY STEP VALIDATOR
export const stepByStepValidatorSelector = createSelector(
  [
    createLocationStepValidatorSelector,
    createPhotoUploadStepValidatorSelector,
    createDescriptionStepValidatorSelector,
    createTravelStepValidatorSelector,
    createTaggingStepValidator,
  ],
  (isStep1Valid, isStep2Valid, isStep3Valid, isStep4Valid, isStep5Valid) => [
    isStep1Valid,
    isStep2Valid,
    isStep3Valid,
    isStep4Valid,
    isStep5Valid,
    true, // all questions are optional
  ]
);

export const currentStepSelector = createSelector(
  getCurrentStep,
  (stateValue) => stateValue
);

// *** Create form state and submission selector
const getIsRequestPending: Selector<IAppState, boolean> = (state) =>
  state.createPost.formInfo.isRequestPending;
const getIsRequestSuccess: Selector<IAppState, boolean | null> = (state) =>
  state.createPost.formInfo.isRequestSuccess;

export const newPostIDSelector = createSelector(
  [
    loggedInUserIDSelector,
    countrySelector,
    citySelector,
    googlePlaceValueSelector,
  ],
  (userID, country, city, googleValue) => {
    const shortUserID = userID ? userID.slice(-5) : '';
    const uniqueID = generateUniqueId().slice(-5);
    const location = encodeURIComponent(
      `${city}-${country}`.toLowerCase().replace(/\s/g, '-')
    );
    const altName = googleValue
      ? encodeURIComponent(googleValue.replace(/\s/g, '-'))
      : null;

    return city !== null || country !== null
      ? `${uniqueID + shortUserID}-${location}`
      : `${uniqueID + shortUserID}-${altName}`;
  }
);

export const completedCreateFormDataSelector = createSelector(
  [createPostStateSelector, loggedInUserIDSelector, newPostIDSelector],
  (formData, userID, postID) => {
    const data = {
      ...formData,
      admin: {
        authorID: userID || '',
        postID: postID || '', // will always generate unique POSTID based on newPostIDSelector
      },
    };
    return data;
  }
);

export const getIsRequestSuccessSelector = createSelector(
  getIsRequestSuccess,
  (stateValue) => stateValue
);

export const firebaseSubmissionSelector = createSelector(
  [getIsRequestPending, getIsRequestSuccess],
  (reqPending, reqSuccess) => ({
    isPending: reqPending,
    isSuccess: reqSuccess,
  })
);
