import { CreateLocationActionTypes } from './createLocation.types';
import { IGooglemapsPlace } from '../../../types/post.types';
import { ActionSources, IFormFieldPayload } from '../../../types/base.types';

export const UPDATE_CREATE_LOCATION_FIELD = 'UPDATE_CREATE_LOCATION_FIELD';
export const SET_AUTOCOMPLETE_STORY_LOCATION =
  'SET_AUTOCOMPLETE_PLACE_STORY_LOCATION';
export const CLEAR_AUTOCOMPLETE_STORY_LOCATION =
  'CLEAR_AUTOCOMPLETE_PLACE_STORY_LOCATION';

export const updateCreateLocationField = (
  payload: IFormFieldPayload,
  src: ActionSources
): CreateLocationActionTypes => ({
  type: UPDATE_CREATE_LOCATION_FIELD,
  payload: payload,
  src: src,
});

export const setAutocompletePlace = (
  payload: IGooglemapsPlace,
  src: ActionSources
): CreateLocationActionTypes => ({
  type: SET_AUTOCOMPLETE_STORY_LOCATION,
  payload: payload,
  src: src,
});

export const clearAutocompletePlace = (
  src: ActionSources
): CreateLocationActionTypes => ({
  type: CLEAR_AUTOCOMPLETE_STORY_LOCATION,
  payload: null,
  src: src,
});
